import { Heading, Image, SpanText } from "../../atoms";
import { RatingStars } from "../OtherElements/RatingStars";
import VerifiedBlueTick from "../../../utils/reactIcons/verified-blue-tick";
import { WriterGraduationHat } from "../../../utils/reactIcons/writer-graduation-hat";

export const WritersInfo = ({ data }) => {
  return (
    <div className="border rounded-md shadow shadow-primary-hover p-3 sm:py-3 sm:px-6">
      <div className="flex flex-wrap justify-between gap-8 sm:gap-3">
        <div className="flex gap-3">
          <div className="flex items-center justify-center py-2">
            <Image
              parentClasses="max-w-[80px]"
              customClasses=""
              className=""
              src={"https://www.assignmentdesk.co.uk/images/writer21.png"}
            />
          </div>

          <div className="">
            <div className="">
              <div className="flex gap-4 items-center">
                <Heading
                  customClasses="tracking-wide text-lg font-semibold my-1"
                  heading={data?.name}
                />
                <div className="my-1">
                  <VerifiedBlueTick />
                </div>
              </div>
              <RatingStars
                customClasses="flex items-center gap-1"
                starClassNames="text-lg"
              />
            </div>

            <div className="my-1">
              <div className="max-w-[105px] relative grid items-center px-2 py-1 font-sans text-xs text-primary-hover capitalize bg-gray-section rounded-full select-none whitespace-nowrap">
                <div className="">
                  <div
                    className={`flex gap-1.5 items-center before:content-[''] before:w-2.5 before:h-2.5 before:bg-green-600 before:block before:rounded-full`}
                  >
                    Writer online
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-[224px_auto] md:grid-cols-[1fr_auto] gap-2 w-56">
          <div className="my-1 text-sm md:text-base flex flex-col gap-y-2">
            <SpanText
              customClasses="bg-primary-hover p-1 font-medium w-full capitalize text-center"
              content={"Completed Orders"}
            />
            <SpanText
              customClasses="bg-primary-hover p-1 font-medium w-full capitalize text-center"
              content={"Orders In Progress"}
            />
          </div>

          <div className="my-1 text-sm md:text-base flex flex-col gap-y-2">
            <SpanText
              customClasses="bg-primary-hover p-1 font-medium text-center"
              content={`1000`}
            />
            <SpanText
              customClasses="bg-primary-hover p-1 font-medium text-center"
              content={`10`}
            />
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 gap-2 py-3 md:py-1.5">
        <div className="flex gap-2">
          <WriterGraduationHat className="place-self-center" />
          <div className="px-3">
            <Heading
              customClasses="bg-primary-hover text-dark p-1 min-w-[124px]"
              heading="Bachelor's"
            />
            <p className="lg:text-lg">Degree</p>
          </div>
        </div>
        <div className="">
          <Heading
            customClasses="bg-primary-hover text-dark text-center py-1"
            heading="Competencies"
          />
          <p className="text-center">
            Nursing, Business and Management, English
          </p>
        </div>
      </div>
    </div>
  );
};
