// Marketing  Finance  HR Business  Project Management  Information Technology  Biology Law & Legal  Psychology  MBA Tourism  Engineering  Nursing

export const data = [
  {
    id: 1,
    title: `Marketing`,
    price: "0",
    url: "marketing-section",
  },
  {
    id: 2,
    title: `Finance`,
    price: "0",
    url: "Finance-section",
  },
  {
    id: 3,
    title: `HR Business`,
    price: "0",
    url: "HR-Business-section",
  },
  {
    id: 4,
    title: `Project Management`,
    price: "0",
    url: "Project-Management-section",
  },
  {
    id: 5,
    title: `Information Technology`,
    price: "0",
    url: "Information-Technology-section",
  },
  {
    id: 6,
    title: `Biology`,
    price: "0",
    url: "Biology-section",
  },
  {
    id: 1 + "new",
    title: `Marketing`,
    price: "0",
    url: "marketing-section",
  },
  {
    id: 2 + "new",
    title: `Finance`,
    price: "0",
    url: "Finance-section",
  },
  {
    id: 3 + "new",
    title: `HR Business`,
    price: "0",
    url: "HR-Business-section",
  },
  {
    id: 4 + "new",
    title: `Project Management`,
    price: "0",
    url: "Project-Management-section",
  },
  {
    id: 5 + "new",
    title: `Marketing`,
    price: "0",
    url: "marketing-section",
  },
  {
    id: 6 + "new",
    title: `Finance`,
    price: "0",
    url: "Finance-section",
  },
  {
    id: 7 + "new",
    title: `HR Business`,
    price: "0",
    url: "HR-Business-section",
  },
  {
    id: 8 + "new",
    title: `Project Management`,
    price: "0",
    url: "Project-Management-section",
  },
  {
    id: 9 + "new",
    title: `Information Technology`,
    price: "0",
    url: "Information-Technology-section",
  },
  {
    id: 10 + "new",
    title: `Biology`,
    price: "0",
    url: "Biology-section",
  },
  {
    id: 11 + "new",
    title: `Marketing`,
    price: "0",
    url: "marketing-section",
  },
  {
    id: 12 + "new",
    title: `Finance`,
    price: "0",
    url: "Finance-section",
  },
  {
    id: 13 + "new",
    title: `HR Business`,
    price: "0",
    url: "HR-Business-section",
  },
  {
    id: 14 + "new",
    title: `Project Management`,
    price: "0",
    url: "Project-Management-section",
  },
];

export const freefeatures = [
  {
    id: 1,
    title: `Topic creation`,
    free: true,
    price: "0",
  },
  {
    id: 2,
    title: `outline`,
    free: true,
    price: "0",
  },
  {
    id: 3,
    title: `unlimited revision`,
    free: true,
    price: "0",
  },
  {
    id: 4,
    title: `editing / proofreading`,
    free: true,
    price: "0",
  },
  {
    id: 5,
    title: `formating`,
    free: true,
    price: "0",
  },
  {
    id: 6,
    title: `bibliography`,
    free: true,
    price: "0",
  },
];
