import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import useAppStore from "../../appStates/AppState";
import { AnchoreBtn, SmallText } from "../atoms";

export const TopMenuRight = () => {
  const { contactInfo } = useAppStore();
  return (
    <section className="flex flex-col lg:flex-row items-end lg:items-center lg:justify-end gap-5">
      <section className="flex items-center gap-4">
        <AnchoreBtn blank_page url="tel:+44 7391 606458">
          <FaPhoneVolume className="w-4 h-4" />
        </AnchoreBtn>
        <div className="flex gap-1 items-center">
          <AnchoreBtn
            blank_page
            url={contactInfo?.whatsappURL}
            customClasses="p-0 relative  z-[1]"
          >
            <div className="bg-green-600 text-light p-0.5 rounded-full flex items-center justify-center">
              <FaWhatsapp className="h-4 w-4" />
            </div>
          </AnchoreBtn>
          <AnchoreBtn
            blank_page
            url={contactInfo?.whatsappURL}
            customClasses=""
          >
            <SmallText
              customClasses="font-medium text-sm xl:text-base"
              content={contactInfo?.phone}
            />
          </AnchoreBtn>
        </div>
      </section>
      <section className="flex items-center gap-1">
        <AnchoreBtn blank_page url={`mailto:${contactInfo?.email}`}>
          <MdEmail className="w-6 h-6" />
        </AnchoreBtn>
        <AnchoreBtn blank_page url={`mailto:${contactInfo?.email}`}>
          <SmallText
            customClasses="font-medium text-sm xl:text-base"
            content={`${contactInfo?.email}`}
          />
        </AnchoreBtn>
      </section>
    </section>
  );
};
