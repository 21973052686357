import { size } from "lodash";
import { ParseHTML } from "../../../utils/methods";
import { Heading, PragraphText } from "../../atoms";

export const HeaderStyleOne = ({
  sectionName = "",
  sectionMiddle = "",
  sectionFooter = "",
  pageSection = null,
}) => {
  return (
    <div>
      {!size(pageSection) ? (
        <div className="">
          {size(sectionName) ? (
            <Heading
              customClasses="uppercase text-center md:text-lg lg:text-xl my-2"
              heading={sectionName}
            />
          ) : null}
          {size(sectionMiddle) ? (
            <Heading
              customClasses="tracking-wide capitalize text-center text-lg md:text-2xl lg:text-3xl text-primary font-semibold font-medium my-2"
              heading={sectionMiddle}
            />
          ) : null}
          {size(sectionFooter) ? (
            <div className="md:w-9/12 md:mx-auto mt-2 mb-6">
              <PragraphText
                customClasses="text-left md:text-center text-sm md:text-base leading-6"
                content={sectionFooter}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="text-center">
          {size(pageSection?.title) ? (
            <div className="uppercase text-center md:text-lg lg:text-xl my-2">
              {ParseHTML(pageSection?.title)}
            </div>
          ) : null}
          {size(pageSection?.sub_title) ? (
            <div className="capitalize text-center text-lg md:text-2xl lg:text-3xl font-semibold my-2">
              {ParseHTML(pageSection?.sub_title)}
            </div>
          ) : null}
          {size(pageSection?.description) ? (
            <div className="md:w-11/12 md:mx-auto mt-2 mb-6">
              <div className="">{ParseHTML(pageSection?.description)}</div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
