import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import { reviews } from "../../../utils/uiData/testimonialData";
import { TestimonialCard } from "../../organisms/Cards/TestimonialCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useHomeStore from "../../../appStates/HomeState";
import Container from "../../../components/Container";

export const TestimonialsBody = () => {
  const state = useHomeStore();
  const { testimonials } = state;

  return (
    <div className="px-3 mt-7">
      <Container>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          //   pauseOnMouseEnter: true,
          // }}
          slidesPerView={3}
          // navigation
          loop={false}
          // pagination={{
          //   clickable: true,
          //   //   bulletClass: "w-10 h-2 bg-primary",
          //   //   bulletElement: "div",
          //   el: '<div className="w-10 h-2 bg-primary"></div>',
          // }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
        >
          {testimonials?.map((review) => (
            <SwiperSlide key={review?.id}>
              <TestimonialCard review={review} />
              <TestimonialCard review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
};
