// import useHomeStore from "../../../appStates/HomeState";
// import { ParseHTML } from "../../../utils";
import { Heading } from "../../atoms";

export const LeftBody = () => {
  // const state = useHomeStore();
  // const { writersHeader } = state;

  // const content = writersHeader?.content || null;
  return (
    <div>
      {/* {content ? <div className="py-3">{ParseHTML(content)}</div> : null} */}
      <div className="flex flex-col gap-5">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="flex flex-col gap-2">
            <Heading heading="Proficient Researchers" />
            <p className="">
              Our experienced team of passionate researchers and writers
              delivers high-quality assignments that exceed expectations,
              ensuring academic success for every student.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <Heading heading="Expert Writers" />
            <p className="">
              Our in-house team of trusted assignment writers, with
              qualifications from reputed universities and years of experience,
              provide excellent online assignment help.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="flex flex-col gap-2">
            <Heading heading="Subject Matter Experts" />
            <p className="">
              We assign projects to subject-expert writers. Our writers are
              proficient in your subject matter to ensure you receive the best
              results.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <Heading heading="Proofreader & Analyst" />
            <p className="">
              Our proofreaders, editors, and quality analysts guarantee flawless
              work, eliminating credibility errors and ensuring high quality and
              academic success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
