import { Fragment, useRef, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { AnchoreBtn } from "../atomicSections/atoms";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const data = [
  {
    icon: null,
    label: "Paraphrasing",
    href: "/paraphrasing-tool",
  },
  {
    icon: null,
    label: "Plagiarism Checker",
    href: "/plagiarism-checker",
  },
  {
    icon: null,
    label: "Essay Typer",
    href: "/essay-typer",
  },
  {
    icon: null,
    label: "Grammer Checker",
    href: "/grammer-checker",
  },
];

export const FlyoutMenu = ({ menuTitle = "", linksArray = data }) => {
  let timeout; // NodeJS.Timeout
  const timeoutDuration = 200;

  const buttonRef = useRef(null); // useRef<HTMLButtonElement>(null)
  const [openState, setOpenState] = useState(false);

  const toggleMenu = () => {
    // log the current open state in React (toggle open state)
    setOpenState((openState) => !openState);
    // toggle the menu by clicking on buttonRef
    buttonRef?.current?.click(); // eslint-disable-line
  };

  // Open the menu after a delay of timeoutDuration
  const onHover = (open, action) => {
    // if the modal is currently closed, we need to open it
    // OR
    // if the modal is currently open, we need to close it
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout);
      // open the modal after a timeout
      timeout = setTimeout(() => toggleMenu(open), timeoutDuration);
    }
    // else: don't click! 😁
  };

  const handleClick = (open) => {
    setOpenState(!open); // toggle open state in React state
    clearTimeout(timeout); // stop the hover timer if it's running
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="hidden md:block">
      <Popover as="span" className="relative mx-auto w-48">
        {({ open }) => (
          <div
            onMouseEnter={() => onHover(open, "onMouseEnter")}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
            className="inline-block"
          >
            <Popover.Button as="span" className="inline-block" ref={buttonRef}>
              <div
                className="flex items-center"
                onClick={() => handleClick(open)}
              >
                {menuTitle ? (
                  <span className=" text-light capitalize cursor-pointer md:text-sm font-medium xl:text-base h-[100%]">
                    {menuTitle}
                  </span>
                ) : null}
              </div>
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="z-10 w-48 mx-auto absolute left-[-8px]"
              >
                <div className={"pt-4"}>
                  <div className="grid shadow-md">
                    {linksArray.map((item, _idx) => (
                      <Fragment key={_idx}>
                        <AnchoreBtn
                          url={item?.href}
                          blank_page={item?.blank_page ?? false}
                          customClasses="px-3 py-2 text-dark w-full h-full block border-b-gray-not-last-child bg-[#f1f2f4] hover:bg-white"
                        >
                          <div className="flex items-center gap-2">
                            {item?.icon}
                            <span>{item?.label}</span>
                          </div>
                        </AnchoreBtn>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </div>
  );
};
