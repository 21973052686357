import { Link } from "react-router-dom";
import { FlyoutMenu } from "../../../components/FlyoutMenu";
import DashboardAddAssignmentBtn from "../DashboardOrganism/DashboardAddAssignmentBtn";
import { FaSquareWhatsapp, FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLogoWechat } from "react-icons/io5";

const customerDropdownData = [
  {
    label: "My orders",
    route: "/student/dashboard",
  },
  {
    label: "Refer and earn",
    route: "/student/affiliate-earnings",
  },
  // {
  //   label: "Contact Us",
  //   route: "/contact-us",
  // },
];

const contactData = [
  {
    icon: <FaPhoneVolume className="text-primary-hover" size={15} />,
    label: "+44 7391 606458",
    href: "tel:+44 7391 606458",
    blank_page: true,
  },
  {
    icon: <FaSquareWhatsapp className="text-primary-hover" size={18} />,
    label: "+44 7391 606458",
    href: "https://wa.me/message/YJYCMWSA6SBGP1",
    blank_page: true,
  },
  {
    icon: <MdEmail className="text-primary-hover" size={18} />,
    label: "help@assignmentpremium.com",
    href: "mailto:help@assignmentpremium.com",
    blank_page: true,
  },
  {
    icon: <IoLogoWechat className="text-primary-hover" size={18} />,
    label: "Live Chat Now",
    href: "#",
    blank_page: false,
  },
];

const CustomerDashboardHeader = () => {
  return (
    <div className="hidden md:grid grid-cols-2 gap-2">
      <div className="flex items-center justify-end gap-2 h-full">
        {customerDropdownData?.map((item, _idx) => (
          <Link
            className="flex items-center gap-2 text-light capitalize md:text-sm font-medium xl:text-base h-full lg:px-2 px-1 hover:bg-[#ef8f00] py-2"
            to={item?.route}
            key={_idx}
          >
            {item?.label}
          </Link>
        ))}
        <div className="flex items-center h-full hover:bg-[#ef8f00] py-2">
          <span className="lg:px-2 px-1">
            <FlyoutMenu menuTitle="contact us" linksArray={contactData} />
          </span>
        </div>
      </div>
      <div className="flex items-center md:justify-end h-full">
        <DashboardAddAssignmentBtn />
      </div>
    </div>
  );
};

export default CustomerDashboardHeader;
