import { useRef, useState } from "react";
import { useClickAway } from "react-use";
import useAppStore from "../../../appStates/AppState";
import { UL } from "../../molecules";
import { Link } from "react-router-dom";
import { AnchoreBtn, Button, Logo } from "../../atoms";
import { WhatsAppIcon } from "../../../utils/reactIcons";
import { responsiveMenuData } from "../../../utils/uiData/menuData";
import { size } from "lodash";
import { MdKeyboardArrowDown } from "react-icons/md";
import useAuthStore from "../../../appStates/AuthState";
import { customerDropdownData } from "../../../components/SigninoutDropdown";
import { toast } from "react-toastify";
import { API } from "../../../utils/request";

const SidebarOrganism = () => {
  const { sideBar, handleSideBar, megaMenus, contactInfo } = useAppStore();
  const { auth, removeAuth, isAuthLoading, setisAuthLoading } = useAuthStore();
  const { menusData = [], servicesData = [] } = megaMenus;

  let menus = [...menusData, ...responsiveMenuData];

  if (size(auth)) {
    menus = [...menus, ...customerDropdownData];
  }

  const sidebarRef = useRef(null);

  const [activeDropdown, setActiveDropdown] = useState("");
  const [activeServiceDropdown, setActiveServiceDropdown] = useState("");
  const [activeChildDropdown, setActiveChildDropdown] = useState("");

  const toogleDropDown = (label) => {
    if (label === activeDropdown) {
      setActiveDropdown("");
    } else {
      setActiveDropdown(label);
    }
  };

  const toogleServiceDropDown = (label) => {
    if (label === activeServiceDropdown) {
      setActiveServiceDropdown("");
    } else {
      setActiveServiceDropdown(label);
    }
  };

  const toogleChildDropDown = (label) => {
    if (label === activeChildDropdown) {
      setActiveChildDropdown("");
    } else {
      setActiveChildDropdown(label);
    }
  };

  useClickAway(sidebarRef, () => {
    setActiveDropdown("");
    setActiveServiceDropdown("");
    setActiveChildDropdown("");
    handleSideBar();
  });

  const logOut = async () => {
    setisAuthLoading(true);
    try {
      const response = await API().post(`/app/logout`);
      if (response?.status === 200) {
        const data = response?.data;
        if (!data?.status) {
          toast.error(`Logout Unsuccessful!`);
          setisAuthLoading(false);
          return;
        }
        setisAuthLoading(false);
        removeAuth();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("writerToken");
      }
    } catch {
      setisAuthLoading(false);
      toast.error(`Logout Unsuccessful!`);
    }
  };

  return (
    <>
      {sideBar ? (
        <div
          className={`fixed top-0 left-0 shadow-md md:hidden z-40 w-full h-full transition duration-150 ease-in bg-[#11111178]`}
        >
          <div
            className="bg-light py-4 px-3 h-screen w-8/12 sm:w-5/12 overflow-auto"
            ref={sidebarRef}
          >
            <UL ulClasses="">
              <li className="my-6 py-3 flex items-center justify-center rounded-md">
                <Link to={`/`}>
                  <Logo
                    parentClasses="h-[28px]"
                    src=""
                    customClasses="w-full"
                  />
                </Link>
              </li>
              <li className="w-full grid gap-3 py-3">
                <div>
                  <Link
                    to="/order-now"
                    className="rounded-md w-full py-2 block text-center bg-primary-hover text-sm md:text-lg text-light transition ease-in delay-150 font-medium"
                  >
                    Order Now
                  </Link>
                </div>
                <div>
                  <AnchoreBtn
                    url={contactInfo?.whatsappURL}
                    customClasses="rounded-md w-full py-2 block text-center px-5 text-sm md:text-lg bg-green-600 hover:bg-green-700 transition ease-in delay-150 text-light font-medium"
                    blank_page
                  >
                    <div className="flex items-center justify-center gap-2">
                      <WhatsAppIcon customClasses="w-4 h-4" />
                      Order On WhatsApp
                    </div>
                  </AnchoreBtn>
                </div>
              </li>
              {size(menus)
                ? menus?.map((link) => (
                    <li
                      key={link?.id}
                      className={`w-full border-b-2 border-b-slate-200 last:border-b-transparent`}
                    >
                      {link?.name.toLowerCase() === "services" &&
                      size(servicesData) > 0 ? (
                        <span
                          className={`flex items-center justify-between capitalize py-[5px] ${
                            activeDropdown === link?.name
                              ? "bg-gray-section"
                              : ""
                          }`}
                          role="button"
                          tabIndex={0}
                          onClick={() => toogleDropDown(link?.name)}
                        >
                          {link?.name} <MdKeyboardArrowDown />
                        </span>
                      ) : (
                        <Link
                          to={link?.url}
                          className={`flex capitalize py-[5px] hover:bg-gray-section`}
                        >
                          {link?.name}
                        </Link>
                      )}

                      {link?.name.toLowerCase() === "services" &&
                      size(servicesData) > 0 ? (
                        <UL
                          ulClasses={`${
                            activeDropdown === link?.name ? "" : "hidden"
                          } transition duration-150 ease-in `}
                        >
                          {servicesData?.map((page) => (
                            <li key={page?.id} className="px-2 py-1">
                              <span
                                className={`flex items-center justify-between capitalize py-[5px] ${
                                  activeServiceDropdown === page?.name
                                    ? "bg-gray-section"
                                    : ""
                                }`}
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                  toogleServiceDropDown(page?.name)
                                }
                              >
                                {page?.name} <MdKeyboardArrowDown />
                              </span>

                              <UL
                                ulClasses={`${
                                  activeServiceDropdown === page?.name
                                    ? ""
                                    : "hidden"
                                } transition duration-150 ease-in`}
                              >
                                {page?.childpages?.map((child) => (
                                  <li key={child?.id}>
                                    <span
                                      className={`flex items-center justify-between capitalize py-[5px] ${
                                        activeServiceDropdown === page?.name
                                          ? "bg-gray-section"
                                          : ""
                                      }`}
                                      role="button"
                                      tabIndex={0}
                                      onClick={() =>
                                        toogleChildDropDown(child?.name)
                                      }
                                    >
                                      {child?.name}
                                      {size(child?.pages) ? (
                                        <MdKeyboardArrowDown />
                                      ) : null}
                                    </span>

                                    <UL
                                      ulClasses={`${
                                        activeChildDropdown === child?.name
                                          ? ""
                                          : "hidden"
                                      } transition duration-150 ease-in`}
                                    >
                                      {child?.pages?.map((childpage) => (
                                        <li
                                          className={`capitalize py-[5px] hover:bg-gray-section`}
                                          key={childpage?.id}
                                        >
                                          <Link
                                            to={`/pages/${childpage?.slug}`}
                                          >
                                            {childpage?.title}
                                          </Link>
                                        </li>
                                      ))}
                                    </UL>
                                  </li>
                                ))}
                              </UL>
                            </li>
                          ))}
                        </UL>
                      ) : null}
                    </li>
                  ))
                : null}
              {/* {responsiveMenuData?.map((link) => (
                <li
                  key={link?.id}
                  className={`w-full border-b-2 border-b-slate-200 last:border-b-transparent`}
                >
                  {size(link?.subPages) > 0 ? (
                    <span
                      className={`flex items-center justify-between capitalize py-[5px] ${
                        activeDropdown === link?.name ? "bg-gray-section" : ""
                      }`}
                      role="button"
                      tabIndex={0}
                      onClick={() => toogleDropDown(link?.name)}
                    >
                      {link?.name} <MdKeyboardArrowDown />
                    </span>
                  ) : (
                    <Link
                      to={link?.url}
                      className={`flex capitalize py-[5px] hover:bg-gray-section`}
                    >
                      {link?.name}
                    </Link>
                  )}

                  {size(link?.subPages) > 0 ? (
                    <UL
                      ulClasses={`${
                        activeDropdown === link?.name ? "" : "hidden"
                      } transition duration-150 ease-in `}
                    >
                      {link?.subPages?.map((page) => (
                        <li key={page?.id} className="px-2 py-1">
                          <Link to={page?.url} className="flex capitalize">
                            {page?.name}
                          </Link>
                        </li>
                      ))}
                    </UL>
                  ) : null}
                </li>
              ))} */}
            </UL>

            {!auth ? (
              <div className="flex flex-col space-y-3 w-full py-5">
                <div>
                  <Link
                    to="/signin"
                    className="rounded-md w-full py-4 block text-center bg-dark text-sm md:text-lg text-light transition ease-in delay-150 font-medium"
                  >
                    Sign in
                  </Link>
                </div>
                <div>
                  <Link
                    to="/signup"
                    className="rounded-md w-full py-4 block text-center bg-primary-hover text-sm md:text-lg text-dark transition ease-in delay-150 font-medium"
                  >
                    Register
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <Button
                  type="button"
                  customClasses="rounded-md w-full py-4 block text-center bg-dark text-sm md:text-lg text-light transition ease-in delay-150 font-medium"
                  handleClick={logOut}
                  disabled={isAuthLoading}
                >
                  Log out
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SidebarOrganism;
