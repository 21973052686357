import useHomeStore from "../../../appStates/HomeState";
import { ParseHTML } from "../../../utils";

export const LeftBody = () => {
  const state = useHomeStore();
  const { whyUsHeader } = state;
  return (
    <div className="">
      {whyUsHeader?.content ? ParseHTML(whyUsHeader?.content) : ""}
    </div>
  );
};
