import { BsPlusCircleFill } from "react-icons/bs";
import { LinkBtn } from "../../atoms";

const DashboardAddAssignmentBtn = () => {
  return (
    <LinkBtn
      route="/order-now"
      customClasses="text-sm capitalize p-2 rounded bg-primary-hover border border-primary-hover hover:bg-dark text-light inline-block flex items-center gap-1 transition ease-in duration-150"
    >
      <BsPlusCircleFill className="w-4 h-4" /> Add new Assignment
    </LinkBtn>
  );
};

export default DashboardAddAssignmentBtn;
