import NotFoundTemplate from "../templates/NotFoundTemplate";

const NotFoundPage = () => {
  return (
    <section>
      <NotFoundTemplate />
    </section>
  );
};

export default NotFoundPage;
