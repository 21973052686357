import { Link } from "react-router-dom";
import { SmallText } from "../atoms";

export const TopMenuLeft = () => {
  return (
    <section className="flex items-center">
      <SmallText
        customClasses="mr-3 font-medium text-sm xl:text-base"
        content="Get Extra 10% Off On Your First Order!"
      />
      <Link
        to="/order-now"
        className="px-4 py-1 rounded-md bg-primary hover:bg-[#ef8f00] text-dark text-sm xl:text-base font-normal"
      >
        Order Now
      </Link>
    </section>
  );
};
