import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const OurBlogsHeader = () => {
  const state = useHomeStore();
  const { blogsHeader } = state;

  return (
    <div>
      <HeaderStyleOne
        // sectionName="our blogs"
        // sectionMiddle="Read Our Latest Blogs"
        // sectionFooter="By High Quality Assignment experts on university, academics, assignment, writing skills & tips, and many more ..."
        pageSection={blogsHeader}
      />
    </div>
  );
};
