import { menus } from "../../../utils/uiData/menuBarBtn";
import { MenuBarBtn } from "../../molecules";

const MenuBarOrganism = () => {
  return (
    <section className="sticky bottom-0 left-0 shadow-md md:hidden z-30">
      <div className="grid grid-cols-5 gap-4 py-2 bg-light">
        {menus?.map((menu) => (
          <MenuBarBtn
            key={menu?.id}
            id={menu?.id}
            icon={menu?.icon}
            label={menu?.label}
            to={menu?.href}
          />
        ))}
      </div>
    </section>
  );
};

export default MenuBarOrganism;
