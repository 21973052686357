import { Link } from "react-router-dom";
import { Heading } from "../../atoms";
import useAppStore from "../../../appStates/AppState";
import { size } from "lodash";
import { cn } from "../../../utils";

const BestInCountry = ({ className = "" }) => {
  const { footerLinks = {} } = useAppStore();

  const countries = footerLinks?.best_in_countries;

  const countryLinks = size(countries)
    ? countries?.map((item, _idx) => (
        <span
          className="after:content-[','] last:after:content-['.'] after:mr-1"
          key={_idx}
        >
          <Link
            to={size(item?.url) ? item?.url : `/${item?.slug}`}
            className=" capitalize hover:text-gray-50 app-transition-in-150"
          >
            {item?.title}
          </Link>
        </span>
      ))
    : null;

  if (!size(countries)) return null;

  return (
    <div className={cn("grid gap-y-3 mb-4", className)}>
      <Heading
        customClasses="text-lg md:text-2xl lg:text-3xl font-semibold text-center"
        heading="Best in Countries"
      />
      <div className="flex flex-wrap justify-center">{countryLinks}</div>
    </div>
  );
};

export default BestInCountry;
