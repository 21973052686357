import { size } from "lodash";
import useHomeStore from "../../../appStates/HomeState";
import { Heading } from "../../atoms";

export const AssignmentCounterSection2 = () => {
  const state = useHomeStore();
  const { counters } = state;

  const amountToString = (amount) =>
    size(amount) ? +amount?.toString() : `000`;

  const counterContent = (title, value) => {
    return (
      <div
        className={`p-6 text-center rounded-md bg-secondary-dark grid gap-3 min-w-full md:min-w-[255px]`}
      >
        <p className="text-light font-medium text-3xl md:text-5xl">
          {amountToString(value)}
        </p>
        <Heading
          customClasses="tracking-wide text-primary font-semibold uppercase md:text-xl py-1 px-2 bg-gray-100 rounded-md"
          heading={title}
        />
      </div>
    );
  };
  return (
    <div className="grid md:grid-cols-3 gap-4 md:gap-0 content-center justify-items-center">
      {counterContent(
        counters?.expert_order_title,
        counters?.expert_order_value,
      )}
      {counterContent(
        counters?.deliver_order_title,
        counters?.deliver_order_value,
      )}
      {counterContent(
        counters?.client_rating_title,
        counters?.client_rating_value,
      )}
    </div>
  );
};
