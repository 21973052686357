import { useQuery } from "@tanstack/react-query";
import useHomeStore from "../appStates/HomeState";
import useOrderPageStore from "../appStates/OrderPageStates";
import { API } from "./request";
import useAppStore from "../appStates/AppState";
import { useEffect } from "react";
import useAuthStore from "../appStates/AuthState";
import { size } from "lodash";
import useDataPreparationStore from "../appStates/prepare-data-state";

export const CallAPI = () => {
  const homeState = useHomeStore();

  const {
    setFAQ,
    setTestimonials,
    setOurTools,
    setServices,
    setCounter,
    setWriters,
    setWhyUsContents,
    setOrderProcessContents,
    setRatings,
    setHomePageContents,
    setOurSamples,
  } = homeState;

  const { prepareHomeRatings } = useDataPreparationStore();

  // Home API Calls are from here

  // HOME PAGE CONTENTS API
  const { refetch: homePageContentsFetch } = useQuery({
    queryKey: ["home-page-contents"],
    queryFn: async () => {
      const response = await API()
        .get("/app/home-page-contents")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setHomePageContents(res);
    },
  });
  // HOME RATINGS API
  const { refetch: homeRatingsFetch } = useQuery({
    queryKey: ["home-ratings"],
    queryFn: async () => {
      const response = await API()
        .get("/app/website-reviews")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      const preparedRatings = prepareHomeRatings(res?.reviews);
      setRatings({ ...res, reviews: preparedRatings });
    },
  });

  // order process
  const { refetch: orderProcessFetch } = useQuery({
    queryKey: ["order-process"],
    queryFn: async () => {
      const response = await API()
        .get("/app/ordering-process")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setOrderProcessContents(res);
    },
  });

  // why us
  const { refetch: whyUsFetch } = useQuery({
    queryKey: ["why-us"],
    queryFn: async () => {
      const response = await API()
        .get("/app/why-us")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setWhyUsContents(res);
    },
  });

  // writers
  const { refetch: writersFetch } = useQuery({
    queryKey: ["writers"],
    queryFn: async () => {
      const response = await API()
        .get("/app/writers")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setWriters(res);
    },
  });

  // counters
  const { refetch: countersFetch } = useQuery({
    queryKey: ["counters"],
    queryFn: async () => {
      const response = await API()
        .get("/app/counters")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setCounter(res);
    },
  });

  // OUR SAMPLES
  const { refetch: ourSamplesQuery } = useQuery({
    queryKey: ["our-samples"],
    queryFn: async () => {
      const response = await API()
        .get("/app/our-samples")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setOurSamples(res);
    },
  });

  // Services (no needed contents)
  const { refetch: servicesFetch } = useQuery({
    queryKey: ["services"],
    queryFn: async () => {
      const response = await API()
        .get("/app/our-services")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setServices(res);
    },
  });

  // our_tools
  const { refetch: ourToolsFetch } = useQuery({
    queryKey: ["our-tools"],
    queryFn: async () => {
      const response = await API()
        .get("/app/our-tools")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setOurTools(res);
    },
  });

  // Testimonials
  const { refetch: testimonialsFetch } = useQuery({
    queryKey: ["testimonials"],
    queryFn: async () => {
      const response = await API()
        .get("/app/testimonials")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setTestimonials(res);
    },
  });

  // FAQ
  const { refetch: faqFetch } = useQuery({
    queryKey: ["faq"],
    queryFn: async () => {
      const response = await API()
        .get("/app/faqs")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setFAQ(res);
    },
  });

  useEffect(() => {
    homePageContentsFetch();
    homeRatingsFetch();
    orderProcessFetch();
    whyUsFetch();
    writersFetch();
    countersFetch();
    servicesFetch();
    ourToolsFetch();
    ourSamplesQuery();
    testimonialsFetch();
    faqFetch();
  }, []);

  return "";
};

export const CallPrimaryAPI = () => {
  const { auth, setAuth, setAuthCheckLoading } = useAuthStore();
  const orderState = useOrderPageStore();
  const appState = useAppStore();
  const {
    setOurGauranteesContents,
    setBlogs,
    setOrderProcessContents,
    setTestimonials,
    setFAQ,
  } = useHomeStore();

  const { prepareMegamenuData, prepareFooterLinksData, prepareApplicationAds } =
    useDataPreparationStore();

  const {
    setServices: setOrderServices,
    setOrderDiscount,
    setWalletReferral,
  } = orderState;

  const {
    setMegaMenus,
    setAppisReady,
    setCountryNames,
    setFooterLinks,
    setApplicationAds,
    setUserLocation,
  } = appState;

  // Primary API Calls are from here

  // APPLICATION BANNERS AND ADS FETCH
  const { refetch: appAdsQuery } = useQuery({
    queryKey: ["ads-and-banners"],
    queryFn: async () => {
      const response = await API()
        .get("/app/banner_and_ads_list")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const preparedAds = prepareApplicationAds(res);
      setApplicationAds(preparedAds);
    },
  });
  // Home API Primary Calls are from here

  // order process
  useQuery({
    queryKey: ["order-process"],
    queryFn: async () => {
      const response = await API()
        .get("/app/ordering-process")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    // refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setOrderProcessContents(res);
    },
  });

  // our progress
  const { refetch: ourProgressQuery } = useQuery({
    queryKey: ["our-gurantees"],
    queryFn: async () => {
      const response = await API()
        .get("/app/our-gurantees")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setOurGauranteesContents(res);
    },
  });

  // OUR BLOGS
  const { refetch: ourBlogs } = useQuery({
    queryKey: ["home-blogs"],
    queryFn: async () => {
      const response = await API()
        .get("/app/blogs/latest?per_page=6")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setBlogs(res);
    },
  });

  // Testimonials
  useQuery({
    queryKey: ["testimonials"],
    queryFn: async () => {
      const response = await API()
        .get("/app/testimonials")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    // refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setTestimonials(res);
    },
  });

  useQuery({
    queryKey: ["faq"],
    queryFn: async () => {
      const response = await API()
        .get("/app/faqs")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    // refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setFAQ(res);
    },
  });

  // FOOTER LINKS
  const { refetch: footerLinksQuery } = useQuery({
    queryKey: ["footer-links"],
    queryFn: async () => {
      const response = await API()
        .get("/app/footer-pages/group_by_section_name")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const preparedData = prepareFooterLinksData(res);
      setFooterLinks(preparedData);
    },
  });

  // country-names on order page
  const { refetch: countryNamesQuery } = useQuery({
    queryKey: ["country-name"],
    queryFn: async () => {
      const response = await API()
        .get("/app/selectable-countries")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setAppisReady(true);
      setCountryNames(res);
    },
  });

  // discount-percentage
  useQuery({
    queryKey: ["discount-fetch"],
    queryFn: async () => {
      const response = await API()
        .get("/app/get-discount")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    onSuccess: (res) => {
      setAppisReady(true);
      setOrderDiscount(res);
    },
  });

  // primary-menu
  const { refetch: primaryMenuQuery } = useQuery({
    queryKey: ["primary-menu"],
    queryFn: async () => {
      const response = await API()
        .get("/app/primary-menu")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const preparedMenus = prepareMegamenuData(res);
      setMegaMenus(preparedMenus);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  // order-form-services
  const { refetch: orderServicesQuery } = useQuery({
    queryKey: ["order-services"],
    queryFn: async () => {
      const response = await API()
        .get("/app/services")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setOrderServices(res);
    },
  });

  // check if the user is valid and return the info
  useQuery({
    queryKey: ["user-info"],
    queryFn: async () => {
      const response = await API()
        .get("/user-info")
        .then((res) => res?.data)
        .catch(() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("writerToken");
          setAuthCheckLoading(false);
          setAuth(null);
        })
        .finally(() => {
          if (!size(auth)) {
            setAuthCheckLoading(false);
            setAuth(null);
          }
        });
      return response;
    },
    onSuccess: (res) => {
      setAuth(res);
      setAuthCheckLoading(false);
    },
    onError: () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("writerToken");
      setAuthCheckLoading(false);
      setAuth(null);
    },
  });

  // wallet referral API used in WalletBalance page and Dashboard/Checkout page
  useQuery({
    queryKey: ["wallet-referral"],
    queryFn: async () => {
      const response = await API()
        .get("/app/wallet-referral-settings")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    onSuccess: (res) => {
      setWalletReferral(res);
    },
  });

  useEffect(() => {
    primaryMenuQuery();
    appAdsQuery();
    ourProgressQuery();
    ourBlogs();
    footerLinksQuery();
    countryNamesQuery();
    orderServicesQuery();
    setUserLocation();
  }, []);

  return "";
};
