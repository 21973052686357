import axios from "axios";

export const endPoint = `https://app.assignmentpremium.net`;
export const APIEndPoint = `${endPoint}/api`;

let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const API = (paramheaders = {}) => {
  const auth = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : localStorage.getItem("writerToken")
    ? JSON.parse(localStorage.getItem("writerToken"))
    : null;

  if (!auth) {
    headers = { ...headers };
  }
  headers = {
    ...headers,
    Authorization: `Bearer ${auth?.token ?? ""}`,
    ...paramheaders,
  };
  return axios.create({
    baseURL: APIEndPoint,
    headers: { ...headers },
  });
};
