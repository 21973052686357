import { MdEmail } from "react-icons/md";
import { SpanText } from "../../atoms/Others";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AnchoreBtn, Image } from "../../atoms";
import { BsFacebook } from "react-icons/bs";
import { SiYoutubemusic } from "react-icons/si";
import { FaPhoneVolume, FaSquareWhatsapp } from "react-icons/fa6";
import useAppStore from "../../../appStates/AppState";
import { size } from "lodash";
import { endPoint } from "../../../utils/request";

export const RightBody = () => {
  const { contactInfo, applicationAds } = useAppStore();

  const ad = applicationAds?.footer_secure_payment_logo;
  const BannerPath = ad?.path;
  const banner = size(BannerPath)
    ? `${endPoint}/${BannerPath}`
    : "/images/payment/secure_payment.png";

  return (
    <div className="px-2 lg:flex lg:px-0 lg:justify-end">
      <div className="bg-[#101A31] rounded-md px-5 py-4 shadow-md">
        <div className="mb-5">
          <div className="text-left">
            <SpanText
              customClasses="text-light capitalize md:text-lg"
              content="Need Help?"
            />
          </div>
          <div>
            <div className="flex items-center gap-2 my-2">
              <FaPhoneVolume className="text-light mr-1.5" size={21} />{" "}
              <span className="text-light md:text-lg">
                {contactInfo?.phone}
              </span>
            </div>
            <div className="flex items-center gap-2 my-1">
              <span className="relative footer_section_whatsapp_bg z-10">
                <FaSquareWhatsapp className="text-[#4CAF50]" size={28} />{" "}
              </span>
              <span className="text-light md:text-lg">
                {contactInfo?.phone}
              </span>
            </div>
            <div className="flex items-center gap-2 my-1">
              <MdEmail className="text-light" size={28} />{" "}
              <span className="text-light md:text-lg">
                {contactInfo?.email}
              </span>
            </div>
          </div>
        </div>

        {/* Download App  */}
        <div className="mb-5 text-left">
          <SpanText
            customClasses="mb-4 text-light capitalize md:text-lg"
            content="Download App"
          />
          <div className="flex items-center gap-3 my-2">
            <Image
              parentClasses=""
              customClasses="h-12"
              src="/images/download/android_app_store.png"
              title="android_app_store"
            />
            <Image
              parentClasses=""
              customClasses="h-12"
              src="/images/download/ios_app_store2.png"
              title="ios_app_store"
            />
          </div>
        </div>

        {/* Social media Start here */}
        <div className="mb-5">
          <div className="text-left">
            <SpanText
              customClasses="text-light md:text-lg capitalize"
              content="Social media"
            />
          </div>

          <div className="flex items-center gap-2 text-3xl text-white my-2">
            <AnchoreBtn blank_page url="https://www.facebook.com">
              <BsFacebook className="h-6 w-6" />
            </AnchoreBtn>
            <AnchoreBtn blank_page url="https://www.twitter.com">
              <AiFillTwitterCircle className="h-7 w-7" />
            </AnchoreBtn>
            <AnchoreBtn blank_page url="https://www.youtube.com">
              <SiYoutubemusic className="h-6 w-6" />
            </AnchoreBtn>
          </div>
        </div>

        {/* secure payment  */}
        <div className="text-left">
          <SpanText
            customClasses="text-light capitalize md:text-lg"
            content="Secure payment"
          />
          <Image
            parentClasses="h-[60px] my-2"
            customClasses="w-[250px] p-1"
            src={banner}
            title="secure payment"
          />
        </div>
      </div>
    </div>
  );
};
