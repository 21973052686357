import { size } from "lodash";
import useHomeStore from "../../../appStates/HomeState";
import { AppPlaceholderImage } from "../../../utils/uiData";
import ServicesCard from "../../organisms/Cards/ServicesCard";

export const OurServicesBody = () => {
  const state = useHomeStore();
  const { services } = state;

  return (
    <div className="grid grid-col-1 md:grid-cols-3 gap-5 my-3">
      {services?.map((service) => (
        <ServicesCard
          key={service?.id}
          slug={service?.slug}
          id={service?.id}
          title={service?.title}
          image={
            size(service?.thumbnail_path)
              ? service?.thumbnail_path
              : AppPlaceholderImage
          }
          body={service?.post_excerpt}
        />
      ))}
    </div>
  );
};
