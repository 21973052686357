/* eslint-disable no-unused-vars */
import {
  filter,
  find,
  forEach,
  map,
  orderBy,
  reduce,
  size,
  sortBy,
  uniqBy,
} from "lodash";
import { create } from "zustand";
import { getFormatedDateAndTime, ParseHTML } from "../../utils/methods";
import { endPoint } from "../../utils/request";
import { orderReferances } from "../../utils/uiData";
import useAppStore from "../AppState";
import { Image } from "../../atomicSections/atoms";
import FAQAccordion from "../../atomicSections/templates/FAQTemplate/FAQAccordion";

const useDataPreparationStore = create((set, get) => ({
  //  APPLICATION ADS AND BANNERS PREPARATION
  prepareApplicationAds: (res) => {
    let banners = {};

    res.forEach((ad) => {
      banners[ad?.context] = ad;
    });
    return banners;
  },
  // HOME MEGA MENU DATA PREPARATION
  prepareMegamenuData: (menus) => {
    const menusData = filter(menus, (menu) => !menu?.parent_id);

    const servicesData = filter(
      menus,
      (menu) => menu?.level === "2" && menu?.parent_id === "2",
    );

    const allMenus = forEach(menusData, (menu) => {
      if (menu?.slug === "services") {
        menu.subItems = servicesData;
      }
    });

    const childMenus = forEach(allMenus, (menu) => {
      if (menu?.slug === "services") {
        forEach(menu.subItems, (items) => {
          items.childpages = filter(
            menus,
            (page) => page?.parent?.slug === items?.slug,
          );
        });
      }
    });
    return { menusData, servicesData };
  },

  // HOME PAGE RATINGS DATA PREPARATIONS
  prepareHomeRatings: (items) => {
    return map(items, (item) => ({
      icon: `${endPoint}${item?.image?.path}` ?? "",
      logoColor: "",
      label: item?.title ?? "",
      rating: size(item?.rating) ? Math.round(+item?.rating) : 0,
      count:
        size(item?.rating) && size(item?.total)
          ? `${+item?.rating}/${+item?.total}`
          : `0/0`,
    }));
  },
  // FOOTER LINKS DATA PREPARATIONS
  prepareFooterLinksData: (items) => {
    /*
    template ID = 1 => /pages/
    template ID = 2 => /details/
    template ID = 3 => /how-it-works/
    */
    return {
      best_in_countries: filter(items, {
        context: "best_in_countries_section",
      }),
      home_works: filter(items, { context: "top_home_work_section" }),
      useful_resources: filter(items, { context: "useful_resources_section" }),
      city_left: filter(items, { context: "city_left_section" }),
      city_right: filter(items, { context: "city_right_section" }),
      footer_bottom: filter(items, { context: "footer_bottom_section" }),
    };
  },

  // ORDER FORM DATA PREPARATION
  prepareOrderSubjects: (items) => {
    let result = [];
    forEach(items, (item, _idx) => {
      const itemsTitle = item?.subject?.subject_heading?.name;
      const newgroup = {
        groupTitle: itemsTitle,
        subjects: [],
      };

      forEach(items, (subject) => {
        if (itemsTitle === subject?.subject?.subject_heading?.name) {
          newgroup?.subjects.push(subject?.subject);
        }
      });
      result.push({
        ...newgroup,
        subjects: orderBy(newgroup?.subjects, ["name"], ["asc"]),
      });
    });
    return uniqBy(result, "groupTitle");
  },
  prepareOrderDays: (items) => {
    if (!size(items)) return [];
    return map(items, (item) => item?.day);
  },
  prepareOrderDetails: (item, country_id) => {
    const reference_style = find(orderReferances, {
      name: item?.reference_style,
    });

    let obj = {};
    if (!size(item)) return obj;

    obj = {
      formValues: {
        topic: item?.topic,
        service_id: JSON.stringify(item?.service),
        subject_id: JSON.stringify(item?.subject),
        page_id: JSON.stringify({
          id: item?.service_page?.id,
          title: item?.service_page?.title,
          page_type_id: item?.service_page?.page_type_id,
          total_pages: item?.service_page?.total_pages,
        }),
        day_id: JSON.stringify(item?.day),
        specify_requirement: item?.specify_requirement,
        country_id,
        reference_style,
        pageID: item?.page_id,
        discount_id: item?.discount_id ?? "",
      },
      unit_price: item?.unit_price,
      total_price: item?.total_price,
      serviceID: item?.service_id,
      subjectID: item?.subject_id,
      dayID: item?.day_id,
    };
    return { ...obj };
  },
  getDynamicSubjectsValue: (data, query) => {
    const filtered = data.filter((item) =>
      item?.subject?.name
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(query.toLowerCase().replace(/\s+/g, "")),
    );

    return get().prepareOrderSubjects(filtered);
  },
  //   CUSTOMER TRANSFER DATA TABLE PREPARATION
  prepareTransferRequestData: (items) => {
    const lists = items;
    const getFormatedCurrency = useAppStore.getState()?.getFormatedCurrency;
    const result = map(lists, (item) => {
      return {
        ...item,
        payoneer_email: item?.payoneer_email ?? "N/A",
        user_number: size(item?.user) ? `${item?.user?.user_number}` : "N/A",
        paypal_invoice_link: item?.paypal_invoice_link ?? "N/A",
        created_at: getFormatedDateAndTime(item?.created_at),
        status: item?.status?.translated_name ?? "N/A",
        amount: getFormatedCurrency(item?.amount),
      };
    });
    return size(lists) ? result : lists;
  },
  prepareTransferHistoryData: (items) => {
    const lists = items?.data;
    const getFormatedCurrency = useAppStore.getState()?.getFormatedCurrency;
    const result = map(lists, (item) => {
      return {
        ...item,
        amount: getFormatedCurrency(item?.amount),
        transaction_no:
          `${item?.transaction?.prefix ?? ""}-${
            item?.transaction?.transaction_no ?? ""
          }` ?? "N/A",
        transaction_type: item?.transaction?.transaction_type ?? "N/A" ?? "N/A",
        account_number: item?.account_number ?? "N/A",
        bank_name: item?.bank_name ?? "N/A",
        payoneer_email: item?.payoneer_email ?? "N/A",
        paypal_invoice_link: item?.paypal_invoice_link ?? "N/A",
        created_at: getFormatedDateAndTime(item?.created_at),
        status: item?.status?.translated_name ?? "N/A",
        note: item?.transaction?.note ?? "N/A",
      };
    });
    return size(lists) ? result : lists;
  },

  //   AFFILIATE/REFER AND EARN PAGE DATA PREPARATION
  prepareFinanceData: (items) => {
    const lists = items?.data;
    const getFormatedCurrency = useAppStore.getState()?.getFormatedCurrency;
    let totalBalance = reduce(lists, (sum, item) => sum + +item?.amount, 0);
    let prevTotal = 0;
    const result = map(lists, (item) => {
      const obj = {
        ...item,
        transaction_no: `${item?.prefix}-${item?.transaction_no}` ?? "N/A",
        created_at: getFormatedDateAndTime(item?.created_at),
        status: item?.status?.translated_name ?? "N/A",
        amount: getFormatedCurrency(item?.amount),
        balance: getFormatedCurrency(totalBalance - prevTotal),
      };
      prevTotal += +item?.amount;
      return obj;
    });
    return size(lists) ? result : lists;
  },
  prepareTopEarnersData: (items) => {
    const lists = items?.data;
    const result = map(lists, (item) => {
      const obj = {
        ...item,
        user_id: item?.user_id ?? `N/A`,
        country: item?.country ?? `N/A`,
        assignment_referred: item?.assignment_referred ?? `N/A`,
        total_earning: `${item?.total_earning}` ?? `N/A`,
        note: size(item?.note) ? (
          <div className="px-2 flex items-center justify-between gap-2">
            <div className="">{ParseHTML(item?.note)}</div>
            <div className="text-xl md:text-3xl text-orange-400">
              {item?.sorting_order === "1" ? (
                <Image
                  customClasses="mix-blend-multiply h-10 w-10"
                  src="/images/affiliate_page/1st_badge.svg"
                />
              ) : item?.sorting_order === "2" ? (
                <Image
                  customClasses="mix-blend-multiply h-10 w-10"
                  src="/images/affiliate_page/2nd_badge.svg"
                />
              ) : item?.sorting_order === "3" ? (
                <Image
                  customClasses="mix-blend-multiply h-10 w-10"
                  src="/images/affiliate_page/3rd_badge.svg"
                />
              ) : null}
            </div>
          </div>
        ) : null,
      };
      return obj;
    });
    return size(lists) ? result : lists;
  },
  prepareNameWithStar: (text) => {
    try {
      return `${text.substr(0, 3)}***`;
    } catch {
      return `******`;
    }
  },

  prepareEmailWithStar: (text, splitOn) => {
    try {
      const splited = text?.split(splitOn);
      const domExt = splited[1].split(".")[1];
      return `${splited[0].substr(0, 2)}***${splitOn}***.${domExt}`;
    } catch {
      return `****@***.com`;
    }
  },
  prepareMyReferalData: (items) => {
    const lists = items?.data;
    const result = map(lists, (item) => {
      const email = get()?.prepareEmailWithStar(
        item?.referral_user?.email,
        "@",
      );
      const obj = {
        ...item,
        created_at: size(item?.referral_user)
          ? getFormatedDateAndTime(item?.referral_user?.created_at)
          : "N/A",
        name: size(item?.referral_user)
          ? get()?.prepareNameWithStar(item?.referral_user?.name)
          : "N/A",
        email: size(item?.referral_user) ? email : "N/A",
        // phone: size(item?.referral_user) ? item?.referral_user?.phone : "N/A",
        phone: size(item?.referral_user) ? "***********" : "N/A",
      };
      return obj;
    });

    return size(lists) ? result : lists;
  },
  prepareMyEarningsData: (items) => {
    const lists = items?.commissions?.data ?? [];
    const getFormatedCurrency = useAppStore.getState()?.getFormatedCurrency;
    const result = map(lists, (item) => {
      const obj = {
        ...item,
        order_id: size(item?.order) ? item?.order?.order_number : "N/A",
        user_number: size(item?.referral_user)
          ? item?.referral_user?.user_number
          : "N/A",
        payment_date:
          size(item?.order) && size(item?.order?.payments[0])
            ? getFormatedDateAndTime(item?.order?.payments?.created_at)
            : "N/A",
        cash_earning: getFormatedCurrency(
          size(item?.cash_earning) ? item?.cash_earning : "0.00",
        ),
        wallet_earning: getFormatedCurrency(
          size(item?.wallet_earning) ? item?.wallet_earning : "0.00",
        ),
        total_earnig: getFormatedCurrency(
          item?.total_earning !== null ? item?.total_earning : "0.00",
        ),
      };
      return obj;
    });
    return size(lists) ? result : lists;
  },

  // ABOUT US DATA PREPARATION
  prepareAboutUsHeading: (res) => {
    if (!size(res)) return {};

    const result = {
      heroHeading: find(res, ["context", "hero_section"]),
      globalLeaderHeadings: find(res, ["context", "global_leader_section"]),
      premiumHeadings: find(res, ["context", "why_premium_is_unique_section"]),
      wantToOrderHeadings: find(res, ["context", "want_to_order_section"]),
    };
    return result;
  },

  // SAMPLE PAGE DATA PREPARATION

  prepareSamplePaperTypes: (items) => {
    if (!size(items)) return [];
    const res = map(items, (item) => ({
      id: item.id,
      name: item.name,
      check: true,
    }));

    return [
      {
        id: 0,
        name: "Choose The Academic Paper Type",
        check: false,
      },
      ...res,
    ];
  },

  prepareSampleCategories: (items) => {
    // GROUP ITEMS BY ALPHABET
    if (!size(items)) {
      return [];
    }
    const groupNames = (arr) => {
      const map = arr.reduce((acc, val) => {
        let char = val?.name?.charAt(0).toUpperCase();
        acc[char] = [].concat(acc[char] || [], val);
        return acc;
      }, {});
      const res = Object.keys(map).map((el) => ({
        letter: el,
        lists: map[el],
      }));
      return res;
    };
    const itemsByName = groupNames(items);
    const result = sortBy(itemsByName, (item) => item?.letter);
    return result;
  },
  prepareFilteredSampleCategories: (items) => {
    if (!size(items)) {
      return [];
    }
    const result = map(items, (item) => {
      return {
        service_id: item?.id,
        id: item?.subject?.id,
        name: item?.subject?.name,
        slug: item?.subject?.slug,
        totalSamples: size(item?.subject?.subject_pages) ?? 0,
      };
    });

    return result;
  },

  prepareSearchedSampleCategoryList: (items) => {
    if (!size(items)) {
      return [];
    }

    const result = map(items, (item) => {
      return {
        ...item,
        title: item?.title,
        subject_slug: item?.subject?.slug,
      };
    });

    return result;
  },

  // FAQ DETAILS PAGE DATA PREPARATION

  prepareFAQDetailsData: (res) => {
    if (!size(res)) return [];

    let faqItem = {};

    forEach(res, (value) => {
      faqItem[value?.name] = {
        content: <FAQAccordion faqs={value?.faqs_details ?? []} />,
        order: value?.order ?? ``,
      };
    });

    return faqItem;
  },
}));

export default useDataPreparationStore;
