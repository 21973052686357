export { writerDashBTabs, writerOrderTableHeader } from "./writerDashboardData";

export const AppPlaceholderImage = `/images/svgs/assignment_premium_placeholder_image_(340x160).svg`;
// export const imageApi = `/images/primary.jpg`;

export const orderPages = [
  { id: 1, title: "1 Page / 250 Words", total_pages: "1" },
  { id: 1, title: "2 Page / 500 Words", total_pages: "2" },
  { id: 1, title: "3 Page / 750 Words", total_pages: "3" },
];
export const orderDays = [
  { id: 1, name: "1 Days" },
  { id: 2, name: "2 Days" },
  { id: 3, name: "3 Days" },
];

export const orderReferances = [
  {
    id: 9,
    name: "Harvard",
  },
  {
    id: 3,
    name: "APA",
  },
  {
    id: 1,
    name: "ACS",
  },
  {
    id: 2,
    name: "AGLSC",
  },
  {
    id: 12,
    name: "MLA",
  },
  {
    id: 14,
    name: "OSCOLA",
  },
  {
    id: 17,
    name: "Vancouver",
  },
  {
    id: 4,
    name: "APSA",
  },
  {
    id: 5,
    name: "BMJ",
  },
  {
    id: 6,
    name: "Chicago",
  },
  {
    id: 10,
    name: "IEEE",
  },
  {
    id: 11,
    name: "MHRA",
  },
  {
    id: 13,
    name: "Open",
  },
  {
    id: 15,
    name: "Oxford",
  },
  {
    id: 16,
    name: "Turabian",
  },
  {
    id: 7,
    name: "Footnotes",
  },
  {
    id: 8,
    name: "Footnotes and bibliography",
  },
  {
    id: 17,
    name: "Other",
  },
];

export const cartTabs = [
  {
    id: 1,
    label: `CART`,
  },
  {
    id: 2,
    label: "CURRENT ORDER",
  },
  {
    id: 3,
    label: "DELIVERED ORDER",
  },
  {
    id: 4,
    label: "FEEDBACK/RIVISION ORDER",
  },
];
