import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const FAQHeader = () => {
  const state = useHomeStore();
  const { faqHeader } = state;
  return (
    <div>
      <HeaderStyleOne
        // sectionName="Frequently Asked Questions"
        // sectionMiddle="Often Asked Questions Regarding Our Services"
        // sectionFooter="We are proud to say that we have scored a 96% customer satisfaction rate in providing the best assignment help online."
        pageSection={faqHeader}
      />
    </div>
  );
};
