import { ParseHTML } from "../../../utils";
import { Heading } from "../../atoms";

const GuaranteesCard = ({
  // slug = "",
  icon = "",
  heading = "",
  desc = "",
  itemOrder = "",
}) => {
  return (
    <div
      className={`border border-stone-400 rounded-md py-5 px-3 group-hover/guarantees:shadow-2xl group-hover/guarantees:translate-y-[-10px] transition-all duration-300`}
      style={{ order: `${itemOrder}` }}
    >
      <div className="flex items-center justify-center">
        <div className="text-primary-hover flex items-center justify-center h-[5rem] w-[5rem] border border-secondary-dark p-4 text-2xl md:text-5xl font-semibold rounded-full">
          {icon}
        </div>
      </div>
      <div>
        <Heading
          customClasses="font-semibold capitalize my-3 text-center text-base xl:text-lg"
          heading={heading}
        />
      </div>
      <div className="text-center my-2 leading-7 text-sm md:text-base">
        {ParseHTML(desc)}
      </div>
      {/* <div className="text-center py-3">
        <Link
          to={`/guarantees/${slug}`}
          className="text-sm md:text-base capitalize py-2 px-3 border rounded-md border-stone-500 group-hover/guarantees:bg-primary-hover group-hover/guarantees:text-light"
        >
          read more
        </Link>
      </div> */}
    </div>
  );
};

export default GuaranteesCard;
