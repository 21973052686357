import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../../appStates/AuthState";
import { size } from "lodash";

const AuthPageSecureRoute = () => {
  const authstate = useAuthStore((state) => state);
  const { getAccessToken, getWriterToken } = authstate;

  const notAuthorized = () => !size(getAccessToken()) || size(getWriterToken());

  return size(getWriterToken()) ? (
    <Navigate to="/writer/dashboard" replace={true} />
  ) : notAuthorized() ? (
    <Outlet />
  ) : (
    <Navigate to="/student/dashboard" replace={true} />
  );
};

export default AuthPageSecureRoute;
