import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useHomeStore from "../../../appStates/HomeState";
import { useEffect } from "react";
import { ParseHTML, cn } from "../../../utils";
import { size } from "lodash";

export const FAQContent = ({
  className = "",
  buttonClassName = "",
  contents = [],
}) => {
  const { activetab, setActivetab } = useHomeStore();

  const toggleDiscloser = (id) => {
    if (id === activetab) {
      setActivetab("");
      return;
    }

    setActivetab(id);
  };

  useEffect(() => {
    return () => {
      setActivetab("");
    };
  }, []);

  const getValidContent = (res) =>
    size(res?.description) ? ParseHTML(res?.description) : "";

  return (
    <div className={cn("w-full px-4 pt-5", className)}>
      <div className="w-full bg-white">
        {contents?.map((content) => (
          <Disclosure
            key={content?.id}
            as="div"
            className={`my-3 rounded-md border border-slate-400 shadow`}
            style={{ order: content?.order }}
            onClick={() => toggleDiscloser(content?.id?.toString())}
          >
            {() => (
              <>
                <Disclosure.Button
                  className={cn(
                    `rounded-md flex w-full justify-between px-4 py-2 text-left text-[15px] font-medium capitalize tracking-wide text-dark hover:bg-gray-section focus:outline-none focus-visible:ring focus-visible:ring-yellow-500 focus-visible:ring-opacity-75 ${
                      activetab === content?.id?.toString()
                        ? "bg-gray-section"
                        : ""
                    }`,
                    buttonClassName,
                  )}
                >
                  <span className="text-slate-600">{content?.title}</span>
                  <ChevronDownIcon
                    className={`${
                      activetab === content?.id?.toString()
                        ? "rotate-180 transform"
                        : "rotate--180 transform"
                    } h-5 w-5 text-slate-600`}
                  />
                </Disclosure.Button>
                {activetab === content?.id?.toString() ? (
                  <Disclosure.Panel
                    className="text-justify text-sm px-4 pt-4 pb-2 text-gray-500 rounded-bl-md rounded-br-md"
                    static
                  >
                    {getValidContent(content)}
                  </Disclosure.Panel>
                ) : null}
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};
