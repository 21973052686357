export const SpanBtn = ({
  children,
  customClasses = "",
  onClick = () => {},
  onHover = () => {},
}) => {
  return (
    <span
      className={`cursor-pointer ${customClasses}`}
      tabIndex={0}
      role="button"
      onMouseEnter={onHover}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
