import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { universities } from "../../../utils/uiData/trustedBydata";
import { Image } from "../../atoms";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const TrustedByBody = () => {
  return (
    <div className="">
      <Swiper
        // install Swiper modules
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={10}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        slidesPerView={7}
        loop
      >
        {universities?.map((logo, _idx) => (
          <SwiperSlide key={_idx}>
            <Image
              key={_idx}
              customClasses="app-logo-ratio"
              parentClasses=""
              src={logo?.image}
              title="institute logo"
              width={230}
              height={90}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
