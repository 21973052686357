import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const OrderProcessHeader = () => {
  const state = useHomeStore();
  const { orderProcessHeader } = state;

  return (
    <div>
      <HeaderStyleOne
        // sectionName="ordering process"
        // sectionMiddle={`4 Easy Steps to Avail Our Assignment Help Service Online`}
        // sectionFooter={`The ultimate answer to the question, "Which is the best assignment writing website in the UK?" is Fortran service. With more than a decade of experience in the academic writing field, we clearly understand the concerns and anxiety of students about assignment writing. Therefore we provide the following guarantees to every student. And here's why you should trust the Fortran service!`}
        pageSection={orderProcessHeader}
      />
    </div>
  );
};
