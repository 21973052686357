import { BsFillTelephoneFill } from "react-icons/bs";
import { FaCartArrowDown, FaUserAlt } from "react-icons/fa";
import { HiChatAlt2 } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io5";
import useAppStore from "../../appStates/AppState";

const appstore = useAppStore.getState();

export const menus = [
  {
    id: 1,
    label: "whatsapp",
    icon: <IoLogoWhatsapp />,
    href: appstore?.contactInfo?.whatsappURL,
  },
  {
    id: 2,
    label: "contact us",
    icon: <BsFillTelephoneFill />,
    href: `tel:${appstore?.contactInfo?.phone}`,
  },
  {
    id: 3,
    label: "order now",
    icon: <FaCartArrowDown />,
    href: `/order-now`,
  },
  {
    id: 4,
    label: "My Account",
    icon: <FaUserAlt />,
    href: `/student/profile`,
  },
  {
    id: 5,
    label: "Live chat",
    icon: <HiChatAlt2 />,
    href: `#`,
  },
];
