import { find, size } from "lodash";
import { create } from "zustand";

const useAuthStore = create((set, get) => ({
  authCheckLoading: true,
  setAuthCheckLoading: (bool) =>
    set((state) => ({ ...state, authCheckLoading: bool })),
  isAuthLoading: false,
  setisAuthLoading: (bool) =>
    set((state) => ({ ...state, isAuthLoading: bool })),
  error: null,
  setError: (error) => set((state) => ({ ...state, error })),
  // writers Auth
  // writer_auth: localStorage.getItem("writer_token")
  //   ? JSON.parse(localStorage.getItem("writer_token"))
  //   : null,
  // setWriterAuth: (auth) => set(() => ({ writer_auth: auth })),
  // removeWriterAuth: () => set({ writer_auth: null }),
  // user/student Auth
  getWriterToken: () =>
    localStorage.getItem("writerToken")
      ? JSON.parse(localStorage.getItem("writerToken"))
      : null,
  getAccessToken: () =>
    localStorage.getItem("accessToken")
      ? JSON.parse(localStorage.getItem("accessToken"))
      : null,
  auth: null,
  setAuth: (auth) => set(() => ({ auth })),
  removeAuth: () => set({ auth: null }),
  isAcustomer: () => {
    const auth = get()?.auth;
    return auth?.customer ? auth?.customer?.id : "";
  },
  getLocalUser: () => {
    const user = get()?.auth;

    return user ? user : null;
  },
  isPhoneVerified: () => {
    const user = get()?.getLocalUser();
    return size(user?.phone_verified_at);
  },
  getBankAccounts: () => {
    const user = get()?.getLocalUser();

    let usd_bank = {};
    let gbp_bank = {};

    if (!size(user)) {
      return {
        usd_bank,
        gbp_bank,
      };
    }

    usd_bank = find(user?.bank_accounts, {
      bank_type: "usd_bank",
    });
    gbp_bank = find(user?.bank_accounts, {
      bank_type: "gbp_bank",
    });

    return {
      usd_bank,
      gbp_bank,
    };
  },

  // Auth formValues
  formValues: {
    name: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
  },
  handleFormValue: (e) => {
    set((state) => ({
      ...state,
      formValues: { ...state.formValues, [e.target.name]: e.target.value },
    }));
  },
  // Auth Modal states
  openAuthModal: false,
  setOpenAuthModal: (bool) =>
    set((state) => ({ ...state, openAuthModal: bool })),
  toggleAuthModal: (click_type) => {
    if (!!click_type && click_type === "backdrop_close") {
      return;
    }
    set((state) => ({ ...state, openAuthModal: !state?.openAuthModal }));
  },
  openNotWriterModal: false,
  setNotWriterModal: (bool) =>
    set((state) => ({ ...state, openNotUserModal: bool })),
  authTab: 0,
  setAuthTab: (num) => set((state) => ({ ...state, authTab: num })),
}));

export default useAuthStore;
