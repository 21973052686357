import { size } from "lodash";
import { Button, Heading, PragraphText, SpanText } from "../../atoms";
import { ParseHTML } from "../../../utils";
import { Link } from "react-router-dom";

const OffersCardOrganism = ({
  offer = {},
  apply = false,
  handleApply = () => {},
  activeItem,
  setActiveItem,
}) => {
  const offerID = offer?.id?.toString()?.toLocaleLowerCase();
  const activeID = activeItem?.toString()?.toLocaleLowerCase();
  const active = activeID === offerID;

  const handleDescription = () => {
    if (offerID === activeID) {
      setActiveItem("");
      return;
    }

    setActiveItem(offer?.id);
  };

  return (
    <section>
      <div className="grid md:grid-cols-[1fr_150px] gap-4 justify-items-stretch rounded-md shadow-lg px-3 py-4 border border-slate-200">
        <div className="flex gap-2">
          <div className="flex flex-col py-1 justify-center px-3 bg-dark border text-primary-hover rounded">
            <SpanText
              customClasses="text-xl font-medium"
              content={`${offer?.coupon_percentage ?? 0}%`}
            />
            <SpanText customClasses="text-lg font-medium" content="OFF" />
          </div>
          <div className="flex flex-col justify-between items-start">
            {size(offer?.title) ? (
              <Heading
                customClasses="text-lg md:text-2xl font-medium"
                heading={offer?.title}
              />
            ) : null}

            <Button
              type="button"
              customClasses={`!p-0 text-primary-hover hover:underline decoration-primary-hover outline-none focus:ring-0 border-none font-normal !text-sm ${
                active ? "underline" : ""
              }`}
              handleClick={handleDescription}
            >
              See Terms And Condotions For More Details
            </Button>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="text-center">
            <PragraphText
              customClasses="text-gray-600 capitalize font-medium"
              content={`Coupon Code`}
            />
            <Heading
              customClasses="uppercase text-base"
              heading={offer?.promocode ?? "N/A"}
            />
          </div>

          <div>
            {apply ? (
              <Button
                type="button"
                customClasses="w-full py-2 px-4 font-normal text-lg text-light bg-primary-hover hover:opacity-80 rounded-md capitalize outline-none focus:ring-0"
                handleClick={() => handleApply(offer?.promocode ?? "")}
              >
                Apply
              </Button>
            ) : (
              <Link
                to={`/order-now`}
                className="w-full py-1 px-4 font-normal text-light bg-primary-hover hover:opacity-80 rounded-md capitalize outline-none focus:ring-0"
              >
                Get Deals
              </Link>
            )}
          </div>
        </div>
      </div>

      {active ? (
        <div className="shadow-lg px-3 py-4 border border-slate-200 mt-3">
          {size(offer?.description) ? (
            <div className="">{ParseHTML(offer?.description.toString())}</div>
          ) : null}
        </div>
      ) : null}
    </section>
  );
};

export default OffersCardOrganism;
