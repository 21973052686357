import { Link } from "react-router-dom";
import { freefeatures } from "../../../utils/uiData/freefeaturesData";
import { Heading } from "../../atoms";

export const BodyRight = () => {
  return (
    <div className="rounded-md shadow-md px-2 py-4">
      <Heading
        customClasses="tracking-wide text-lg lg:text-xl font-semibold py-3 text-center text-primary"
        heading="Free Freatures"
      />

      <ul className="free_features_list text-base">
        {freefeatures?.map((feature) => (
          <li key={feature?.id}>
            <div className="flex items-center justify-between px-2 my-2 text-sm md:text-base">
              <span className="capitalize">{feature?.title}</span>
              {feature?.free ? (
                <span className="text-green-600 font-medium uppercase">
                  Free
                </span>
              ) : (
                <span className="">{feature?.price}</span>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="text-center mt-6">
        <Link
          to={`/order-now`}
          className="w-[50%] text-light py-2 px-3 bg-primary-hover rounded-md mt-1 mb-2  text-base md:text-lg uppercase hover:bg-opacity-90 font-medium border-none drop-shadow-sm transition ease-in delay-150"
        >
          Order Now
        </Link>
      </div>
    </div>
  );
};
