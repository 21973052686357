import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const WhyUsHeader = () => {
  const state = useHomeStore();
  const { whyUsHeader } = state;

  return (
    <div className="">
      <HeaderStyleOne pageSection={whyUsHeader} />
    </div>
  );
};
