import { Heading, PragraphText } from "../../../atoms";
import { RatingStars } from "../../../molecules";

const WriterProfileReviews = ({ review = {} }) => {
  const reviewcard = (
    <div
      className="shadow shadow-primary-hover p-3 bg-light my-4 rounded-md"
      key={review?.id}
    >
      <div className="flex flex-wrap gap-2 sm:justify-between">
        <div className="flex flex-col space-y-2">
          <RatingStars
            count={+review?.review ?? 0}
            customClasses="flex items-center gap-0.5"
          />
        </div>
        <div className="flex items-center gap-2">
          <div className=" bg-primary-hover p-1">
            <p className="text-sm md:text-base ">
              <strong>Order ID:</strong> 97***17
            </p>
          </div>
          <span className="font-medium bg-primary-hover p-1">
            <strong>Date:</strong> {review?.created_at ?? "08 April 2024"}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-0.5 text-sm mt-1 mb-3">
        <Heading customClasses="" heading="Information Technology" />
        <div className="flex flex-col sm:flex-row gap-1 pb-1">
          <div className="">Assignment: 10 Pages, </div>
          <div className="">
            {/* Deadline: {getFormatedDate(review?.input_date)} */}
            Deadline: 5 Days
          </div>
        </div>
      </div>

      <div className="h-[80px] my-3 overflow-auto scroll_style_thin">
        {!review?.description ? (
          <PragraphText
            customClasses="pr-4 text-sm text-justify"
            content={`Nam voluptate velit qui consequuntur ratione in sit repudiandae quaerat eum et ipsam voluptas provident et vero odio voluptates ut aliquam.`}
          />
        ) : review?.description?.length > 150 ? (
          <PragraphText
            customClasses="pr-4 text-sm text-justify"
            content={`${review?.description.substring(0, 147)}...`}
          />
        ) : (
          <PragraphText
            customClasses="pr-4 text-sm text-justify"
            content={review?.description}
          />
        )}
      </div>
    </div>
  );

  return (
    <section className="">
      <Heading
        customClasses="bg-primary-hover p-2 font-semibold max-w-[122px]"
        heading={`Reviews`}
      />

      {reviewcard}
      {reviewcard}
      {reviewcard}
    </section>
  );
};

export default WriterProfileReviews;
