import { size } from "lodash";
import useAppStore from "../../../appStates/AppState";
import { AnchoreBtn, SmallText } from "../../atoms";
import { FooterLinks } from "./FooterLinks";

export const LeftBody = () => {
  const {
    copyrightTxt = "",
    footerLinks = {},
    disclaimer = "",
  } = useAppStore();

  return (
    <div className={`text-dark`}>
      <div className={`flex flex-col md:flex-row gap-2 sm:gap-12 md:gap-6`}>
        {size(footerLinks?.home_works) ? (
          <div className="min-w-max w-full">
            <FooterLinks
              heading="Top Home work Searches"
              data={footerLinks?.home_works}
            />
          </div>
        ) : null}

        {size(footerLinks?.useful_resources) ? (
          <div className="min-w-max w-full">
            <FooterLinks
              heading="Useful Resources"
              data={footerLinks?.useful_resources}
            />
          </div>
        ) : null}

        {size(footerLinks?.city_left) ? (
          <div className="w-full">
            <FooterLinks heading="cities" data={footerLinks?.city_left} />
          </div>
        ) : null}

        {size(footerLinks?.city_right) ? (
          <div className="w-full">
            <FooterLinks heading="cities" data={footerLinks?.city_right} />
          </div>
        ) : null}
      </div>
      <div className="border-transparent border-t-2 border-gray-600 my-2" />
      <div className="">
        <FooterLinks
          data={footerLinks?.footer_bottom}
          listClasses="flex gap-2 lg:gap-3 flex-row flex-wrap text-sm"
          linkClasses="inline-block flex-auto"
          linkParentClasses="links-after-pipe"
        />
      </div>
      <div className="my-3">
        <SmallText customClasses="text-sm" content={disclaimer} />
      </div>
      <div className="flex justify-between items-center md:flex-row flex-col my-2">
        <small className="text-sm">{copyrightTxt}</small>
        {/* <SmallText
          customClasses="text-sm"
          content="Rated 4.8/5 based on 5188 Reviews"
        /> */}

        <div className="py-2 uppercase">
          <AnchoreBtn blank_page url="https://www.dmca.com">
            <span className="border p-1 bg-[#6BC530] text-light text-sm font-semibold">
              DMCA
            </span>
            <span className="border p-1 bg-dark text-light text-sm font-semibold">
              Protected
            </span>
          </AnchoreBtn>
        </div>
      </div>
    </div>
  );
};
