export const universities = [
  {
    image: "/images/trustedBy/aus_uni/1.png",
  },
  {
    image: "/images/trustedBy/uk_uni/1.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/2.png",
  },
  {
    image: "/images/trustedBy/uk_uni/2.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/3.png",
  },
  {
    image: "/images/trustedBy/uk_uni/3.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/4.png",
  },
  {
    image: "/images/trustedBy/uk_uni/4.png",
  },
  {
    image: "/images/trustedBy/aus_uni/5.png",
  },
  {
    image: "/images/trustedBy/uk_uni/5.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/6.png",
  },
  {
    image: "/images/trustedBy/uk_uni/6.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/7.png",
  },
  {
    image: "/images/trustedBy/uk_uni/7.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/8.png",
  },
  {
    image: "/images/trustedBy/uk_uni/8.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/9.png",
  },
  {
    image: "/images/trustedBy/uk_uni/9.png",
  },
  {
    image: "/images/trustedBy/aus_uni/10.png",
  },
  {
    image: "/images/trustedBy/uk_uni/10.png",
  },
  {
    image: "/images/trustedBy/aus_uni/11.png",
  },
  {
    image: "/images/trustedBy/uk_uni/11.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/12.png",
  },
  {
    image: "/images/trustedBy/uk_uni/12.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/13.png",
  },
  {
    image: "/images/trustedBy/uk_uni/13.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/14.png",
  },
  {
    image: "/images/trustedBy/uk_uni/14.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/15.png",
  },
  {
    image: "/images/trustedBy/uk_uni/15.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/16.png",
  },
  {
    image: "/images/trustedBy/uk_uni/16.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/17.png",
  },
  {
    image: "/images/trustedBy/uk_uni/17.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/18.png",
  },
  {
    image: "/images/trustedBy/uk_uni/18.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/19.png",
  },
  {
    image: "/images/trustedBy/uk_uni/19.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/20.png",
  },
  {
    image: "/images/trustedBy/uk_uni/20.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/21.png",
  },
  {
    image: "/images/trustedBy/uk_uni/21.webp",
  },
  {
    image: "/images/trustedBy/aus_uni/22.png",
  },
  {
    image: "/images/trustedBy/uk_uni/22.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/23.png",
  },
  {
    image: "/images/trustedBy/uk_uni/23.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/24.png",
  },
  {
    image: "/images/trustedBy/uk_uni/24.jpg",
  },
  {
    image: "/images/trustedBy/aus_uni/25.png",
  },
  {
    image: "/images/trustedBy/uk_uni/25.jpg",
  },
];
