import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../../appStates/AuthState";

const WritersPrivateRoute = () => {
  const state = useAuthStore((state) => state);
  const { getWriterToken } = state;

  return getWriterToken() ? (
    <Outlet />
  ) : (
    <Navigate to="/writer-signin" replace={true} />
  );
};

export default WritersPrivateRoute;
