import useHomeStore from "../../../appStates/HomeState";
import { OrderProcessCard } from "./OrderProcessCard";

export const OrderProcessBody = () => {
  const state = useHomeStore();
  const { orderProcessContents } = state;

  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 pt-3">
      {orderProcessContents?.map((step, idx) => {
        const cid = idx + 1;
        const icon =
          cid === 1
            ? `assignment_premium_submit_order`
            : cid === 2
            ? `assignment_premium_pay_for_service`
            : cid === 3
            ? `assignment_premium_recieve_solutions`
            : ``; // step 1
        const iconUrl = `/images/order_process/${icon}.svg`;
        return (
          <OrderProcessCard
            key={idx}
            step={{ ...step, iconUrl }}
            idx={idx}
            stepClasses="text-lg md:text-xl"
            titleClasses="text-lg md:text-xl"
          />
        );
      })}
    </div>
  );
};
