import { Heading, PragraphText } from "../../atoms";
import { RatingStars } from "../../molecules";

export const TestimonialCard = ({ review }) => {
  return (
    <div className="shadow-md p-3 bg-light my-4 mx-2" key={review?.id}>
      <div className="flex flex-col sm:flex-row gap-2 sm:justify-between">
        <div className="flex flex-col space-y-2">
          <Heading
            customClasses="text-sm md:text-base "
            heading={review?.name || "User Name"}
          />
          <RatingStars
            count={+review?.review ?? 0}
            customClasses="flex items-center gap-0.5"
          />
        </div>
        <span className="text-stone-400 font-medium text-xs">
          <strong>Date:</strong> {review?.created_at ?? "08 April 2024"}
        </span>
      </div>

      <div className="flex flex-col gap-0.5 text-sm mt-1 mb-3">
        <Heading
          customClasses="text-sm sm:text-base md:text-xl"
          heading="Information Technology"
        />
        <div className="flex flex-col sm:flex-row gap-1 border-b-2 pb-1">
          <div className="">Assignment: 10 Pages, </div>
          <div className="">
            {/* Deadline: {getFormatedDate(review?.input_date)} */}
            Deadline: 5 Days
          </div>
        </div>
      </div>

      <div className="h-[80px] my-3 overflow-auto scroll_style_thin">
        {!review?.description ? (
          <PragraphText
            customClasses="pr-4 text-sm text-justify"
            content={`Nam voluptate velit qui consequuntur ratione in sit repudiandae quaerat eum et ipsam voluptas provident et vero odio voluptates ut aliquam.`}
          />
        ) : review?.description?.length > 150 ? (
          <PragraphText
            customClasses="pr-4 text-sm text-justify"
            content={`${review?.description.substring(0, 147)}...`}
          />
        ) : (
          <PragraphText
            customClasses="pr-4 text-sm text-justify"
            content={review?.description}
          />
        )}
      </div>
      <div className="flex items-center justify-between gap-2">
        <div>
          <p className="text-sm md:text-base ">
            <strong>Order ID:</strong> 97***17
          </p>
        </div>

        <div className="">
          <Heading
            customClasses="text-base md:text-base"
            heading="Melbourne, Austrailia"
          />
        </div>
      </div>
    </div>
  );
};
