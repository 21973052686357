import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const FreeFeaturesHeader = () => {
  const { homePageContents } = useHomeStore();
  return (
    <div>
      <HeaderStyleOne
        pageSection={homePageContents?.free_features_page_section}
      />
    </div>
  );
};
