import { CiCircleCheck } from "react-icons/ci";
import { TiDeleteOutline } from "react-icons/ti";
import withHeaderAndFooter from "../HOCTemplates/withHeaderAndFooter";
import withSidebar from "../HOCTemplates/withSidebar";
import { useParams } from "react-router-dom";
import RouteStatusContainer from "../../../components/RouteStatusContainer";

const Page = () => {
  const { status = "" } = useParams();
  return (
    <div>
      <div className="container mx-auto px-4">
        {status === "success" ? (
          <RouteStatusContainer
            icon={CiCircleCheck}
            iconClassName="text-green-500"
            iconSize={70}
            className="text-green-500"
            title="Payment Successful"
          />
        ) : status === "cancel" ? (
          <RouteStatusContainer
            icon={TiDeleteOutline}
            iconClassName="text-red-500"
            iconSize={70}
            className="text-red-500"
            title="Payment Unsuccessful"
          />
        ) : (
          <RouteStatusContainer
            icon={TiDeleteOutline}
            iconClassName="text-gray-300"
            iconSize={70}
            className="text-slate-300"
            title="No Content Found"
          />
        )}
      </div>
    </div>
  );
};

const PageWithSidebar = withSidebar(Page);
const PaymentStatusTemplates = withHeaderAndFooter(PageWithSidebar);

export default PaymentStatusTemplates;
