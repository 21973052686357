import { size } from "lodash";
import { ParseHTML, cn } from "../../../utils";
import { Label } from "../Label";
import { Button } from "../Button";
import ErrorText from "../ErrorText";

// Text Atoms
export const SmallText = ({ customClasses = "", content = "" }) => (
  <small className={`${customClasses}`}>{content}</small>
);

export const PragraphText = ({ customClasses = "", content = "" }) => (
  <p className={`${customClasses}`}>{content}</p>
);

export const SpanText = ({ customClasses = "", content = "" }) => (
  <span className={`${customClasses}`}>{content}</span>
);

export const Heading = ({ customClasses = "", heading = "" }) =>
  size(heading) ? (
    <h2 className={cn(`text-xl font-bold`, customClasses)}>{heading}</h2>
  ) : null;

export const TextArea = ({
  parentClasses = "",
  customClasses = "",
  labelCustomClasses = "",
  id = "",
  name = "",
  rows = "4",
  handleTextArea = () => {},
  value = "",
  label = "",
  placeHolder = "",
  error = null,
  errorCustomClasses = "",
}) => (
  <div className={`grid gap-1 ${parentClasses}`}>
    {label ? (
      <Label customClasses={labelCustomClasses} htmlFor={id} label={label} />
    ) : null}
    <textarea
      className={`focus:outline-none ${customClasses}`}
      id={id}
      name={name}
      rows={rows}
      onChange={handleTextArea}
      value={value}
      placeholder={placeHolder}
    />
    <ErrorText
      error={error}
      name={name}
      errorCustomClasses={errorCustomClasses}
    />
  </div>
);

export const PaginationBtns = ({ pages = [], handleClick = () => {} }) => (
  <div className="flex items-center justify-center gap-3 py-3">
    {pages?.map((item, _idx) => (
      <Button
        type="button"
        key={_idx}
        customClasses={`hover:bg-primary-hover hover:text-light border-transparent ${
          item?.active ? "bg-primary-hover text-light" : ""
        } !text-sm`}
        disabled={item?.active || !size(item?.url)}
        handleClick={() => handleClick(item)}
      >
        {item?.label ? ParseHTML(item?.label) : null}
      </Button>
    ))}
  </div>
);
