import { FooterBody } from "../../molecules";

const FooterOrganism = () => {
  return (
    <section className={`pt-6 pb-20 bg-primary`} id="footer">
      <FooterBody />
    </section>
  );
};

export default FooterOrganism;
