import { size } from "lodash";
import useHomeStore from "../../../appStates/HomeState";
import { FAQContent } from "./FAQContent";

export const FAQBody = () => {
  const state = useHomeStore();
  const { faqs = [] } = state;

  return (
    <div className="grid md:grid-cols-2">
      <FAQContent contents={faqs?.slice(0, size(faqs) / 2)} />
      <FAQContent contents={faqs?.slice(size(faqs) / 2, size(faqs))} />
    </div>
  );
};
