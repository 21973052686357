import GuaranteesCard from "./GuaranteesCard";
import useHomeStore from "../../../appStates/HomeState";
import { size } from "lodash";
export const GuaranteesBody = () => {
  const state = useHomeStore();
  const { ourGauranteesContents } = state;

  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 pt-2">
      {ourGauranteesContents?.map((slide, key) => (
        <div key={slide?.id} className="group/guarantees">
          <GuaranteesCard
            id={slide?.id}
            slug={slide?.slug}
            icon={key + 1}
            heading={slide?.title}
            desc={
              size(slide?.post_excerpt) ? slide?.post_excerpt : slide?.content
            }
            itemOrder={slide?.order}
          />
        </div>
      ))}
    </div>
  );
};
