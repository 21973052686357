import { create } from "zustand";
import { API } from "../../utils/request";
import { find, size } from "lodash";
import useAppStore from "../AppState";
import useDataPreparationStore from "../prepare-data-state";

const useOrderPageStore = create((set, get) => {
  return {
    formValues: {
      topic: "",
    },
    getCustomerId: () =>
      localStorage.getItem("accessToken")
        ? JSON.parse(localStorage.getItem("accessToken"))?.customer?.id
        : null,
    page: 1,
    words: 250,
    quoteType: "",
    isOpen: false,
    orderDiscount: {},
    setOrderDiscount: (discount) => {
      set((state) => ({ ...state, orderDiscount: discount }));
    },
    // Order Form data from API
    services: [],
    setServices: (services) => set((state) => ({ ...state, services })),
    subjects: [],
    setSubjects: (subjects) => set((state) => ({ ...state, subjects })),
    getSubjects: async (service_id) => {
      get().setOrderFormLoading(true);
      try {
        const res = await API().get(
          `/app/service-subjects/${JSON.parse(service_id)?.id}`,
        );
        const rawSubjects = res?.data;
        const preparedSubjects = useDataPreparationStore
          .getState()
          ?.prepareOrderSubjects(rawSubjects);
        set((state) => ({ ...state, rawSubjects, subjects: preparedSubjects }));
        get().setOrderFormLoading(false);
      } catch (error) {
        get().setOrderFormLoading(false);
      }
    },
    pages: [],
    setPages: (pages) => set((state) => ({ ...state, pages })),
    getPages: async (service_id, subject_id) => {
      get().setOrderFormLoading(true);
      try {
        const res = await API().get(
          `/app/service/${JSON.parse(service_id)?.id}/subject/${
            JSON.parse(subject_id)?.id
          }/pages`,
        );
        const result = res?.data?.page?.page_type?.pages;
        if (!size(result)) {
          get().setOrderFormLoading(false);
          set((state) => ({ ...state, pages: [] }));
          return;
        }
        set((state) => ({ ...state, pages: result }));
        get().setOrderFormLoading(false);
      } catch (error) {
        get().setOrderFormLoading(false);
      }
    },
    days: [],
    setDays: (days) => set((state) => ({ ...state, days })),
    getDays: async (service_id, subject_id) => {
      get().setOrderFormLoading(true);
      try {
        const res = await API().get(
          `/app/service/${JSON.parse(service_id)?.id}/subject/${
            JSON.parse(subject_id)?.id
          }/days`,
        );
        get().setOrderFormLoading(false);
        const preparedDays = useDataPreparationStore
          .getState()
          ?.prepareOrderDays(res?.data);
        set((state) => ({ ...state, days: preparedDays }));
      } catch (error) {
        get().setOrderFormLoading(false);
      }
    },
    price: {},
    setPrice: (price = {}) => set((state) => ({ ...state, price })),
    discount_amount: 0,
    coupon_amount: 0,
    total_price: 0,
    setTotalPrice: (stage_amount) => {
      if (size(stage_amount)) {
        set((state) => ({
          ...state,
          discount_amount: stage_amount?.amount,
        }));

        set((state) => ({
          ...state,
          coupon_amount: stage_amount?.amount,
        }));

        set((state) => ({
          ...state,
          total_price: stage_amount?.amount,
        }));
        return;
      }
      const pages = get()?.formValues?.page_id
        ? JSON.parse(get()?.formValues?.page_id)
        : {};
      const amount = get()?.price?.single_price
        ? +get()?.price?.single_price
        : 0;
      const total_unit_price = size(pages)
        ? +pages?.total_pages * amount
        : amount;
      // Offer discount calc
      const offerDiscount = get()?.orderDiscount;
      const discount_percentage = !offerDiscount?.discount_percentage
        ? 0
        : +offerDiscount.discount_percentage;

      const discountAmount = discount_percentage
        ? (discount_percentage * total_unit_price) / 100
        : 0;

      // COUPON PRICE CALC
      const couponDiscount = get()?.couponResponse;
      const coupon_percentage = !couponDiscount?.coupon_percentage
        ? 0
        : +couponDiscount?.coupon_percentage;

      const couponAmount = coupon_percentage
        ? (coupon_percentage * (total_unit_price - discountAmount)) / 100
        : 0;

      const totalAmount = total_unit_price - discountAmount - couponAmount;

      set((state) => ({
        ...state,
        discount_amount: discountAmount,
      }));

      set((state) => ({
        ...state,
        coupon_amount: couponAmount,
      }));

      set((state) => ({
        ...state,
        total_price: totalAmount,
      }));
    },
    getOrderPrice: async (service_id, subject_id, day_id) => {
      get().setOrderFormLoading(true);
      try {
        const res = await API().get(
          `/app/service/${JSON.parse(service_id)?.id}/subject/${
            JSON.parse(subject_id)?.id
          }/day/${JSON.parse(day_id)?.id}/price`,
        );
        if (res?.status === 200) {
          // set details loading to update all the states
          get().setOrderFormLoading(false);

          const price = res?.data;
          if (size(price)) {
            set((state) => ({
              ...state,
              price,
            }));
          } else {
            set((state) => ({ ...state, price: {} }));
            get().setOrderFormLoading(false);
          }
        }
      } catch (error) {
        get().setOrderFormLoading(false);
      }
    },

    error: "",
    setError: (error) => set((state) => ({ ...state, error })),

    openModal: () => set((state) => ({ ...state, isOpen: true })),
    closeModal: () => set((state) => ({ ...state, isOpen: false })),
    handleQuoteType: (type) => set((state) => ({ ...state, quoteType: type })),
    handleFormValue: (e) => {
      set((state) => ({
        ...state,
        error: "",
        formValues: { ...state.formValues, [e.target.name]: e.target.value },
      }));
    },
    setFormValues: (values) => {
      set((state) => ({
        ...state,
        formValues: { ...state, formValues: values },
      }));
    },
    handleDNDUploads: (files, name) => {
      const fileList = files;

      const prevAttachments = get().formValues[name] || [];
      const attachments = Object.values(fileList);

      set((state) => ({
        ...state,
        error: "",
        formValues: {
          ...state.formValues,
          [name]: [...prevAttachments, ...attachments],
        },
      }));
    },
    handleFileDelete: (_idx) => {
      const storedFiles = get().formValues?.attachments;

      const restFiles = storedFiles?.filter(
        (item, item_idx) => item_idx !== _idx,
      );
      set((state) => ({
        ...state,
        error: "",
        formValues: { ...state.formValues, attachments: restFiles },
      }));
    },
    wallet_referral: {},
    setWalletReferral: (res) =>
      set((state) => ({ ...state, wallet_referral: res })),
    setForm: (values) =>
      set((state) => ({
        ...state,
        formValues: { ...state.formValues, ...values },
      })),
    orderFormLoading: false,
    setOrderFormLoading: (bool) =>
      set((state) => ({ ...state, orderFormLoading: bool })),
    getOrderDetails: async (id) => {
      get().setOrderFormLoading(true);
      try {
        const response = await API().get(`/app/order/${id}`);
        if (response.status === 200) {
          if (!size(response?.data)) {
            window.location.replace(`/`);
          }
          const countryNames = useAppStore.getState()?.countryNames;
          const country_id = find(countryNames, {
            id: +response?.data?.country_id,
          });

          const preparedDetails = useDataPreparationStore
            .getState()
            ?.prepareOrderDetails(response?.data, country_id);
          get().getSubjects(preparedDetails?.formValues?.service_id);
          get().getPages(
            preparedDetails?.formValues?.service_id,
            preparedDetails?.formValues?.subject_id,
          );
          get().getDays(
            preparedDetails?.formValues?.service_id,
            preparedDetails?.formValues?.subject_id,
          );
          get().getOrderPrice(
            preparedDetails?.formValues?.service_id,
            preparedDetails?.formValues?.subject_id,
            preparedDetails?.formValues?.day_id,
          );

          set((state) => ({ ...state, ...preparedDetails }));
        }
      } catch (error) {
        const response = error.response;

        if (response?.status !== 200) {
          window.location.replace(`/order-now`);
        }
        get().setOrderFormLoading(false);
        return;
      }
    },
    resetOrderState: () => {
      const resetData = {
        formValues: {
          topic: "",
          service_id: "",
          subject_id: "",
          page_id: "",
          day_id: "",
          specify_requirement: "",
          country_id: "",
          reference_style: "",
        },
        unit_price: "",
        total_price: "",
        serviceID: "",
        subjectID: "",
        dayID: "",
        pageID: "",
      };
      set((state) => ({ ...state, ...resetData }));
    },
    // terms and condition
    termsAndCons: false,
    handleTC: () => {
      get().setError("");
      set((state) => ({ ...state, termsAndCons: !get()?.termsAndCons }));
    },
    setTermsAndCons: (bool) => {
      set((state) => ({ ...state, termsAndCons: bool }));
    },

    // promo coupon
    couponResponse: {},
    setCouponResponse: (response) =>
      set((state) => ({ ...state, couponResponse: response })),

    couponLoading: false,
    setCouponLoading: (bool) =>
      set((state) => ({ ...state, couponLoading: bool })),
    couponError: false,
    setCouponError: (bool) => set((state) => ({ ...state, couponError: bool })),
    promoCode: "",
    setPromoCode: (coupon) => set((state) => ({ ...state, promoCode: coupon })),
    checkCouponValidity: async () => {
      const code = get()?.promoCode;

      if (!size(code)) return;
      get()?.setCouponLoading(true);

      try {
        const response = await API().get(`/app/coupon/${code}/verify`);
        if (response?.status === 200) {
          if (!response?.data?.status) {
            get()?.setCouponResponse(response?.data);
            get()?.setTotalPrice();
            get()?.setCouponLoading(false);
            get()?.setCouponError(false);
            return;
          }
          get()?.setCouponResponse(response?.data);
          get()?.setCouponLoading(false);
          get()?.setCouponError(true);
        }
      } catch (error) {
        get()?.setCouponError(true);
        get()?.setCouponLoading(false);
      }
    },
  };
});

export default useOrderPageStore;
