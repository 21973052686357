export const WriterGraduationHat = (props) => {
  return (
    <svg
      width={70}
      height={70}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_59_30"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={70}
        height={70}
      >
        <path d="M0 0H70V70H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_59_30)">
        <path
          d="M35 70C15.6798 70 0 54.3202 0 35C0 15.6798 15.6798 0 35 0C54.3202 0 70 15.6798 70 35C70 54.3202 54.3202 70 35 70ZM35 1.4C16.5202 1.4 1.4 16.5202 1.4 35C1.4 53.4798 16.45 68.6 35 68.6C53.55 68.6 68.6 53.55 68.6 35C68.6 16.45 53.4798 1.4 35 1.4Z"
          fill="#EC8E1F"
        />
      </g>
      <path
        d="M35 34.3C34.9298 34.3 34.9298 34.3 34.8596 34.3L11.55 29.6096C11.2 29.5404 10.9904 29.2596 10.9904 28.9096C10.9904 28.5596 11.2 28.2798 11.55 28.2096L34.8596 22.75C35 22.75 35.0702 22.75 35.2096 22.75L58.5202 28.2096C58.8702 28.2798 59.0798 28.5596 59.0798 28.9096C59.0798 29.2596 58.8 29.5404 58.5202 29.6096L35.2096 34.3C35.0702 34.3 35.0702 34.3 35 34.3ZM15.05 28.7702L35 32.8298L54.95 28.7702L35 24.0798L15.05 28.7702Z"
        fill="#EC8E1F"
      />
      <path
        d="M35.7 47.3903C35.4202 47.3903 35.2096 47.3903 34.9298 47.3903C29.3298 47.6701 20.4403 44.7999 19.8798 44.3096L19.6702 44.0999V32.6201L35 35.6999L48.8596 32.6201V44.0999L48.65 44.3096C48.1596 44.6596 41.0202 47.3903 35.7 47.3903ZM21.1403 43.2596C22.8903 43.9596 29.9596 46.1298 34.9298 45.9201C39.3403 46.1298 45.7798 43.9596 47.3903 43.2596V34.4403L35 37.1701L21.1403 34.3701V43.2596Z"
        fill="#EC8E1F"
      />
      <path
        d="M52.5 29.8901L34.9298 28.4199C34.5096 28.4199 34.2298 27.9997 34.2298 27.6497C34.2298 27.2295 34.65 26.9497 35 26.9497L52.5 28.4199C52.9903 28.4901 52.85 29.8901 52.5 29.8901Z"
        fill="#EC8E1F"
      />
      <path
        d="M52.5 40.1095C52.0798 40.1095 51.8 39.7595 51.8 39.4095V29.1902C51.8 28.7701 52.15 28.4902 52.5 28.4902C52.85 28.4902 53.2 28.8402 53.2 29.1902V39.4095C53.2 39.7595 52.9202 40.1095 52.5 40.1095Z"
        fill="#EC8E1F"
      />
      <path d="M51.8 40.8096H53.2702V43.7499H51.8V40.8096Z" fill="#EC8E1F" />
    </svg>
  );
};
