import { getActionBtn } from "../../components/AppTable";

export const writerDashBTabs = [
  {
    id: 2,
    label: `order cart`,
  },
  {
    id: 1,
    label: `all orders`,
  },
  {
    id: 3,
    label: "balance",
  },
  {
    id: 4,
    label: "account settings",
  },
];

export const writerBalanceTableHeader = [
  {
    id: 1,
    Header: "SL",
    accessor: "sl",
    width: "table-col-300",
    className: "capitalize py-2 text-center",
  },
  {
    id: 2,
    Header: "order id",
    accessor: "order_id",
    width: "table-col-150",
    className: "capitalize py-2 text-center",
  },
  {
    id: 3,
    Header: "amount",
    accessor: "amount",
    className: "capitalize py-2 text-center",
  },
  {
    id: 4,
    Header: "order status",
    accessor: "order_status",
    className: "capitalize py-2 text-center",
  },
  {
    id: 5,
    Header: "payment status",
    accessor: "payment_status",
    className: "capitalize py-2 text-center",
  },
];

export const writerBalanceTableData = [
  {
    sl: 1,
    order_id: 123456781,
    amount: "124",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 2,
    order_id: 123456782,
    amount: "114",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 3,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 4,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 5,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 6,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 7,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 8,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 9,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 10,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 11,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 12,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 13,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 14,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "pending",
  },
  {
    sl: 15,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "withdrawable",
  },
  {
    sl: 17,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "withdrawable",
  },
  {
    sl: 18,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "claimed",
  },
  {
    sl: 19,
    order_id: 123456783,
    amount: "100",
    order_status: "completed",
    payment_status: "claimed",
  },
];

export const writerOrderTableHeader = [
  {
    id: 1,
    Header: "SL",
    accessor: "sl",
    className: "text-center capitalize py-2",
  },
  {
    id: 2,
    Header: "order id",
    accessor: "order_id",
    className: "text-center capitalize py-2",
  },
  {
    id: 3,
    Header: "job description",
    accessor: "job_desc",
    className: "text-center capitalize py-2",
  },
  {
    id: 4,
    Header: "words",
    accessor: "total_words",
    className: "text-center capitalize py-2",
  },
  {
    id: 5,
    Header: "deadline",
    accessor: "deadline",
    className: "text-center capitalize py-2",
  },
  {
    id: 6,
    Header: "price",
    accessor: "price",
    className: "text-center capitalize py-2",
  },
  {
    id: 7,
    Header: "status",
    accessor: "status",
    className: "text-center capitalize py-2",
  },
  {
    id: 8,
    Header: "order submission",
    accessor: "order_submission",
    className: "text-center capitalize py-2",
  },
  {
    id: 9,
    Header: "actions",
    accessor: "actions",
    className: "text-center capitalize py-2",
  },
];

export const writerOrders = [
  {
    sl: 1,
    order_id: 123456789,
    job_desc: "ENL Literature Assignment",
    total_words: "200",
    deadline: "5/12/2023",
    price: "124",
    status: "pending",
    actions: getActionBtn(null, { status: "pending" }),
    order_submission: "none",
    is_new: true,
  },
  {
    sl: 2,
    order_id: 1234567810,
    job_desc: "13th Century Literature Assignment",
    total_words: "230",
    deadline: "15/11/2023 11:20:44 GMT",
    price: "150",
    status: "pending",
    actions: getActionBtn(null, { status: "pending" }),
    order_submission: "none",
    is_new: true,
  },
  {
    sl: 3,
    order_id: 1234567811,
    job_desc: "18th Century Literature Assignment",
    total_words: "250",
    deadline: "14/12/2023",
    price: "144",
    status: "in process",
    actions: getActionBtn(null, { status: "in process" }),
    order_submission: "none",
    is_new: false,
  },
  {
    sl: 4,
    order_id: 1234567812,
    job_desc: "ENG Macbeth Assignment",
    total_words: "200",
    deadline: "5/12/2023",
    price: "124",
    status: "completed",
    actions: getActionBtn(null, { status: "completed" }),
    order_submission: "none",
    is_new: false,
  },
];
