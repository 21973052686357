import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "../../../components/Container";
import { FlyoutMenu } from "../../../components/FlyoutMenu";
import { MegaMenu } from "../../../components/MegaMenu";
import { Logo } from "../../atoms";
import { HamburgerBtn, SearchBtn } from "../../molecules";
import { SearchInputMolicules } from "../../molecules";
import { useClickAway, useTimeoutFn, useWindowScroll } from "react-use";
import { CancelThinIcon } from "../../../utils/heroIcons";
import { Transition } from "@headlessui/react";
import SigninDropdown from "../../../components/SigninoutDropdown";
import { HiUser } from "react-icons/hi";
import useAuthStore from "../../../appStates/AuthState";
import { companyDatas } from "../../../utils/uiData/menuData";
import useAppStore from "../../../appStates/AppState";
import { size } from "lodash";
import OrderPageHeaderOrganism from "./OrderPageHeaderOrganism";
import CustomerHeaderOrganism from "./CustomerHeaderOrganism";
import CustomerDashboardHeader from "./CustomerDashboardHeader";
import { endPoint } from "../../../utils/request";

const HeaderOrganism = ({ customClasses = "" }) => {
  const authstates = useAuthStore();
  const { handleSideBar, megaMenus } = useAppStore();

  const { applicationAds } = useAppStore();

  const logo = applicationAds?.menu_left_logo;

  const AppLogo = size(logo?.path) ? (
    <Logo
      parentClasses="h-[28px]"
      src={`${endPoint}/${logo?.path}`}
      customClasses="w-full"
    />
  ) : (
    <Logo parentClasses="h-[28px]" src="" customClasses="w-full" />
  );

  const { menusData = [], servicesData = [] } = megaMenus;
  const { getLocalUser, authCheckLoading, getWriterToken } = authstates;

  const MegaMenuref = useRef(null);
  const Searchref = useRef(null);
  const moreMenuref = useRef(null);
  const signinMenuref = useRef(null);
  const [scaled, setIsScaled] = useState(false);
  const [showSignInMenu, setShowSignInMenu] = useState(false);
  const [, , resetScale] = useTimeoutFn(() => setIsScaled(true), 6000);
  // eslint-disable-next-line no-unused-vars
  const { _, y: scrollY } = useWindowScroll();
  const { pathname = "" } = useLocation();

  const [megaMenu, setMegaMenu] = useState(false);
  const [searchPanel, setSearchPanel] = useState(false);
  const [moreMenu, setMoreMenu] = useState(false);

  const toggleSearch = () => {
    setSearchPanel((openState) => !openState);
  };

  // close signin on outsideclick.
  useClickAway(signinMenuref, () => {
    setShowSignInMenu(false);
  });
  // more menu fetures and functionalities starts here
  const toggleMoreMenu = () => {
    setMoreMenu((openState) => !openState);
  };

  // close megamenu on outsideclick.
  useClickAway(MegaMenuref, () => {
    setMegaMenu(false);
  });

  // close moremenu on outsideclick.
  useClickAway(moreMenuref, () => {
    setMoreMenu(false);
    setMegaMenu(false);
  });

  // close search box on outsideclick.
  useClickAway(Searchref, () => {
    setMegaMenu(false);
    setSearchPanel(false);
  });

  useEffect(() => {
    // button scale effect on header render
    resetScale();
  }, []);

  const isAuthPage = () =>
    pathname.includes("/signin") || pathname.includes("/signup");

  const headerMiddle = () => {
    if (pathname.includes("/order-now")) {
      return <OrderPageHeaderOrganism />;
    }

    if (pathname.includes("/student")) {
      return <CustomerDashboardHeader />;
    }

    if (isAuthPage()) {
      return null;
    }

    return (
      <CustomerHeaderOrganism
        scaled={scaled}
        megaMenu={megaMenu}
        setMegaMenu={setMegaMenu}
        menus={menusData}
        getWriterToken={getWriterToken}
        toggleMoreMenu={toggleMoreMenu}
        toggleSearch={toggleSearch}
      />
    );
  };

  return (
    <section
      className={`${scrollY > 300 ? "sticky top-0 left-0 z-20" : ""} ${
        isAuthPage() ? "flex items-center justify-center" : ""
      } bg-dark text-white shadow-md min-h-[56px]`}
      id="header"
    >
      <div className="relative">
        <Container>
          <section className="hidden items-center justify-between md:flex xl:hidden">
            <div className="py-1">
              <Link to="/">{AppLogo}</Link>
            </div>
            <div className="lg:hidden">
              <SearchBtn onClick={toggleSearch} />
            </div>
          </section>
          <section
            className={`grid grid-cols-[1fr_1fr] py-3 gap-1 lg:gap-2 md:py-0 md:grid-cols-[1fr_auto_auto_auto] xl:grid-cols-[auto_1fr_auto_auto_auto] content-center ${customClasses}`}
          >
            <div className="flex md:hidden xl:flex items-center">
              <div className="md:hidden pr-2 md:pr-0">
                <HamburgerBtn onClick={handleSideBar} />
              </div>
              <div className="py-1">
                <Link to="/">{AppLogo}</Link>
              </div>
            </div>
            {headerMiddle()}
            {!isAuthPage() ? (
              <div
                className={`hidden md:flex items-center h-full hover:bg-[#ef8f00] py-2 cursor-pointer ${
                  showSignInMenu ? "bg-[#ef8f00]" : ""
                }`}
                role="button"
                tabIndex={0}
                onMouseEnter={() => {
                  if (authCheckLoading) {
                    return;
                  }
                  setShowSignInMenu(true);
                }}
                onMouseLeave={() => setShowSignInMenu(false)}
              >
                <span className="lg:px-2 px-1 flex items-center gap-2 capitalize font-medium">
                  <HiUser className="text-xl" />
                  {authCheckLoading ? (
                    <div className="grid gap-2 h-[40px] w-[46px]">
                      <div className="h-2 w-full bg-gray-200 rounded-md" />
                      <div className="h-2 w-full bg-gray-200 rounded-md" />
                    </div>
                  ) : !size(getLocalUser()) ? (
                    <span className="grid justify-items-start">
                      <span className="text-xs">Sign in</span>
                      <span className="md:text-sm lg:text-base">Register</span>
                    </span>
                  ) : (
                    <span className="grid justify-items-start">
                      <span className="text-xs capitalize">Hi,</span>
                      <span className="md:text-sm lg:text-base">
                        {`${getLocalUser()?.name.substr(0, 11)}.` ?? "user"}
                      </span>
                    </span>
                  )}
                </span>
              </div>
            ) : null}
          </section>
        </Container>

        {/* More Menu, Search panel, Mega menu */}
        <Transition
          show={moreMenu}
          as={"div"}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 top-[-100%]"
          enterTo="opacity-100 top-[100%]"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 top-[100%]"
          leaveTo="opacity-0 top-[-100%]"
        >
          {moreMenu ? (
            <div
              ref={moreMenuref}
              className={`absolute left-0 top-0 bg-dark w-full`}
            >
              <Container>
                <div className="hidden md:flex md:items-center md:gap-5 justify-end">
                  <Link
                    className="flex items-center gap-2 text-light capitalize md:text-sm font-medium lg:text-base h-[100%] lg:px-2 px-1 hover:bg-[#ef8f00] py-4"
                    to="/offers"
                  >
                    offers
                  </Link>
                  <Link
                    className="flex items-center gap-2 text-light capitalize md:text-sm font-medium lg:text-base h-[100%] lg:px-2 px-1 hover:bg-[#ef8f00] py-4"
                    to="/hire-writers"
                  >
                    Writers
                  </Link>
                  <div className="flex items-center h-full hover:bg-[#ef8f00] py-4">
                    <span className="lg:px-2 px-1">
                      <FlyoutMenu
                        menuTitle="Company"
                        linksArray={companyDatas}
                      />
                    </span>
                  </div>
                  <Link
                    className="hidden md:flex items-center gap-2 text-light capitalize md:text-sm font-medium lg:text-base h-[100%] lg:px-2 px-1 hover:bg-[#ef8f00] py-4"
                    to="/blogs"
                  >
                    blogs
                  </Link>
                  <span
                    className="cursor-pointer "
                    role={"button"}
                    tabIndex="0"
                    onClick={toggleMoreMenu}
                  >
                    <CancelThinIcon customClasses="w-6 h-6 text-light" />
                  </span>
                </div>
              </Container>
            </div>
          ) : null}
        </Transition>

        <Transition
          show={searchPanel}
          as={"div"}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 top-[-100%]"
          enterTo="opacity-100 top-[100%]"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 top-[100%]"
          leaveTo="opacity-0 top-[-100%]"
        >
          {searchPanel ? (
            <div className={`absolute left-0 top-0 bg-dark w-full`}>
              <Container>
                <div className="flex items-center gap-4">
                  <SearchInputMolicules ref={Searchref} />
                  <span
                    className="cursor-pointer "
                    role={"button"}
                    tabIndex="0"
                    onClick={toggleSearch}
                  >
                    <CancelThinIcon customClasses="w-6 h-6 text-light" />
                  </span>
                </div>
              </Container>
            </div>
          ) : null}
        </Transition>
      </div>
      {/* Mega menu section */}
      <div className="hidden md:block">
        <MegaMenu
          customClasses={`${megaMenu ? "active-menu" : "services-mega-menu"}`}
          onMouseEnter={() => setMegaMenu(true)}
          onMouseLeave={() => setMegaMenu(false)}
          ref={MegaMenuref}
          linksArray={servicesData}
        />
      </div>
      {/* Signup menu section */}
      <div
        className="hidden md:block"
        onMouseEnter={() => setShowSignInMenu(true)}
        onMouseLeave={() => setShowSignInMenu(false)}
      >
        <SigninDropdown show={showSignInMenu} ref={signinMenuref} />
      </div>
    </section>
  );
};

export default HeaderOrganism;
