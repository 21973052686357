import { size } from "lodash";
import { Heading, Image } from "../../atoms";
import { ParseHTML, cn } from "../../../utils";

export const OrderProcessCard = ({
  step,
  stepClasses = "",
  titleClasses = "",
  bodyClasses = "",
  idx,
  icon = "",
}) => {
  const description = step?.post_excerpt ? step?.post_excerpt : step?.content;
  return (
    <div
      className={`shadow-md bg-light rounded-md py-5 px-4 ${step?.order}`}
      key={step?.id}
    >
      {size(step?.iconUrl) ? (
        <div className="flex items-center justify-center py-3">
          <div className="rounded-full inline-block">
            <Image customClasses="h-[5rem] w-[5rem]" src={step?.iconUrl} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <div className="text-primary-hover flex items-center justify-center h-[4rem] w-[4rem] border border-secondary-dark p-4 text-2xl md:text-4xl font-semibold rounded-full">
            {icon}
          </div>
        </div>
      )}
      <div className="">
        {idx?.toString() ? (
          <Heading
            customClasses={cn(
              `font-semibold text-center capitalize`,
              stepClasses,
            )}
            heading={`step ${idx + 1}`}
          />
        ) : null}
        <Heading
          customClasses={cn(
            `capitalize text-primary font-semibold text-center pb-2`,
            titleClasses,
          )}
          heading={step?.title}
        />
        <div
          className={cn(
            `text-center leading-7 min-h-[140px] text-sm md:text-[15px]`,
            bodyClasses,
          )}
        >
          {ParseHTML(description)}
        </div>
      </div>
    </div>
  );
};
