import { CallPrimaryAPI } from "../../../utils/APIHook";
import SidebarOrganism from "../../organisms/SidebarOrganism";

const withSidebar = (Component) => {
  const NestedComponent = ({ ...props }) => {
    // Calling Primary the Api
    CallPrimaryAPI();
    return (
      <div>
        <Component {...props} />
        <SidebarOrganism />
      </div>
    );
  };
  return NestedComponent;
};

export default withSidebar;
