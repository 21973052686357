import { TopMenuRight } from "../../molecules";

const OrderPageHeaderOrganism = () => {
  return (
    <div className="hidden md:flex items-center justify-center">
      <TopMenuRight />
    </div>
  );
};

export default OrderPageHeaderOrganism;
