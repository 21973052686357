import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const OurServicesHeader = () => {
  const state = useHomeStore();
  const { servicesHeader } = state;

  return (
    <div className="">
      <HeaderStyleOne
        // sectionName="our services"
        // sectionMiddle={`Online Academic Writing Services That We Provide`}
        // sectionFooter={`Our assignment helpers on board can aid you with different assignment writing services in the UK so that you never feel the stress of drafting assignments, research papers, thesis, dissertations, essays, coursework, homework, reports, presentations, etc., according to the exact specifications. Here are the primary academic paper writing services we offer at cheap prices to students living in the UK. No matter the complexity or length of your academic paper requirements, our expert helpers provide assignment writing services on every subject under the sun including IT, Law, Engineering, MBA, Nursing, Science, Mathematics, Marketing, History, English, Philosophy and more.`}
        pageSection={servicesHeader}
      />
    </div>
  );
};
