import useAppStore from "../../../../appStates/AppState";
import { WhatsAppIcon } from "../../../../utils/reactIcons";
import { AnchoreBtn, LinkBtn, SpanText } from "../../../atoms";

export const HeroButtons = ({ className = "" }) => {
  const { contactInfo } = useAppStore();

  return (
    <section className={`${className} w-full"`}>
      <div className={`w-full`}>
        <AnchoreBtn
          blank_page
          url={contactInfo?.whatsappURL}
          customClasses="max-h-fit block text-dark shadow-md px-5 py-[2px] bg-light rounded-md hover:bg-gray-100 transition ease-in delay-150 text-center"
        >
          <div className="flex items-center justify-center gap-1">
            <div className="text-green-600">
              <WhatsAppIcon customClasses="h-6 w-6 md:h-7 md:w-7" />
            </div>
            <div className="">
              <p className="grid text-left text-sm md:text-base">
                Order On WhatsApp
                <SpanText
                  content={contactInfo?.phone}
                  customClasses="capitalize font-semibold leading-0"
                />
              </p>
            </div>
          </div>
        </AnchoreBtn>
      </div>
      <LinkBtn
        route="/order-now"
        customClasses="flex items-center justify-center text-light py-2 w-full min-h-[52px] bg-primary-hover hover:bg-opacity-90 rounded-md transition ease-in delay-150 px-5 shadow-md"
      >
        <SpanText
          customClasses="capitalize font-medium text-base lg:text-xl"
          content="Get Assignment Help"
        />
      </LinkBtn>
    </section>
  );
};
