import { size } from "lodash";
import AppLogoSvg from "../../utils/UI/AppIcons/AppLogoSvg";
import { Image } from "./Image";

export const Logo = ({
  customClasses = "",
  parentClasses = "",
  src = "",
  title = "website logo",
  svg = false,
}) => {
  return (
    <section>
      {!size(src) ? (
        <h2 className="text-lg font-semibold text-primary-hover">
          Assignment Premium
        </h2>
      ) : !svg ? (
        <Image
          customClasses={customClasses}
          parentClasses={parentClasses}
          src={src}
          alt={title}
        />
      ) : (
        <div className={parentClasses}>
          <AppLogoSvg className={customClasses} />
        </div>
      )}
    </section>
  );
};
