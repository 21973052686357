import { forwardRef, useEffect, useId, useState } from "react";
import { Link } from "react-router-dom";
import { SpanBtn } from "../atomicSections/atoms";
import { IoIosArrowForward } from "react-icons/io";
import { size } from "lodash";
import { cn } from "../utils";

const Menus = (
  { linksArray = [], customClasses = "", onMouseLeave, onMouseEnter },
  ref,
) => {
  const key1 = useId();
  const [subPages, setSubpages] = useState(null);

  const [section, setSection] = useState(null);

  useEffect(() => {
    if (size(linksArray)) {
      setSection(linksArray[0]?.name);
      setSubpages(linksArray[0]?.childpages);
    }
  }, [linksArray]);

  return (
    <div
      className={`absolute ${customClasses} mx-auto left-1 right-1 bg-light w-[calc(100%-37px)] lg:w-[calc(100%-115px)] z-30 shadow-lg rounded-br-sm rounded-bl-sm transition ease-in-out delay-300 pt-1`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      ref={ref}
    >
      <div className="grid grid-cols-[20%_80%] h-[296px] grid-2">
        <div className="h-[296px] overflow-y-auto">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              WebkitFlexDirection: "column",
            }}
          >
            {linksArray?.map(({ id, name, childpages, order }) => (
              <div
                className={cn("border-b-gray-not-last-child")}
                key={"PopoverPanel<>" + name + id}
                style={{
                  order: `${order}`,
                }}
              >
                <SpanBtn
                  onHover={() => {
                    setSection(name);
                    setSubpages(childpages);
                  }}
                  customClasses={`capitalize p-2 text-dark hover:bg-white w-full h-full block bg-[#f1f2f4] text-sm ${
                    section === name ? "bg-white" : ""
                  }`}
                >
                  <span className="flex items-center justify-between">
                    {name} <IoIosArrowForward />
                  </span>
                </SpanBtn>
              </div>
            ))}
          </div>
        </div>
        <div className="text-black pl-3">
          <div className="grid grid-cols-4 gap-2 h-[296px] overflow-y-auto">
            {" "}
            {/**321px */}
            {subPages?.map((contents, i) => (
              <div className="py-1" key={key1 + i}>
                {/* <h1 className="text-lg font-semibold mb-1">{contents?.name}</h1> */}
                <ul className="grid">
                  {contents?.pages?.map((page) => (
                    <Link
                      className="hover:text-primary capitalize block"
                      to={`/${page?.slug}`}
                      key={"subpages <>" + key1 + i + page?.title}
                    >
                      <li className="text-sm" key={"subpages <>" + page?.title}>
                        {page?.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export const MegaMenu = forwardRef(Menus);
