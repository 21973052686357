import { Link } from "react-router-dom";

export const MenuBarBtn = ({ to = "/", icon = "", label = "" }) => {
  return (
    <div className="">
      <Link to={to} className="block text-center">
        <div
          className={`flex items-center justify-center text-xl ${
            label.toLocaleLowerCase() === "order now"
              ? "p-3 bg-primary rounded-md"
              : ""
          }`}
        >
          {icon}
        </div>
        {label.toLocaleLowerCase() === "order now" ? null : (
          <div className="text-xs capitalize font-medium">{label}</div>
        )}
      </Link>
    </div>
  );
};
