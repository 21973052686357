import { create } from "zustand";

const useHomeStore = create((set) => ({
  homePageContents: {},
  setHomePageContents: (res) => {
    set((state) => ({ ...state, homePageContents: res }));
  },
  homeHeroSection: {},
  heroContent: {},
  setHomeHeroContent: (res) => {
    set((state) => ({ ...state, heroContent: res }));
  },
  // site ratings state
  ratings: [],
  setRatings: (res) => {
    set((state) => ({
      ...state,
      ratings: res?.reviews,
      homeHeroSection: res?.home_page_hero_page_section,
    }));
  },
  // our gaurantees state
  ourGauranteesContents: [],
  ourGauranteesHeader: {},
  setOurGauranteesContents: (res) => {
    set((state) => ({
      ...state,
      ourGauranteesContents: res?.our_gurantees,
      ourGauranteesHeader: res?.page_section,
    }));
  },
  // order process state
  orderProcessContents: [],
  orderProcessHeader: {},
  setOrderProcessContents: (res) => {
    set((state) => ({
      ...state,
      orderProcessContents: res?.order_process,
      orderProcessHeader: res?.page_section,
    }));
  },
  // Why us state
  whyUsContents: [],
  whyUsHeader: {},
  setWhyUsContents: (res) => {
    set((state) => ({
      ...state,
      whyUsContents: res?.why_us,
      whyUsHeader: res?.page_section,
    }));
  },
  // Writers state
  writers: [],
  writersHeader: {},
  setWriters: (res) => {
    set((state) => ({
      ...state,
      writers: res?.writers,
      writersHeader: res?.page_section,
    }));
  },
  // Counters state
  counters: [],
  countersHeader: {},
  setCounter: (res) => {
    set((state) => ({
      ...state,
      counters: res?.counter,
      countersHeader: res?.page_section,
    }));
  },
  // Services state
  services: [],
  servicesHeader: {},
  setServices: (res) => {
    set((state) => ({
      ...state,
      services: res?.services,
      servicesHeader: res?.page_section,
    }));
  },
  // OUR BLOGS state
  blogs: [],
  blogsHeader: {},
  setBlogs: (res) => {
    set((state) => ({
      ...state,
      blogs: res?.blogs?.data ?? [],
      blogsHeader: res?.page_section ?? {},
    }));
  },
  // ourSamples state
  ourSamples: [],
  ourSamplesHeader: {},
  setOurSamples: (res) => {
    set((state) => ({
      ...state,
      ourSamples: res?.our_samples,
      ourSamplesHeader: res?.page_section,
    }));
  },
  // ourTools state
  ourTools: [],
  ourToolsHeader: {},
  setOurTools: (res) => {
    set((state) => ({
      ...state,
      ourTools: res?.our_tools,
      ourToolsHeader: res?.page_section,
    }));
  },
  // testimonials state
  testimonials: [],
  testimonialsHeader: {},
  setTestimonials: (res) => {
    set((state) => ({
      ...state,
      testimonials: res?.testimonials,
      testimonialsHeader: res?.page_section,
    }));
  },
  // FAQ state
  activetab: "",
  setActivetab: (res) => {
    set((state) => ({ ...state, activetab: res }));
  },
  faqs: [],
  faqHeader: {},
  setFAQ: (res) => {
    set((state) => ({
      ...state,
      faqs: res?.faqs,
      faqHeader: res?.page_section,
    }));
  },
}));

export default useHomeStore;
