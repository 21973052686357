import { size } from "lodash";
import { ParseHTML, cn } from "../../../../utils";
import { Heading, SmallText } from "../../../atoms";

export const HeroHeading = ({
  className = "",
  titleClassName = "",
  middleClassName = "",
  subClassName: contentClassName = "",
  heading = "",
  smallText = "",
  pageSection = null,
}) => {
  if (size(pageSection)) {
    return (
      <div className={cn("", className)}>
        {size(pageSection?.title) ? (
          <div
            className={cn(
              "w-full text-2xl lg:text-4xl font-semibold mb-2 capitalize",
              titleClassName,
            )}
          >
            {ParseHTML(pageSection?.title)}
          </div>
        ) : null}
        {size(pageSection?.sub_title) ? (
          <div
            className={cn(
              "w-full capitalize text-lg md:text-2xl lg:text-3xl my-2",
              middleClassName,
            )}
          >
            {ParseHTML(pageSection?.sub_title)}
          </div>
        ) : null}
        {size(pageSection?.description) ? (
          <div className={cn("w-full text-sm md:text-base", contentClassName)}>
            {ParseHTML(pageSection?.description)}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <section className={cn("lg:text-left", className)}>
      <Heading
        heading={heading}
        customClasses={`text-2xl lg:text-4xl font-semibold mb-2 capitalize`}
      />
      <div className={cn("lg:w-7/12 text-sm md:text-base", contentClassName)}>
        <SmallText content={smallText} customClasses={`text-sm md:text-base`} />
      </div>
    </section>
  );
};
