import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const AssignmentCounterHeadings = () => {
  const state = useHomeStore();
  const { countersHeader } = state;

  return (
    <div className="py-4">
      <HeaderStyleOne
        customClasses="py-2 text-center text-xl lg:text-3xl font-medium text-dark"
        pageSection={countersHeader}
      />
    </div>
  );
};
