import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SpinLoader from "../SpinLoader";
import { Button } from "../../atomicSections/atoms";
import { cn } from "../../utils";

const Modal = ({
  isOpen = false,
  loading = false,
  closeModal = () => {},
  handleSubmit = () => {},
  modalTitle = "",
  modalFooter,
  btnLabel = "Submit",
  btnClasses = "",
  dialogBodyClasses = "",
  closeBtn = null,
  titleClasses = "",
  modalFooterClasses = "",
  children,
}) => {
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => closeModal("backdrop_close")}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cn(
                    "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all",
                    dialogBodyClasses,
                  )}
                >
                  <Dialog.Title
                    as="h3"
                    className={cn(
                      `text-lg font-medium leading-6 text-gray-900`,
                      titleClasses,
                    )}
                  >
                    {modalTitle}
                  </Dialog.Title>
                  <div className="mt-2">{children}</div>

                  {modalFooter ? (
                    <div
                      className={cn(
                        "flex items-center justify-end gap-3 py-1",
                        modalFooterClasses,
                      )}
                    >
                      <Button
                        type="button"
                        customClasses={btnClasses}
                        handleClick={handleSubmit}
                        disabled={loading}
                      >
                        <div className="flex items-center justify-center gap-2">
                          <span>{btnLabel}</span>
                          {loading ? (
                            <SpinLoader customClasses="h-6 w-6" />
                          ) : null}
                        </div>
                      </Button>

                      {closeBtn}
                    </div>
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Modal;
