import { Link } from "react-router-dom";

export const UpdateAlert = ({
  icon = "",
  route = "/",
  description = "",
  btnLabel = "",
  descriptionClasses = "",
  btnClasses = "",
  parentClasses = "",
}) => {
  return (
    <div className={parentClasses}>
      <div>{icon ? icon : null}</div>
      {description ? <p className={descriptionClasses}>{description}</p> : null}
      <Link to={route} className={btnClasses}>
        {btnLabel}
      </Link>
    </div>
  );
};
