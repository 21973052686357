import { AiFillStar } from "react-icons/ai";
import { cn } from "../../../utils";

export const RatingStars = ({
  count = 5,
  customClasses = "",
  starClassNames = "",
}) => {
  return (
    <div>
      <div className={cn(``, customClasses)}>
        {Array(5)
          .fill()
          .map((x, i) => (
            <span
              key={i}
              className={cn(
                `${
                  count > 0 && count !== null && i <= count
                    ? "text-primary-hover"
                    : "text-slate-400"
                }`,
                starClassNames,
              )}
            >
              <AiFillStar />
            </span>
          ))}
      </div>
    </div>
  );
};
