import { Image } from "../../atoms";

export const Avatar = ({
  src = "https://cdn1.myassignmenthelp.com/assets/profile/avatar1.svg",
  title = "avatar",
  profileAvatar = false,
}) => {
  return (
    <div className="">
      <Image
        parentClasses={`w-24 h-24 max-w-[100px] max-h-[100px] rounded-[50%] bg-gray-section ${
          profileAvatar ? "p-1 border-2 border-slate-400 overflow-hidden" : ""
        }`}
        customClasses={`${profileAvatar ? "" : "w-full h-full"} drop-shadow-sm`}
        src={src}
        title={title}
      />
    </div>
  );
};
