import { Link } from "react-router-dom";
import { Heading } from "../../atoms";
import { size } from "lodash";
import { ParseHTML, cn, getFormatedDate } from "../../../utils";

const SampleCard = ({
  item = {},
  parsehtml = false,
  btnLabel = "View Sample",
  btnContainerClassName = "",
  btnClassName = "",
}) => {
  const url = `/samples/${item?.subject?.slug}/${item?.slug}`;
  const content = item?.post_excerpt?.substring(0, 150);

  return (
    <div
      className={`my-5 mx-3 shadow-md rounded-md px-5 py-3 bg-gray-section`}
      key={item?.id}
      style={{ order: `${item?.itemOrder}` }}
    >
      <div className="my-2 flex gap-2">
        <div className="min-h-[48px]">
          {item?.title?.length > 50 ? (
            <Heading
              customClasses="text-sm sm:text-base tracker-wide font-semibold capitalize"
              heading={`${item?.title?.substring(0, 57)}...`}
            />
          ) : (
            <Heading
              customClasses="text-sm sm:text-base tracker-wide font-semibold capitalize"
              heading={item?.title}
            />
          )}
        </div>
      </div>

      <div className="w-full border-b-2 border-b-stone-200" />
      {parsehtml ? (
        <div className="min-h-[120px]">{ParseHTML(content)}</div>
      ) : (
        <div className="py-2 text-sm md:text-base">
          <div className="flex items-center justify-between">
            <span>Subject Area:</span>
            <span className="capitalize">{item?.subject?.name ?? "N/A"}</span>
          </div>
          <div className="flex items-center justify-between">
            <span>Number of Pages:</span>
            <span>{item?.sample_info?.number_of_pages ?? "N/A"}</span>
          </div>
          <div className="flex items-center justify-between">
            <span>Total Words:</span>
            <span>{item?.sample_info?.number_of_words ?? "N/A"}</span>
          </div>
          <div className="flex items-center justify-between">
            <span>Total Downloads:</span>
            <span>{item?.sample_info?.total_downloads ?? "N/A"}</span>
          </div>
          <div className="flex items-center justify-between">
            <span>Published Date:</span>
            <span className="">
              {size(item?.sample_info?.published_date)
                ? getFormatedDate(item?.sample_info?.published_date)
                : "N/A"}
            </span>
          </div>
        </div>
      )}

      <div className={cn("text-center", btnContainerClassName)}>
        <Link
          to={url}
          className={cn(
            "text-base md:text-lg font-semibold text-primary-hover hover:underline decoration-text-primary-hover capitalize transition-colors ease-in duration-150",
            btnClassName,
          )}
        >
          {btnLabel}
        </Link>
      </div>
    </div>
  );
};

export default SampleCard;
