import { create } from "zustand";

const useDashboardStore = create((set, get) => ({
  cartLoading: true,
  setCartLoading: (bool) => set((state) => ({ ...state, cartLoading: bool })),
  formValues: {
    message: "",
    feedbackfiles: [],
  },
  handleFormValue: (e) => {
    set((state) => {
      return {
        ...state,
        formValues: { ...state.formValues, [e.target.name]: e.target.value },
      };
    });
  },
  handleDNDUploads: (files, name) => {
    const fileList = files;

    const prevAttachments = get()?.formValues[name] || [];
    const attachments = Object.values(fileList);
    set((state) => ({
      ...state,
      error: "",
      formValues: {
        ...state.formValues,
        [name]: [...prevAttachments, ...attachments],
      },
    }));
  },
  handleFileDelete: (_idx) => {
    const storedFiles = get().formValues?.feedbackfiles;

    const restFiles = storedFiles?.filter(
      (item, item_idx) => item_idx !== _idx,
    );
    set((state) => ({
      ...state,
      error: "",
      formValues: { ...state.formValues, feedbackfiles: restFiles },
    }));
  },

  error: "",
  setError: (error) => set((state) => ({ ...state, error })),

  activeTab: localStorage.getItem("activeDashTab")
    ? JSON.parse(localStorage.getItem("activeDashTab"))
    : "CART",
  setActiveTab: (tab) => {
    localStorage.setItem("activeDashTab", JSON.stringify(tab));
    get()?.setPaymentSummary({});
    set((state) => ({ ...state, activeTab: tab }));
  },
  cart: [],
  setCart: (orders) => set((state) => ({ ...state, cart: orders })),

  activeOrders: [],
  setActiveOrders: (orders) =>
    set((state) => ({ ...state, activeOrders: orders })),
  pendingOrders: [],
  setPendingOrders: (orders) =>
    set((state) => ({ ...state, pendingOrders: orders })),
  completedOrders: [],
  setCompletedOrders: (orders) =>
    set((state) => ({ ...state, completedOrders: orders })),
  feedbackOrders: [],
  setFeedbackOrders: (orders) =>
    set((state) => ({ ...state, feedbackOrders: orders })),

  // DELETE ORDERS STATES
  deleteOrderModalOpen: false,
  setDeleteOrderModalOpen: (bool) =>
    set((state) => ({ ...state, deleteOrderModalOpen: bool })),
  orderID: "",
  setOrderID: (id) => set((state) => ({ ...state, orderID: id })),

  // Payment Summary
  paymentSummary: {},
  setPaymentSummary: (item) =>
    set((state) => ({ ...state, paymentSummary: item })),
  // checking if the order is to be paid partially
  isPaymentStatusPartial: () =>
    get()?.paymentSummary?.payment_status === "partial",
}));

export default useDashboardStore;
