import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { API } from "./request";

export { getPresentYear } from "./methods";
export { getFormatedDateAndTime } from "./methods";
export { getFormatedDate } from "./methods";
export { getPageSteps } from "./methods";
export { getPrevLocation } from "./methods";
export { getSplitedPageName } from "./methods";
export { copyToClipBoard } from "./methods";
export { GetLocation } from "./methods";
export { ParseHTML } from "./methods";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// get user data after login
export const getUserData = async () => {
  try {
    const response = await API().get(`/user-info`);

    if (response?.status === 200) {
      return response?.data;
    }
  } catch {
    return null;
  }
};

export const toTopofPage = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
