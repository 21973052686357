import { cn } from "../../../utils";
import { Button, Input } from "../../atoms";
import { Search } from "../../atoms/Search";

export const SearchForm = ({
  submitSearch = (e) => {
    e.preventDefault();
  },
  searchInput = "",
  setSearchInput = () => {},
  className = "",
  inputClasses = "",
  placeholder = "Search anything...",
}) => {
  return (
    <form
      onSubmit={submitSearch}
      className={`grid grid-cols-[1fr_50px] align-middle rounded-md ${className}`}
    >
      <div className="">
        <Input
          customClasses={cn(
            `text-dark placeholder:text-dark bg-light p-3 border-none focus:outline-none w-full`,
            inputClasses,
          )}
          placeHolder={placeholder}
          name="searchInput"
          value={searchInput}
          handleInput={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <Button customClasses="flex cursor-pointer items-center justify-center bg-primary hover:bg-opacity-90 rounded-none rounded-tr-md rounded-br-md border-none">
        <Search />
      </Button>
    </form>
  );
};
