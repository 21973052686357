import { AiFillStar } from "react-icons/ai";
import { cn } from "../../../utils";
import { Image, SpanText } from "../../atoms";

export const ReviewsSection = ({ site }) => {
  const { icon = "", label = "", rating = 5, logoColor = "", count = 0 } = site;

  return (
    <div className="flex gap-1 md:gap-3 flex-col md:flex-row items-center w-fit">
      <div className="md:order-2">
        <span className="text-xl lg:text-2xl font-semibold">{count}</span>
      </div>
      <div className="flex items-center gap-3">
        <div className={cn(``, logoColor)}>
          <Image
            className={cn(`app-logo-ratio`, logoColor)}
            src={icon}
            alt={label}
            loading="lazy"
            height={75}
            width={75}
          />
        </div>
        <div className="text-start">
          <div>
            <SpanText
              customClasses="lg:text-2xl font-medium "
              content={label}
            />
          </div>
          <div className={`lg:text-2xl flex items-center text-primary`}>
            {Array(rating)
              .fill()
              .map((star, idx) => (
                <span key={idx}>{<AiFillStar />}</span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
