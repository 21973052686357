import { isNaN, isString, size } from "lodash";
import { create } from "zustand";
import { endPoint } from "../../utils/request";
import { AppPlaceholderImage } from "../../utils/uiData";
import { GetLocation, getPresentYear } from "../../utils";

const useAppStore = create((set, get) => ({
  // application primary states
  userLocation: {},
  setUserLocation: async () => {
    await GetLocation(set);
  },
  contactInfo: {
    phone: `+44 7391 606458`,
    whatsappURL: `https://wa.me/message/YJYCMWSA6SBGP1`,
    email: `help@assignmentpremium.net`,
  },
  getPresentYear: () => new Date().getFullYear(),
  countryNames: [],
  setCountryNames: (res) => set((state) => ({ ...state, countryNames: res })),
  appisReady: false,
  setAppisReady: (res) => set((state) => ({ ...state, appRendered: res })),
  // mega menu states
  megaMenus: [],
  setMegaMenus: (res) => {
    set((state) => ({ ...state, megaMenus: res }));
  },
  // FOOTER CONTENTS
  disclaimer:
    "Disclaimer: The reference papers provided by AssignmentPremium.Net serve as model papers for students and should not be submitted as it is. These papers are intended to be used for research and reference purposes only.",
  copyrightTxt: `Copyright © ${getPresentYear()} AssignmentPremium.Net. All Rights
  Reserved.`,
  footerLinks: {},
  setFooterLinks: (res) => {
    set((state) => ({
      ...state,
      footerLinks: { ...state.footerLinks, ...res },
    }));
  },

  // APPLICATION ADS AND BANNERS STATE
  applicationAds: {},
  setApplicationAds: (res) => {
    set((state) => ({ ...state, applicationAds: res }));
  },
  getAdImage: (context) => {
    const applicationAds = get()?.applicationAds;

    const ad = applicationAds?.[context];
    const BannerPath = ad?.path;
    const banner = size(BannerPath)
      ? `${endPoint}/${BannerPath}`
      : AppPlaceholderImage;
    return banner;
  },

  // sidebar states
  sideBar: false,
  handleSideBar: () => {
    set((state) => ({
      ...state,
      sideBar: !state?.sideBar,
    }));
  },

  // handleLoad more methods for pagination
  loadMoreData: async (page, ok, setPage, setLoading, refetch) => {
    if (!ok) return;

    // if previous button clicked then call previous page
    if (page?.label?.split(" ").includes("Previous")) {
      setPage((state) => state - 1);
      setLoading(true);
      await refetch();
      return;
    }

    // if next button clicked then call next page
    if (page?.label?.split(" ").includes("Next")) {
      setPage((state) => state + 1);
      setLoading(true);
      await refetch();
      return;
    }
    setPage(+page?.label);
    setLoading(true);
    await refetch();
  },

  // CURRENCY SELECTOR
  // selectedCurrency: "USD",
  currencies: { usd: "$", gbp: "£" },
  getFormatedCurrency: (amount, type) => {
    const currency = get()?.currencies[type] || get()?.currencies.usd;
    amount = isNaN(amount) || isString(amount) ? +amount : amount;
    return `${currency} ${amount.toFixed(2)}`;
  },
}));

export default useAppStore;
