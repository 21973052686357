import { size } from "lodash";
import { FAQContent } from "../../molecules/FAQ/FAQContent";

const FAQAccordion = ({ faqs = [] }) => {
  if (!size(faqs)) return;

  return (
    <section>
      <FAQContent contents={faqs} className="!p-0" buttonClassName="py-3" />
    </section>
  );
};

export default FAQAccordion;
