import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const OurSamplesHeader = () => {
  const state = useHomeStore();
  const { ourSamplesHeader } = state;
  return (
    <div className="mb-2">
      <HeaderStyleOne
        // sectionName="our samples"
        // sectionMiddle="Explore Our FREE Samples"
        // sectionFooter="Have a look at our assignment writing services samples and get a taste of perfection. They can also be viewed as proof of expertise and dedication of our expert assignment helpers in providing online assignment help UK."
        pageSection={ourSamplesHeader}
      />
    </div>
  );
};
