import { cn } from "../../utils";

export const Image = ({
  parentClasses = "",
  customClasses = "",
  src = "",
  title = "website logo",
  ...rest
}) => {
  return (
    <div className={cn(`h-full w-full`, parentClasses)}>
      <img
        className={cn(`h-full w-full`, customClasses)}
        src={src}
        alt={title}
        loading="lazy"
        {...rest}
      />
    </div>
  );
};
