import { cn } from "../../../utils";

export const RoundedChip = ({ customClasses = "", children }) => (
  <span className={cn(`px-3 py-0.5 rounded-full border`, customClasses)}>
    {children}
  </span>
);

export const NoDataFound = ({ className = "", content = "No Data Found" }) => (
  <div
    className={cn(
      `flex items-center justify-center w-full py-2 font-medium md:text-xl border text-stone-600`,
      className,
    )}
  >
    {content}
  </div>
);
