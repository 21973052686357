import { BodyLeft } from "./BodyLeft";
import { BodyRight } from "./BodyRight";

export const FreeFeaturesBody = () => {
  return (
    <div className="md:grid md:grid-cols-[1fr_380px] gap-4 py-3">
      <BodyLeft />
      <BodyRight />
    </div>
  );
};
