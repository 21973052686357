import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const TrustedByHeader = () => {
  const { homePageContents } = useHomeStore();

  return (
    <div className="py-3">
      <HeaderStyleOne
        pageSection={homePageContents?.trusted_brand_page_section}
      />
    </div>
  );
};
