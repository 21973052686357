export const Form = ({
  children,
  handleSubmit = () => {},
  customClasses = "",
}) => {
  return (
    <form
      className={customClasses}
      encType="multipart/formdata"
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
};
