import { useLocation } from "react-router-dom";
import Container from "../../components/Container";
import { AlertWithCancel, TopMenuLeft, UpdateAlert } from "../molecules";
import { TopMenuRight } from "../molecules";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import useAppStore from "../../appStates/AppState";

const TopMenuOrganism = () => {
  const { contactInfo } = useAppStore();
  const { pathname = "" } = useLocation();
  const [whatsAppAlert, setWhatsAppAlert] = useState(true);
  const isHidden = ["/order-now"].includes(pathname);

  if (isHidden) {
    return null;
  }

  return (
    <section>
      <UpdateAlert
        parentClasses="flex items-center justify-center gap-2 py-1 px-3 bg-slate-100 w-full"
        descriptionClasses="text-sm"
        description="Get $20 Bonus And Earn 10% Commission On All Your Friend's Order For Life!"
        btnLabel="start earning"
        btnClasses="font-medium capitalize py-1 px-2 rounded-md bg-blue-500 text-light text-xs hover:opacity-90"
        route="/student/affiliate-earnings"
      />
      {whatsAppAlert ? (
        <AlertWithCancel
          icon={
            <div className="bg-green-600 text-light p-1 rounded-full flex items-center justify-center">
              <FaWhatsapp className="h-4 w-4" />
            </div>
          }
          parentClasses="flex items-center justify-center gap-2 py-1 px-3 bg-sky-100 w-full"
          description="Connect With Us For Instant Support On WhatsApp"
          descriptionClasses="text-sm font-medium"
          btnLabel="connect now"
          btnClasses="font-medium capitalize py-1 px-2 rounded-md bg-primary-hover text-light text-xs hover:opacity-90"
          route={contactInfo?.whatsappURL}
          handleCancel={(e) => {
            e.preventDefault();
            setWhatsAppAlert((prev) => !prev);
          }}
        />
      ) : null}{" "}
      <section
        className="hidden md:block py-3 bg-[#0c0c0c] text-light"
        id="top_menu"
      >
        <Container>
          <div className="grid grid-cols-2 gap-2 ">
            <TopMenuLeft />
            <TopMenuRight />
          </div>
        </Container>
      </section>
    </section>
  );
};

export default TopMenuOrganism;
