import { Heading } from "../../atoms";
import WriterProfileReviews from "../../organisms/WritersOrganism/WritersProfileOrganism/writer-profile-reviews";

export const WritersDescription = ({ title = "" }) => {
  return (
    <section className="flex gap-5 flex-col">
      <div className="flex gap-7 flex-col border rounded-md shadow shadow-primary-hover p-3 sm:py-3 sm:px-6">
        <Heading
          customClasses="bg-primary-hover p-2 font-semibold max-w-[122px]"
          heading={title}
        />
        <div className="flex items-center flex-wrap gap-3">
          <span className="border border-primary-hover p-1.5">
            My Languages:
          </span>
          <span className="border border-slate-400 p-1.5">
            English (UK), English (US), English (AU)
          </span>
        </div>
        <div className="grid gap-y-7">
          <p>
            I am a proficient essay writer with tremendous experience of over 10
            years. During this time, I had the opportunity to tutor various
            clients with their assignments. The tutoring profession allows me to
            learn beyond my talents and skills to serve those in need. I offer
            the best tutoring services by exceeding customer expectations. When
            a customer consults me for education services, I usually check the
            instructions to acquaint myself with the work&apos;s requirements
            and ensure that I can assist sufficiently. I only coach when I know
            I have the expertise. My experience allows me to tutor at various
            academic levels, including doctoral, master&apos;s, bachelor&apos;s,
            and high school levels.
          </p>
          <p>
            My tutoring services comprise:
            <ul className="grid gap-y-2 py-1">
              <li className="">- Draft development consultations </li>
              <li className="">
                - Giving directions on how to develop the best outline, thesis,
                or report{" "}
              </li>
              <li className="">
                - Informing customers how to ensure vocabulary enrichment{" "}
              </li>
              <li className="">
                - Tutoring on how to access quality academic sources and
                development of annotated bibliographies
              </li>
              <li className="">
                - Coaching on how to develop remarkable PPTs and brochures{" "}
              </li>
              <li className="">
                - Tutoring customers on how to do exemplary article and book
                reviews.
              </li>
              <li className="">
                - Give directions on how to write a business plan employing the
                most recommended format.
              </li>
            </ul>
          </p>
          <p>
            I have coached numerous subjects, but I approach each major with an
            open mind and a willingness to tackle new challenges. I read all the
            customer&apos;s instructions and any additional requirements for the
            assignments before giving my tutoring services. More importantly, I
            coach based on extensive research and intense reading. I have access
            to some online libraries, including Academic Search Premier and
            ProQuest, some critical online resources to get peer-reviewed
            articles to support my tutoring arguments. Tutoring is my
            unrelenting passion.
          </p>
        </div>
      </div>
      <WriterProfileReviews
        review={{
          id: 1,
          assignment_id: "8",
          input_date: "2023-12-14 08:03:51",
          review: "4.00",
          total: "5.00",
          post_excerpt: null,
          content: null,
          status_id: null,
          created_by: "1",
          order: null,
          created_at: null,
          updated_at: null,
        }}
      />
    </section>
  );
};
