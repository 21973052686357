import useAppStore from "../../../appStates/AppState";
import { cn } from "../../../utils";
import { WhatsAppIcon } from "../../../utils/reactIcons";
import { AnchoreBtn, SpanText } from "../../atoms";

export const SectionWhatsappBtn = ({ customClasses = "" }) => {
  const { contactInfo } = useAppStore();

  return (
    <div className={cn(`w-full`, customClasses)}>
      <AnchoreBtn
        blank_page
        url={contactInfo?.whatsappURL}
        customClasses="min-w-[218px] md:min-w-fit max-h-fit block text-light shadow-lg px-5 py-2 text-base md:text-lg bg-green-600 rounded-md hover:bg-green-700 transition ease-in delay-150"
      >
        <div className="flex items-center justify-center gap-2">
          <WhatsAppIcon customClasses="w-4 h-4 md:w-6 md:h-6" />
          <SpanText
            content="Order on WhatsApp"
            customClasses="md:text-lg capitalize font-medium"
          />
        </div>
      </AnchoreBtn>
    </div>
  );
};
