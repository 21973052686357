import moment from "moment/moment";
import parse from "html-react-parser";
import { useHref, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { size } from "lodash";
import axios from "axios";

export const getPrevLocation = () => {
  const location = useLocation();
  const from = location?.state?.form?.pathname || "/";
  return from;
};

export const getPageSteps = () => {
  const href = useHref();
  const steps = href.split("/");
  return steps;
};

export const getSplitedPageName = (steps) =>
  steps.split("-").map((text) => text + " ");

export const getPresentYear = () => new Date()?.getFullYear();

export const getFormatedDate = (date) =>
  moment.utc(date)?.format("L")?.replaceAll("/", "-");

export const getFormatedDateAndTime = (date) =>
  `${moment(date)
    ?.format("DD MMM YYYY h:mm a")
    ?.replaceAll("/", "-")} GMT timezone`;

export const copyToClipBoard = async (text) => {
  if (!size(text)) {
    toast.error(`Content not copied!`);
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
    toast.success(`${text} copied!`);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const ParseHTML = (text) => <div className="">{parse(text)}</div>;

export const GetLocation = async (fn) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const lat = position?.coords?.latitude;
      const lng = position?.coords?.longitude;
      try {
        const res = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`,
        );
        fn((state) => ({ ...state, userLocation: res?.data?.address }));
      } catch {
        fn((state) => ({ ...state, userLocation: null }));
      }
    });
  }
};

export const checkUserTypeAndAccess = (auth, response) => {
  if (size(auth)) {
    if (!auth?.type) return;

    const tokenNames =
      auth?.type === "3"
        ? { setToken: `accessToken`, removeToken: `writerToken` }
        : auth?.type === "2"
        ? { setToken: `writerToken`, removeToken: `accessToken` }
        : null;

    if (tokenNames) {
      localStorage.removeItem(
        tokenNames.removeToken,
        JSON.stringify(response?.data),
      );
      localStorage.setItem(tokenNames.setToken, JSON.stringify(response?.data));
    }
  }
};
