import useHomeStore from "../../../appStates/HomeState";
import { data } from "../../../utils/uiData/writersData";
import WriterCard from "../../organisms/Cards/WriterCard";
import { SectionOrderBtn } from "../OtherElements/SectionOrderBtn";

export const RightBody = () => {
  const state = useHomeStore();
  // eslint-disable-next-line no-unused-vars
  const { writers } = state;

  return (
    <>
      <div className="grid md:grid-cols-2 gap-4 py-5">
        {/*auto-rows-min*/}
        {data?.map((person) => (
          <WriterCard
            key={person.id}
            image={person?.image}
            name={person?.name}
            slug={person?.slug}
            order_completed={person?.order_completed}
            order_inprogress={person?.order_inprogress}
          />
        ))}
      </div>

      <SectionOrderBtn label="View More Writers" route="/find-writers" />
    </>
  );
};
