import { Link } from "react-router-dom";
import { SpanBtn } from "../../atomicSections/atoms";

export const TableActionBtn = ({ tableType = "", item = "" }) => {
  if (tableType === "order_list")
    return (
      <div className="flex gap-3 items-center justify-center my-1">
        {item?.status === "pending" ? (
          <>
            <SpanBtn customClasses="text-sm py-1 text-green-600  hover:text-green-500 hover:underline text-light block text-center">
              Accept
            </SpanBtn>
            <SpanBtn customClasses="text-sm py-1 text-red-600  hover:text-red-500 hover:underline text-light block text-center">
              Reject
            </SpanBtn>
          </>
        ) : item?.status === "completed" ? (
          <>
            <Link
              to={"/"}
              className="text-sm py-1 text-dark hover:underline block text-center"
            >
              See Details
            </Link>
          </>
        ) : (
          <>
            <SpanBtn customClasses="text-sm py-1 text-red-600  hover:text-red-500 hover:underline text-light block text-center">
              Cancel Order
            </SpanBtn>
          </>
        )}
      </div>
    );
  return null;
};

export const getActionBtn = (actionBtn, item) => {
  return !actionBtn ? (
    <TableActionBtn tableType="order_list" item={item} />
  ) : (
    actionBtn
  );
};
