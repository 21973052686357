import { cn } from "../../utils";

export const Button = ({
  children,
  customClasses = "",
  type = "submit",
  disabled = false,
  handleClick = () => {},
}) => {
  return (
    <button
      type={type}
      className={cn(
        `text-sm md:text-base py-1 px-4 border border-slate-600 rounded-md font-medium transition ease-in duration-150 ${
          disabled ? "opacity-75 pointer-events-none" : ""
        }`,
        customClasses,
      )}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
