import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import SpinLoader from "./components/SpinLoader";
import PaymentStatusTemplates from "./atomicSections/templates/PaymentStatusTemplate";
import PrivateRoute from "./components/PrivateRoute";
import AuthPageSecureRoute from "./components/PrivateRoute/AuthPageSecure";
import NotFoundPage from "./atomicSections/pages/NotFound";
import WritersPrivateRoute from "./components/PrivateRoute/WritersPrivateRoute";
import WriterAuthPageSecureRoute from "./components/PrivateRoute/WriterAuthPage";

const App = () => {
  const Home = lazy(() => import("./atomicSections/pages/HomePage"));
  const ServicesDetails = lazy(() =>
    import("./atomicSections/pages/ServicesDetails"),
  );
  const OrderPage = lazy(() => import("./atomicSections/pages/OrderPage"));
  const Register = lazy(() => import("./atomicSections/pages/Register"));
  const SignIn = lazy(() => import("./atomicSections/pages/Signin"));
  const WriterRegister = lazy(() =>
    import("./atomicSections/pages/WriterRegister"),
  );
  const WriterSignin = lazy(() =>
    import("./atomicSections/pages/WriterSignin"),
  );
  const WriterProfile = lazy(() =>
    import("./atomicSections/pages/WriterProfile"),
  );
  const OffersPage = lazy(() => import("./atomicSections/pages/OffersPage"));
  const HowItWorksPage = lazy(() =>
    import("./atomicSections/pages/HowItWorksPage"),
  );
  const AboutUs = lazy(() => import("./atomicSections/pages/AboutUs"));
  const WhyChooseUs = lazy(() => import("./atomicSections/pages/WhyChooseUs"));
  const VisionAndMission = lazy(() =>
    import("./atomicSections/pages/VisionAndMission"),
  );
  const GuaranteesPage = lazy(() =>
    import("./atomicSections/pages/GuaranteesPage"),
  );
  const ReviewsPage = lazy(() => import("./atomicSections/pages/ReviewsPage"));
  const FindWriters = lazy(() => import("./atomicSections/pages/FindWriters"));
  const HireWriters = lazy(() => import("./atomicSections/pages/HireWriters"));
  const ContactUs = lazy(() => import("./atomicSections/pages/ContactUs"));
  const BlogPage = lazy(() => import("./atomicSections/pages/BlogPage"));
  const SamplePage = lazy(() => import("./atomicSections/pages/SamplePage"));
  const SampleCategoryPage = lazy(() =>
    import("./atomicSections/pages/SampleCategoryPage"),
  );
  const SampleDetailsPage = lazy(() =>
    import("./atomicSections/pages/SampleDetailsPage"),
  );
  const GuaranteesDetailsPage = lazy(() =>
    import("./atomicSections/pages/GuaranteesDetailsPage"),
  );
  const FreeFeaturesDetailsPage = lazy(() =>
    import("./atomicSections/pages/FreeFeaturesDetailsPage"),
  );
  const BlogDetailsPage = lazy(() =>
    import("./atomicSections/pages/BlogsDetailsPage"),
  );

  const FooterDetailsPage = lazy(() =>
    import("./atomicSections/pages/FooterDetailsPage"),
  );

  const FAQDetailsPage = lazy(() =>
    import("./atomicSections/pages/FAQDetailsPage"),
  );

  // MegaMenu Items details
  const ServicesDetailsPage = lazy(() =>
    import("./atomicSections/pages/PageItemDetailsPage"),
  );

  // Private Pages
  const Dashboard = lazy(() => import("./atomicSections/pages/Dashboard"));
  const WithdrawBalancePage = lazy(() =>
    import("./atomicSections/pages/WithdrawBalancePage"),
  );
  const WithdrawalsPage = lazy(() =>
    import("./atomicSections/pages/WithdrawalsPage"),
  );
  const UserProfile = lazy(() => import("./atomicSections/pages/UserProfile"));
  const Affiliate = lazy(() => import("./atomicSections/pages/Affiliate"));
  const AffiliateDataPage = lazy(() =>
    import("./atomicSections/pages/AffiliateDataPage"),
  );
  const MyFinances = lazy(() => import("./atomicSections/pages/FinancePage"));
  const CartOrderDetails = lazy(() =>
    import("./atomicSections/pages/CartOrderDetails"),
  );

  // Writers Private Routes
  const WriterDashboardPage = lazy(() =>
    import("./atomicSections/pages/WriterDashboard"),
  );
  const WriterBalancePage = lazy(() =>
    import("./atomicSections/pages/WriterBalancePage"),
  );
  const WriterAccountsPage = lazy(() =>
    import("./atomicSections/templates/WritersTemplate/WriterAccountsTemplate"),
  );

  const WriterWithdrawBalancePage = lazy(() =>
    import("./atomicSections/pages/WriterWithdrawBalancePage"),
  );

  // Tools Pages
  const ParraphasingToolPage = lazy(() =>
    import("./atomicSections/pages/ParraphasingToolPage"),
  );
  const PlagarismCheckerPage = lazy(() =>
    import("./atomicSections/pages/PlagarismCheckerPage"),
  );
  const EssayTyperPage = lazy(() =>
    import("./atomicSections/pages/EssayTyperPage"),
  );
  const GrammerCheckerPage = lazy(() =>
    import("./atomicSections/pages/GrammerCheckerPage"),
  );
  const DissertationGeneratorPage = lazy(() =>
    import("./atomicSections/pages/DissertationGeneratorPage"),
  );

  const Component = (Page) => {
    return (
      <Suspense
        fallback={
          <SpinLoader sectionClass="h-screen flex items-center justify-center" />
        }
      >
        <div>
          <Page />
        </div>
      </Suspense>
    );
  };

  return (
    <div className="relative">
      <BrowserRouter>
        <Routes>
          {/* 
          
          
            CUSTOMER SECTION STARTS HERE
          
          */}

          {/* Private pages starts */}
          <Route element={<PrivateRoute />}>
            <Route path="/student/dashboard" element={Component(Dashboard)} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/student/profile" element={Component(UserProfile)} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/student/order-details/:id"
              element={Component(CartOrderDetails)}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/student/myfinances" element={Component(MyFinances)} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/student/affiliate-earnings"
              element={Component(Affiliate)}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/student/affiliate-earnings/:slug"
              element={Component(AffiliateDataPage)}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/student/withdraw-balance"
              element={Component(WithdrawBalancePage)}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/student/mywithdrawals"
              element={Component(WithdrawalsPage)}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/student/payment-status/:status"
              element={<PaymentStatusTemplates />}
            />
          </Route>
          {/* Private pages starts */}

          {/* Signin/Register pages */}
          <Route element={<AuthPageSecureRoute />}>
            <Route path="/signin" element={Component(SignIn)} />
          </Route>
          <Route element={<AuthPageSecureRoute />}>
            <Route path="/signup" element={Component(Register)} />
          </Route>

          {/* 
          
            CUSTOMER PUBLIC PAGES STARTS HERE
          
          */}
          <Route path="/blogs" element={Component(BlogPage)} />
          <Route path="/blogs/:slug" element={Component(BlogDetailsPage)} />

          {/* Service pages details (Mega menu) */}
          <Route path="/pages/:slug" element={Component(ServicesDetailsPage)} />
          <Route
            path="/free-features/:id"
            element={Component(FreeFeaturesDetailsPage)}
          />
          <Route
            path="/guarantees/:slug"
            element={Component(GuaranteesDetailsPage)}
          />
          <Route path="/samples" element={Component(SamplePage)} />
          <Route
            path="/samples/:category"
            element={Component(SampleCategoryPage)}
          />
          <Route
            path="/samples/:category/:slug"
            element={Component(SampleDetailsPage)}
          />
          <Route
            path="/our-services/:slug"
            element={Component(ServicesDetails)}
          />
          <Route path="/order-now" element={Component(OrderPage)} />
          <Route path="/writers/:name" element={Component(WriterProfile)} />
          <Route path="/offers" element={Component(OffersPage)} />
          <Route path="/how-it-works" element={Component(HowItWorksPage)} />
          <Route path="/about-us" element={Component(AboutUs)} />
          <Route path="/why-choose-us" element={Component(WhyChooseUs)} />
          <Route
            path="/vision-and-mission"
            element={Component(VisionAndMission)}
          />
          <Route path="/guarantees" element={Component(GuaranteesPage)} />
          <Route path="/find-writers" element={Component(FindWriters)} />
          <Route path="/hire-writers" element={Component(HireWriters)} />
          <Route path="/reviews" element={Component(ReviewsPage)} />
          <Route path="/contact-us" element={Component(ContactUs)} />
          <Route path="/faq" element={Component(FAQDetailsPage)} />

          {/* 
          
          
            TOOLS PAGES
          
          
          */}
          <Route
            path="/paraphrasing-tool"
            element={Component(ParraphasingToolPage)}
          />
          <Route
            path="/plagiarism-checker"
            element={Component(PlagarismCheckerPage)}
          />
          <Route path="/essay-typer" element={Component(EssayTyperPage)} />
          <Route
            path="/grammer-checker"
            element={Component(GrammerCheckerPage)}
          />
          <Route
            path="/dissertation-generator"
            element={Component(DissertationGeneratorPage)}
          />

          {/* 

            CUSTOMER PUBLIC PAGES ENDS HERE

          */}

          {/* 
          
            CUSTOMER SECTION ENDS HERE
          
          
          */}

          {/* 
          
          
            WRITER SECTION STARTS HERE 
          
          
          */}

          {/* 
          
            WRITER PRIVATE PAGES STARTS HERE
          
          */}
          <Route element={<WritersPrivateRoute />}>
            <Route
              path="/writer/dashboard"
              element={Component(WriterDashboardPage)}
            />
          </Route>

          <Route element={<WritersPrivateRoute />}>
            <Route
              path="/balance/:slug"
              element={Component(WriterBalancePage)}
            />
          </Route>

          <Route element={<WritersPrivateRoute />}>
            <Route
              path="/writer/acounts"
              element={Component(WriterAccountsPage)}
            />
          </Route>

          <Route element={<WritersPrivateRoute />}>
            <Route
              path="/writer/withdraw-balance"
              element={Component(WriterWithdrawBalancePage)}
            />
          </Route>

          {/* 
          
            WRITER REGISTER/SIGNIN PAGES

          */}
          <Route element={<WriterAuthPageSecureRoute />}>
            <Route
              path="/writer-register"
              element={Component(WriterRegister)}
            />
          </Route>

          <Route element={<WriterAuthPageSecureRoute />}>
            <Route path="/writer-signin" element={Component(WriterSignin)} />
          </Route>

          {/* FOOTER PRIMARY PAGE */}
          <Route path="/:slug" element={Component(FooterDetailsPage)} />
          {/* 
          
            PRIMARY PAGES

          */}
          <Route index path="/" element={Component(Home)} />
          <Route index path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default App;
