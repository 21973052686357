export const companyDatas = [
  {
    icon: null,
    label: "About Us",
    href: "/about-us",
  },
  {
    icon: null,
    label: "Why Choose Us",
    href: "/why-choose-us",
  },
  {
    icon: null,
    label: "Vision and Mission",
    href: "/vision-and-mission",
  },
  {
    icon: null,
    label: "Guarantees",
    href: "/guarantees",
  },
  {
    icon: null,
    label: "How it works",
    href: "/how-it-works",
  },
  {
    icon: null,
    label: "FAQ",
    href: "/faq",
  },
  {
    icon: null,
    label: "Contact Us",
    href: "/contact-us",
  },
];

export const responsiveMenuData = [
  // {
  //   id: 1,
  //   label: "home",
  //   route: "/",
  //   subPages: [],
  // },
  // {
  //   id: 2,
  //   label: "services",
  //   route: "",
  //   subPages: [
  //     {
  //       id: 1,
  //       label: "ATHE Assignment Help",
  //       route: "/services/athe-assignment-help",
  //     },
  //     {
  //       id: 2,
  //       label: "Auditing Assignment help",
  //       route: "/services/auditing-assignment-help",
  //     },
  //     {
  //       id: 3,
  //       label: "Literature",
  //       route: "/services/literature-assignment-help",
  //     },
  //     {
  //       id: 4,
  //       label: "Chemestry Assignment Help",
  //       route: "/services/chemestry-assignment-help",
  //     },
  //     {
  //       id: 5,
  //       label: "Edexcel Assignment Help",
  //       route: "/services/edexcel-assignment-help",
  //     },
  //     {
  //       id: 6,
  //       label: "English Assignment Help",
  //       route: "/services/english-assignment-help",
  //     },
  //     {
  //       id: 7,
  //       label: "Geography Assignment Help",
  //       route: "/services/geography-assignment-help",
  //     },
  //     {
  //       id: 8,
  //       label: "History Assignment Help",
  //       route: "/services/history-assignment-help",
  //     },
  //     {
  //       id: 9,
  //       label: "Zoology Assignment Help",
  //       route: "/services/zoology-assignment-help",
  //     },
  //     {
  //       id: 10,
  //       label: "EEE Assignment Help",
  //       route: "/services/eee-assignment-help",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   label: "samples",
  //   route: "/samples",
  //   subPages: [],
  // },
  {
    id: 4,
    name: "reviews",
    url: "/reviews",
    subPages: [],
  },
  {
    id: 5,
    name: "refer and earn",
    url: "/student/affiliate-earnings",
    subPages: [],
  },
  // {
  //   id: 6,
  //   name: "tools",
  //   url: "",
  //   subPages: [
  //     {
  //       id: 1,
  //       name: "Paraphrasing tool",
  //       url: "/paraphrasing-tool",
  //     },
  //     {
  //       id: 2,
  //       name: "plagarism checker",
  //       url: "/plagarism-checker",
  //     },
  //     {
  //       id: 3,
  //       name: "esaay typer",
  //       url: "/esaay-typer",
  //     },
  //     {
  //       id: 4,
  //       name: "grammer checker",
  //       url: "/grammer-checker",
  //     },
  //   ],
  // },
  {
    id: 9,
    name: "company",
    url: "/company",
    subPages: [
      {
        id: 1,
        name: "about us",
        url: "/about-us",
      },
      {
        id: 2,
        name: "why choose us",
        url: "/why-choose-us",
      },
      {
        id: 3,
        name: "vision and mission",
        url: "/vision-and-mission",
      },
      {
        id: 4,
        name: "guarantees",
        url: "/guarantees",
      },
      {
        id: 5,
        name: "hire writers",
        url: "/hire-writers",
      },
      {
        id: 6,
        name: "reviews",
        url: "/reviews",
      },
      {
        id: 7,
        name: "contact us",
        url: "/contact-us",
      },
    ],
  },

  //   {
  //   id: 7,
  //   name: "sign in",
  //   url: "/signin",
  //   subPages: [],
  // },
  // {
  //   id: 8,
  //   name: "register",
  //   url: "/signup",
  //   subPages: [],
  // },
];

// export const dashboardRoutes = [
//   {
//     id: 10,
//     name: "My Orders",
//     url: "/student/dashboard",
//     subPages: [],
//   },
//   {
//     id: 9,
//     name: "Contact Us",
//     url: "/contact-us",
//     subPages: [
//       // {
//       //   id: 1,
//       //   name: "about us",
//       //   url: "/about-us",
//       // },
//     ],
//   },
//   {
//     id: 8,
//     name: "profile",
//     url: "/student/profile",
//     subPages: [],
//   },
//   {
//     id: 201,
//     name: "My Finances",
//     url: "/student/myfinances",
//     subPages: [],
//   },
//   {
//     id: 202,
//     name: "My Withdrawals",
//     url: "/student/mywithdrawals",
//     subPages: [],
//   },
// ];
