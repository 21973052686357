import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

export const AlertWithCancel = ({
  icon = "",
  route = "/",
  description = "",
  btnLabel = "",
  descriptionClasses = "",
  btnClasses = "",
  parentClasses = "",
  handleCancel = () => {},
}) => {
  return (
    <div className={`${parentClasses} flex items-center`}>
      <div className="flex gap-2 items-center justify-center flex-1">
        {icon ? icon : null}
        {description ? (
          <p className={descriptionClasses}>{description}</p>
        ) : null}
        <Link to={route} className={btnClasses}>
          {btnLabel}
        </Link>
      </div>
      <span className="" role="button" tabIndex={0} onClick={handleCancel}>
        <RxCross2 className="w-5 h-5" />
      </span>
    </div>
  );
};
