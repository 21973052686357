import { cn } from "../../utils";
import { PragraphText } from "./Others";

const ErrorText = ({ error, name = "", errorCustomClasses = "" }) => {
  return (
    <div>
      {error?.[name] ? (
        <PragraphText
          customClasses={cn(
            "text-sm text-red-600 font-medium",
            errorCustomClasses,
          )}
          content={error?.[name][0]}
        />
      ) : null}
    </div>
  );
};

export default ErrorText;
