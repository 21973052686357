import { Link } from "react-router-dom";
import { FlyoutMenu } from "../../../components/FlyoutMenu";
import { SpanBtn } from "../../atoms";
import { HamburgerBtn, SearchBtn } from "../../molecules";

const CustomerHeaderOrganism = ({
  menus = [],
  megaMenu,
  setMegaMenu,
  getWriterToken,
  toggleSearch,
  toggleMoreMenu,
  scaled,
}) => {
  return (
    <>
      <div className="hidden md:flex md:items-center md:justify-end h-[100%]">
        <div className="flex items-center justify-between h-[100%] lg:w-11/12">
          <div className="flex h-[100%] gap-1">
            {menus?.map((menu) =>
              menu?.name.toLowerCase() === "services" ? (
                <div
                  key={menu?.id}
                  className={`relative flex items-center hover:bg-[#ef8f00] ${
                    megaMenu ? "bg-[#ef8f00]" : ""
                  }`}
                  onMouseEnter={() => setMegaMenu(true)}
                  onMouseLeave={() => setMegaMenu(false)}
                >
                  <SpanBtn customClasses="text-light capitalize md:text-sm font-medium xl:text-base h-[100%] lg:px-2 px-1 flex items-center">
                    services
                  </SpanBtn>
                </div>
              ) : (
                <Link
                  className="flex items-center gap-2 text-light capitalize md:text-sm font-medium xl:text-base h-[100%] lg:px-2 px-1 hover:bg-[#ef8f00] py-2"
                  to={`${menu?.url}`}
                  key={menu?.id}
                >
                  {menu?.name}
                </Link>
              ),
            )}
          </div>
          <div className="flex h-[100%] gap-1 justify-end">
            <Link
              className="flex items-center gap-2 text-light capitalize md:text-sm font-medium xl:text-base h-[100%] lg:px-2 px-1 hover:bg-[#ef8f00] py-2"
              to="/reviews"
            >
              reviews
            </Link>
            {!getWriterToken() ? (
              <Link
                className="flex items-center gap-2 text-light capitalize md:text-sm font-medium xl:text-base h-[100%] lg:px-2 px-1 hover:bg-[#ef8f00] py-2"
                to="/student/affiliate-earnings"
              >
                refer and earn
              </Link>
            ) : null}
            <div className="flex items-center h-full hover:bg-[#ef8f00] py-2">
              <span className="lg:px-2 px-1">
                <FlyoutMenu menuTitle="tools" />
              </span>
            </div>
            <div className="flex items-center">
              <Link
                to="/order-now"
                className={`${
                  scaled ? "" : "blink-effect"
                } py-1 md:px-2 lg:px-4 rounded-md bg-primary transition ease-in delay-150 text-dark md:text-sm xl:text-base font-medium`}
              >
                Order Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:hidden lg:flex items-center justify-end">
        <SearchBtn onClick={toggleSearch} />
      </div>

      <div className="hidden md:flex md:items-center md:justify-center ml-3">
        <HamburgerBtn
          customClasses="font-semibold"
          onClick={() => toggleMoreMenu()}
        />
      </div>
    </>
  );
};

export default CustomerHeaderOrganism;
