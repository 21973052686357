import { ParseHTML } from "../../../utils/methods";
import { Heading, PragraphText } from "../../atoms";

export const HeaderStyleWithAlignment = ({
  sectionName = "",
  sectionMiddle = "",
  sectionFooter = "",
  textAlignment = "",
  pageSection = null,
}) => {
  return (
    <div>
      {!pageSection ? (
        <div className="">
          <Heading
            customClasses={`uppercase md:text-lg lg:text-xl ${textAlignment} my-2`}
            heading={sectionName}
          />
          <Heading
            customClasses={`tracking-wide py-2 text-primary capitalize text-lg lg:text-2xl lg:text-3xl font-semibold ${textAlignment} my-2`}
            heading={sectionMiddle}
          />
          <PragraphText
            customClasses={`py-2 ${textAlignment} text-sm md:text-base my-2 leading-6 text-justify`}
            content={sectionFooter}
          />
        </div>
      ) : (
        <div className={textAlignment}>
          {pageSection?.title ? (
            <div className="uppercase md:text-lg lg:text-xl my-2">
              {ParseHTML(pageSection?.title)}
            </div>
          ) : null}
          {pageSection?.sub_title ? (
            <div className="capitalize text-lg md:text-2xl lg:text-3xl font-semibold py-2">
              {ParseHTML(pageSection?.sub_title)}
            </div>
          ) : null}
          {pageSection?.description ? (
            <div className="py-2">{ParseHTML(pageSection?.description)}</div>
          ) : null}
        </div>
      )}
    </div>
  );
};
