import { LeftBody } from "./LeftBody";
import { RightBody } from "./RightBody";

export const OurWritersBody = () => {
  return (
    <div className="grid gap-3">
      <LeftBody />
      <RightBody />
    </div>
  );
};
