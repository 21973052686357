import { Link } from "react-router-dom";
import VerifiedBlueTick from "../../../utils/reactIcons/verified-blue-tick";
import { WriterGraduationHat } from "../../../utils/reactIcons/writer-graduation-hat";
import { Heading, Image, PragraphText, SpanText } from "../../atoms";
import { RatingStars } from "../../molecules";

/** MAKE ALL DATA FROM PROPS ITEM OBJECT***** */

const WriterCard = ({
  id = "",
  name = "",
  order_completed = "",
  order_inprogress = "",
  // image = "",
  about = "Having worked as a clinical research associate, and a professional academic writer with us in the field of microbiology, I have gained immense knowledge and experience. This ha",
}) => {
  return (
    <div
      className="w-full shadow-md rounded-md py-3 bg-light max-h-fit px-5"
      key={id}
    >
      <div className="py-1">
        <div className="flex flex-wrap justify-between gap-8 sm:gap-3">
          <div className="flex gap-3">
            <div className="flex items-center justify-center py-2">
              <Image
                parentClasses="max-w-[80px]"
                customClasses=""
                className=""
                src={"https://www.assignmentdesk.co.uk/images/writer21.png"}
              />
            </div>

            <div className="">
              <div className="">
                <div className="flex gap-4 items-center">
                  <Heading
                    customClasses="tracking-wide text-lg font-semibold my-1"
                    heading={name}
                  />
                  <div className="my-1">
                    <VerifiedBlueTick />
                  </div>
                </div>
                <RatingStars customClasses="flex items-center gap-1" />
              </div>

              <div className="my-1">
                <div className="relative grid items-center px-2 py-1 font-sans text-xs text-primary-hover capitalize bg-gray-section rounded-full select-none whitespace-nowrap">
                  <div className="">
                    <div
                      className={`flex gap-1.5 items-center before:content-[''] before:w-2.5 before:h-2.5 before:bg-green-600 before:block before:rounded-full`}
                    >
                      Writer online
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-[224px_auto] md:grid-cols-[1fr_auto] gap-2 w-56">
            <div className="my-1 text-sm md:text-base flex flex-col gap-y-2">
              <SpanText
                customClasses="bg-primary-hover p-1 font-medium w-full capitalize px-4 text-center"
                content={"Completed Orders"}
              />
              <SpanText
                customClasses="bg-primary-hover p-1 font-medium w-full capitalize px-4 text-center"
                content={"Orders In Progress"}
              />
            </div>

            <div className="my-1 text-sm md:text-base flex flex-col gap-y-2">
              <SpanText
                customClasses="bg-primary-hover p-1 font-medium text-center"
                content={order_completed}
              />
              <SpanText
                customClasses="bg-primary-hover p-1 font-medium text-center"
                content={order_inprogress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 gap-2 py-3 md:py-1.5">
        <div className="flex gap-2">
          <WriterGraduationHat className="place-self-center" />
          <div className="px-3">
            <Heading
              customClasses="bg-primary-hover text-dark p-1 min-w-[124px]"
              heading="Phd."
            />
            <p className="lg:text-lg">Degree</p>
          </div>
        </div>
        <div className="">
          <Heading
            customClasses="bg-primary-hover text-dark text-center p-1"
            heading="Competencies"
          />
          <p className="text-sm text-center">
            Nursing, Business and Management, English
          </p>
        </div>
      </div>

      <div className="py-2 border-t border-primary-hover">
        <div className="">
          <PragraphText
            customClasses="text-justify text-sm md:text-base"
            content={`${about.substring(0, 200)} ...`}
          />
        </div>
        <div className="flex flex-col sm:flex-row items-center gap-2 sm:justify-between py-3">
          <Link
            className="py-2 px-4 w-full sm:w-56 text-center md:text-lg capitalize bg-primary-hover font-bold app-transition-in-150 hover:shadow hover:shadow-primary-hover rounded-md"
            to={`/writers/test_name`}
          >
            view profile
          </Link>
          <Link
            className="py-2 px-4 w-full sm:w-56 text-center md:text-lg capitalize bg-primary-hover font-bold app-transition-in-150 hover:shadow hover:shadow-primary-hover rounded-md"
            to={`/writers/test_name`}
          >
            hire me
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WriterCard;
