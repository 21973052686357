import { ParseHTML } from "../../../utils";
import { Heading, Image, LinkBtn } from "../../atoms";

const ServicesCard = ({
  id = "",
  slug = "",
  title = "",
  image = "",
  body = "",
}) => {
  return (
    <div className="shadow-md rounded-md bg-light" key={id}>
      <Image
        src={image}
        title={title}
        customClasses="rounded"
        parentClasses="pr-3 pl-3 pt-3 h-[180px] object-cover"
      />
      <div className="px-3 pt-3 pb-5 min-h-[200px]">
        <div className="my-2">
          <Heading
            customClasses="tracking-wide text-sm sm:text-base font-semibold my-1"
            heading={title}
          />
          <div className="md:leading-7 text-justify h-[112px] pr-2 lg:p-0 overflow-y-auto scroll_style_thin text-sm md:text-base">
            {ParseHTML(body)}
          </div>
        </div>
        <div className="mt-4 w-full text-center">
          <LinkBtn
            customClasses="px-3 py-1 bg-primary-hover rounded-md capitalize text-light hover:bg-opacity-90 transition ease-in delay-150 text-sm md:text-base"
            route={`/our-services/${slug}`}
          >
            read more
          </LinkBtn>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
