import { size } from "lodash";
import useAppStore from "../../../appStates/AppState";
import { endPoint } from "../../../utils/request";
import { AppPlaceholderImage } from "../../../utils/uiData";
import { Image } from "../../atoms";
import { SectionOrderBtn } from "../OtherElements/SectionOrderBtn";
import { SectionWhatsappBtn } from "../OtherElements/SectionWhatsappBtn";

export const RightBody = () => {
  const { applicationAds } = useAppStore();

  const ad = applicationAds?.home_page_whyus_right_banner;
  const BannerPath = ad?.path;
  const banner = size(BannerPath)
    ? `${endPoint}/${BannerPath}`
    : AppPlaceholderImage;

  return (
    <div className="flex flex-col md:items-center md:justify-center gap-5 md:gap-0">
      <div className="w-full md:self-center">
        <Image
          parentClasses="md:pt-[30px]"
          customClasses="object-contain"
          src={banner}
          title={ad?.title}
          height="760px"
          width="506px"
        />
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center gap-3 w-full">
        <div className="">
          <SectionOrderBtn />
        </div>
        <div className="md:mt-[3rem]">
          <SectionWhatsappBtn />
        </div>
      </div>
    </div>
  );
};
