import { cn } from "../utils";

const Container = ({ children, className = "" }) => {
  return (
    <section
      className={cn(
        `px-5 xl:px-0 w-full lg:w-11/12 xl:max-w-screen-xl mx-auto`,
        className,
      )}
    >
      {children}
    </section>
  );
};

export default Container;
