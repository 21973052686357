import { TiDeleteOutline } from "react-icons/ti";
import RouteStatusContainer from "../../../components/RouteStatusContainer";
import withHeaderAndFooter from "../HOCTemplates/withHeaderAndFooter";
import withSidebar from "../HOCTemplates/withSidebar";

const PageTemplate = () => {
  return (
    <section>
      <RouteStatusContainer
        icon={TiDeleteOutline}
        iconClassName="text-red-500"
        iconSize={70}
        className="text-red-500 uppercase"
        title="404 not found"
      />
    </section>
  );
};

const PageWithSidebar = withSidebar(PageTemplate);
const NotFoundTemplate = withHeaderAndFooter(PageWithSidebar);

export default NotFoundTemplate;
