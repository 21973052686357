import { cn } from "../../../utils";
import { AnchoreBtn } from "../../atoms";

export const SectionOrderBtn = ({
  label = "order now",
  route = "/order-now",
  linkWrapperClasses = "",
  className = "",
  blank_page = false,
}) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center mt-[3rem]",
        linkWrapperClasses,
      )}
    >
      <AnchoreBtn
        url={route}
        customClasses={cn(
          `shadow-lg text-base md:text-lg lg:text-xl font-medium uppercase text-center text-light rounded-md block py-2 px-16 hover:bg-opacity-90 bg-primary-hover transition ease-in delay-150`,
          className,
        )}
        blank_page={blank_page}
      >
        {label}
      </AnchoreBtn>
    </div>
  );
};
