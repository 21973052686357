import { RiRefreshLine } from "react-icons/ri";
import { cn } from "../../utils";
import ErrorText from "./ErrorText";
import { Label } from "./Label";

export const Input = ({
  type = "text",
  name = "",
  handleInput = () => {},
  value = "",
  containerClasses = "",
  wrapperClasses = "",
  customClasses = "",
  parentClasses = "",
  placeHolder = "Please enter a value",
  label = "",
  labelCustomClasses = "",
  error = null,
  errorCustomClasses = "",
  icon: Icon = null,
  iconClasses = "",
}) => {
  return (
    <div className={cn(``, containerClasses)}>
      <div className={cn(``, wrapperClasses)}>
        <div className={cn(``, parentClasses)}>
          {label ? (
            <div className="my-1">
              <Label
                customClasses={labelCustomClasses}
                htmlFor={name}
                label={label}
                icon={Icon}
                iconClasses={iconClasses}
              />
            </div>
          ) : null}
          <input
            className={cn(
              `${
                error?.[name] ? "border-red-600" : ""
              } focus:outline-none border rounded-md p-2`,
              customClasses,
            )}
            type={type}
            id={name}
            name={name}
            onChange={handleInput}
            value={value}
            placeholder={placeHolder}
          />
        </div>
      </div>
      <ErrorText
        error={error}
        name={name}
        errorCustomClasses={errorCustomClasses}
      />
    </div>
  );
};

export const AppSecurityInput = ({
  type = "text",
  name = "",
  handleInput = () => {},
  value = "",
  wrapperClasses = "",
  containerClasses = "",
  customClasses = "",
  placeHolder = "Security Code",
  // label = "",
  // labelCustomClasses = "",
  // error = null,
  // errorCustomClasses = "",
  // icon: Icon = null,
  // iconClasses = "",
}) => {
  return (
    <div
      className={cn(
        "flex items-center gap-2 border rounded-md bg-light",
        wrapperClasses,
      )}
    >
      <Input
        type={type}
        containerClasses={cn(`!flex-1`, containerClasses)}
        customClasses={cn(`w-full border-none`, customClasses)}
        placeHolder={placeHolder}
        name={name}
        handleInput={handleInput}
        value={value}
      />
      <span
        tabIndex={0}
        role="button"
        className="p-1 text-slate-400 rounded-md hover:bg-gray-100 transition duration-75 ease-in"
      >
        <RiRefreshLine size={18} />
      </span>
      <span className="text-xs p-1 bg-green-600 text-light mr-2 rounded-md">
        1234
      </span>
    </div>
  );
};
