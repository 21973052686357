import { SpanBtn } from "../atoms";
import { Search } from "../atoms/Search";

export const SearchBtn = ({ onClick }) => {
  return (
    <SpanBtn onClick={onClick}>
      <Search />
    </SpanBtn>
  );
};
