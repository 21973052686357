import { cn } from "../../../utils";

export const Tooltip = ({
  children,
  tooltipText = "",
  tooltipClasses = "",
}) => {
  return (
    <span className="group relative">
      <span
        className={cn(
          `w-72 text-sm pointer-events-none absolute top-5 -left-1/2 -translate-x-1/2 rounded bg-gray-section px-2 py-1 text-dark opacity-0 transition group-hover:opacity-100 z-10`,
          tooltipClasses,
        )}
      >
        {tooltipText}
      </span>

      {children}
    </span>
  );
};
