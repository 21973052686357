import { size } from "lodash";
import Modal from ".";
import { PaginationBtns } from "../../atomicSections/atoms/Others";
import OffersCardOrganism from "../../atomicSections/organisms/OffersOrganism/OffersCardOrganism";
import SpinLoader from "../SpinLoader";
import { NoDataFound } from "../../atomicSections/atoms";
import useOrderPageStore from "../../appStates/OrderPageStates";

const OfferListModal = ({
  isOpen,
  closeModal,
  handleLoadMore,
  items = [],
  pages = [],
  loading = false,
}) => {
  const {
    setCouponResponse,
    setCouponError: setError,
    setPromoCode,
  } = useOrderPageStore();

  const handlePlaceCouponCode = (coupon) => {
    coupon = coupon.toString();

    if (!size(coupon)) return;

    setError(false);
    setCouponResponse({});
    setPromoCode(coupon);
    closeModal();
  };

  return (
    <div className="">
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={"Offer List"}
        titleClasses="font-semibold text-2xl lg:text-4xl text-center"
        btnClasses="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        dialogBodyClasses="max-w-3xl"
        loading={loading}
      >
        <div
          className={`grid gap-y-4 p-4 relative ${
            loading ? "opacity-90 pointer-events-none" : ""
          }`}
        >
          {!size(items) && !loading ? (
            <NoDataFound
              className="border-none"
              content="No offers available"
            />
          ) : null}

          {loading ? (
            <SpinLoader sectionClass="absolute inset-x-1/2 inset-y-1/2" />
          ) : null}

          <ul className={`flex flex-col space-y-8`}>
            {items?.map((item, _idx) => (
              <li key={`offer-${_idx}`}>
                <OffersCardOrganism
                  offer={item}
                  apply
                  handleApply={handlePlaceCouponCode}
                />
              </li>
            ))}
          </ul>

          {size(items) !== 0 ? (
            <PaginationBtns pages={pages} handleClick={handleLoadMore} />
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default OfferListModal;
