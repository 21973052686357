import { Heading } from "../../atoms";
import { SectionWhatsappBtn } from "../OtherElements/SectionWhatsappBtn";

export const FreeFeaturesFooter = () => {
  return (
    <div className="">
      <div className="grid lg:grid-cols-2 gap-2 py-4 mt-5 bg-gray-section content-center">
        <Heading
          customClasses="tracking-wide text-lg lg:text-2xl font-semibold text-center"
          heading="Get Assignment Help Assistance on Whatsapp"
        />

        <SectionWhatsappBtn customClasses="max-w-fit place-self-center" />
      </div>
    </div>
  );
};
