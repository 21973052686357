import { cn } from "../../utils";

export const Label = ({
  customClasses = "",
  htmlFor = "",
  label = "",
  icon: Icon = null,
  iconClasses = "",
}) => {
  return (
    <label
      className={cn(`flex items-center gap-2`, customClasses)}
      htmlFor={htmlFor}
    >
      {Icon ? <Icon className={cn(``, iconClasses)} /> : null}
      {label}
    </label>
  );
};
