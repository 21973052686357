import { OurWriterHeader } from "./OurWriterHeader";
import { OurWritersBody } from "./OurWritersBody";

export const OurWriters = () => {
  return (
    <div className="">
      <OurWriterHeader />
      <OurWritersBody />
    </div>
  );
};
