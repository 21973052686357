import { size } from "lodash";
import { cn } from "../../../utils";
import { Heading, LinkBtn } from "../../atoms";

export const FooterLinks = ({
  heading = "",
  data,
  customClasses = "",
  linkParentClasses = "",
  listClasses = "",
  linkClasses = "",
}) => {
  return (
    <div className={cn(`!text-left`, customClasses)}>
      {heading ? (
        <Heading
          customClasses="my-2 capitalize text-base md:text-lg font-medium"
          heading={heading}
        />
      ) : null}
      <div className={cn(``, listClasses)}>
        {data?.map((link, _idx) => (
          <div key={_idx} className={cn(`mb-1`, linkParentClasses)}>
            <LinkBtn
              route={size(link?.url) ? link?.url : `/${link?.slug}`}
              customClasses={cn(
                `text-sm md:text-base capitalize hover:text-gray-50 transition ease-in-out delay-200`,
                linkClasses,
              )}
            >
              {link?.title}
            </LinkBtn>
          </div>
        ))}
      </div>
    </div>
  );
};
