import { GoTag } from "react-icons/go";
import { Button, Input, SmallText } from "../../atoms";
import useOrderPageStore from "../../../appStates/OrderPageStates";
import SpinLoader from "../../../components/SpinLoader";
import { useEffect, useState } from "react";
import { API } from "../../../utils/request";
import useDashboardStore from "../../../appStates/DashboardState";
import OfferListModal from "../../../components/Modals/OfferListModal";
import { useQuery } from "@tanstack/react-query";
import { size } from "lodash";
import useAppStore from "../../../appStates/AppState";

export const CouponApplyInput = () => {
  const { isPaymentStatusPartial } = useDashboardStore();

  const {
    couponResponse,
    setCouponResponse,
    couponError: error,
    setCouponError: setError,
    couponLoading: loading,
    setCouponLoading: setLoading,
    promoCode,
    setPromoCode,
    checkCouponValidity,
  } = useOrderPageStore();

  const { loadMoreData } = useAppStore();

  const [dataLoading, setDataLoading] = useState(true);

  // Offer List states
  const [offers, setOffers] = useState([]);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(1);

  const { refetch } = useQuery({
    queryKey: ["offer-list", page],
    queryFn: async ({ queryKey }) => {
      const pageNum = queryKey[1];
      const response = await API()
        .get(`/app/offer-lists?per_page=10&page=${pageNum}`)
        .then((res) => res?.data)
        .catch((err) => {
          setDataLoading(false);
          return err;
        })
        .finally(() => {});
      return response;
    },
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setDataLoading(false);
      setOffers(res?.data);
      setPages(res?.links);
    },
    onError: () => {
      setDataLoading(false);
    },
  });

  const handleLoadMore = async (page) => {
    await loadMoreData(page, size(page?.url), setPage, setDataLoading, refetch);
  };

  const toggleShareModal = () => {
    setOpenCouponModal((state) => !state);
  };

  const handleCoupon = (e) => {
    if (error) {
      setCouponResponse({});
      setError(false);
    }
    setPromoCode(e.target.value);
  };

  const handleCouponApply = async () => {
    setError(false);
    if (!promoCode) {
      setError(true);
      return;
    }
    setCouponResponse({});
    setLoading(true);
    await checkCouponValidity();
  };

  const couponError = !couponResponse?.status ? couponResponse?.message : "";
  const couponExists = !couponResponse?.coupon_percentage
    ? ""
    : couponResponse?.coupon_percentage;

  if (isPaymentStatusPartial()) {
    return null;
  }

  useEffect(() => {
    refetch();
    return () => {
      setPromoCode("");
      setCouponResponse({});
    };
  }, []);

  return (
    <div className="border border-stone-500 rounded bg-gray-section p-3 mb-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1 font-medium mb-1">
          <GoTag className="w-5 h-5" />{" "}
          <p className="text-sm md:text-base">Have a Promo code?</p>
        </div>

        <div className="">
          <Button
            type="button"
            customClasses="border-none px-4 py-2 text-sm font-medium text-primary-hover rounded-md hover:underline focus:outline-none focus-visible:ring-0"
            handleClick={toggleShareModal}
          >
            Offer List
          </Button>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Input
          containerClasses="w-full"
          parentClasses="grow my-2"
          customClasses={`w-full border ${
            error ? "border-red-600" : "border-stone-400"
          } text-dark placeholder:text-gray-400 px-2 py-2 rounded-md text-sm md:text-base"`}
          name="promocode"
          placeHolder="Enter promo code"
          handleInput={handleCoupon}
          value={promoCode}
        />
        <div className="flex-none text-right">
          <Button
            customClasses={`border border-primary-hover py-2 px-3 rounded hover:bg-primary-hover hover:text-light text-sm md:text-base ${
              couponExists
                ? "pointer-events-none bg-primary-hover text-light"
                : ""
            }`}
            disabled={loading}
            handleClick={handleCouponApply}
          >
            <div className="flex items-center justify-center gap-2">
              <span>{couponExists ? "Applied" : "Apply"}</span>
              {loading ? <SpinLoader customClasses="h-6 w-6" /> : null}
            </div>
          </Button>
        </div>
      </div>

      {couponError ? (
        <SmallText
          customClasses="text-red-600 font-semibold"
          content={couponError}
        />
      ) : null}

      <OfferListModal
        isOpen={openCouponModal}
        closeModal={toggleShareModal}
        items={offers}
        pages={pages}
        loading={dataLoading}
        handleLoadMore={handleLoadMore}
      />
    </div>
  );
};
