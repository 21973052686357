import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../../appStates/AuthState";
import { size } from "lodash";

const WriterAuthPageSecureRoute = () => {
  const authstate = useAuthStore((state) => state);
  const { getWriterToken, getAccessToken } = authstate;

  const notAuthorized = () => size(getAccessToken()) || !size(getWriterToken());

  return size(getAccessToken()) ? (
    <Navigate to="/student/dashboard" replace={true} />
  ) : notAuthorized() ? (
    <Outlet />
  ) : (
    <Navigate to="/writer/dashboard" replace={true} />
  );
};

export default WriterAuthPageSecureRoute;
