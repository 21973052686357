import { SmallText } from "../../atoms";
import { FreeFeaturesFooter } from "./FreeFeaturesFooter";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../../utils/request";

export const BodyLeft = () => {
  const [features, setFeatures] = useState([]);

  // HOME RATINGS API
  const { refetch: homeFeatureFetch } = useQuery({
    queryKey: ["home-features"],
    queryFn: async () => {
      const response = await API()
        .get("/app/subjects/list?per_page=18")
        .then((res) => res?.data)
        .catch((err) => console.error(err));
      return response;
    },
    enabled: false,
    onSuccess: (res) => {
      setFeatures(res);
    },
  });

  useEffect(() => {
    homeFeatureFetch();
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="">
        <FreeFeaturesFooter />
      </div>
      <div className="">
        <SmallText
          customClasses=""
          content="Our online assignment writing services are offered for the following Modules:"
        />
      </div>
      <div>
        <div className="flex items-center flex-wrap gap-4">
          {features?.map((feature, _idx) => (
            <span
              key={_idx}
              className="text-sm md:text-base font-light capitalize border border-primary-light bg-light shadow-md rounded-md py-1 px-2 hover:bg-primary-light hover:text-light transition ease-in delay-150"
            >
              {feature?.subject?.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
