import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SampleCard from "../../organisms/Cards/SampleCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useHomeStore from "../../../appStates/HomeState";

export const OurSamplesBody = () => {
  const state = useHomeStore();
  const { ourSamples } = state;

  return (
    <div className="">
      <Swiper
        // install Swiper modules
        modules={[Autoplay, Navigation, Pagination, A11y]}
        spaceBetween={20}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        slidesPerView={3}
        // navigation
        loop
        // pagination={{
        //   clickable: true,
        //   //   bulletClass: "w-10 h-2 bg-primary",
        //   //   bulletElement: "div",
        //   el: '<div className="w-10 h-2 bg-primary"></div>',
        // }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        }}
      >
        {ourSamples?.map((sample) => (
          <SwiperSlide key={sample?.id}>
            <SampleCard item={sample} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
