import { FaUserCircle } from "react-icons/fa";
export const data = [
  {
    id: 1,
    image: <FaUserCircle />,
    slug: "evelyn_green",
    name: "Evelyn Green",
    order_completed: "1000",
    order_inprogress: "10",
    body: "List out the specifications for availing assignment writing service and details in our online order form and submit it. We assure the confidentiality of your personal information.",
  },
  {
    id: 2,
    name: "Tori Green",
    slug: "tori_green",
    order_completed: "1000",
    order_inprogress: "10",
    image: <FaUserCircle />,
    body: "Once we receive the form, our assignment writers will contact you at the earliest to discuss all details related to your assignment help. They are happy to help you.",
  },
  {
    id: 3,
    name: "Kissa Red",
    slug: "kissa_red",
    order_completed: "1000",
    order_inprogress: "10",
    image: <FaUserCircle />,
    body: "After the writing process, the document goes through a proofreading process. After a quality check, the final paper is dropped into your email by the assignment writing service along with Free Plagiarism Report.",
  },
  {
    id: 4,
    name: "Mia Green",
    order_completed: "1000",
    order_inprogress: "10",
    image: <FaUserCircle />,
    body: "After the writing process, the document goes through a proofreading process. After a quality check, the final paper is dropped into your email by the assignment writing service along with Free Plagiarism Report.",
  },
];
