import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../../appStates/AuthState";
import { size } from "lodash";

const PrivateRoute = () => {
  const { getAccessToken } = useAuthStore();

  const Loggedin = () => size(getAccessToken());

  return Loggedin() ? <Outlet /> : <Navigate to="/signin" replace={true} />;
};

export default PrivateRoute;
