import Container from "../../../components/Container";
import BestInCountry from "./BestInCountriesComp";
import { LeftBody } from "./LeftBody";
import { RightBody } from "./RightBody";

export const FooterBody = () => {
  return (
    <div className="">
      <Container className="px-2">
        <BestInCountry className="mb-10" />
        <div className="grid lg:grid-cols-[1fr_400px] gap-4">
          <LeftBody />
          <RightBody />
        </div>
      </Container>
    </div>
  );
};
