import { forwardRef, useState } from "react";
import { SearchForm } from "./SearchForm";

export const SearchMolicules = (
  {
    submitSearch = (e) => {
      e.preventDefault();
    },
  },
  ref
) => {
  const [searchInput, setSearchInput] = useState("");

  return (
    <div className="flex-1 py-3" ref={ref}>
      <SearchForm
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        submitSearch={submitSearch}
      />
    </div>
  );
};

export const SearchInputMolicules = forwardRef(SearchMolicules);
