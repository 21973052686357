import { useNavigate } from "react-router-dom";
import { cn } from "../utils";
import { useEffect } from "react";

const RouteStatusContainer = ({
  icon: Icon = null,
  iconSize = 45,
  iconClassName = "",
  parentClassName = "",
  className = "",
  title = "",
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      navigate("/student/dashboard", { replace: true });
    }, 1000 * 7);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-2 min-h-screen",
        parentClassName,
      )}
    >
      {Icon ? <Icon size={iconSize} className={cn(``, iconClassName)} /> : null}
      <h1
        className={cn(
          "text-lg md:text-2xl lg:text-5xl font-semibold",
          className,
        )}
      >
        {title}
      </h1>
    </div>
  );
};

export default RouteStatusContainer;
