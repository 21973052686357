import { cn } from "../../../../utils";
import { Heading, SpanText } from "../../../atoms";

export const HeroSpecialSection = ({
  icon = null,
  heading = "",
  content = "",
  className = "",
}) => {
  return (
    <section className={cn("flex items-center gap-2 my-5", className)}>
      {icon}
      <div className="leading-5">
        <div>
          <Heading
            heading={heading}
            customClasses={`text-sm sm:text-base lg:text-xl font-semibold inline-block`}
          />
        </div>
        <SpanText content={content} customClasses="text-sm" />
      </div>
    </section>
  );
};
