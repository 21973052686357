import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { UL } from "../atomicSections/molecules";
import useAuthStore from "../appStates/AuthState";
import { FaWhatsapp } from "react-icons/fa";
import { API } from "../utils/request";
import { toast } from "react-toastify";
import SpinLoader from "./SpinLoader";
import { HiUser } from "react-icons/hi";

export const customerDropdownData = [
  {
    id: 101,
    name: "My Orders",
    url: "/student/dashboard",
    subPages: [],
  },
  {
    id: 102,
    name: "My Profile",
    url: "/student/profile",
    subPages: [],
  },
  {
    id: 103,
    name: "My Finances",
    url: "/student/myfinances",
    subPages: [],
  },
  {
    id: 104,
    name: "My Withdrawals",
    url: "/student/mywithdrawals",
  },
  {
    id: 105,
    name: "Refer and earn",
    url: "/student/affiliate-earnings",
  },
];

const Dropdown = ({ show = false }, ref) => {
  const { auth, removeAuth, isAuthLoading, setisAuthLoading, getLocalUser } =
    useAuthStore();

  const user = getLocalUser();
  const logOut = async () => {
    setisAuthLoading(true);
    try {
      const response = await API().post(`/app/logout`);
      if (response?.status === 200) {
        const data = response?.data;
        if (!data?.status) {
          toast.error(`Logout Unsuccessful!`);
          setisAuthLoading(false);
          return;
        }
        setisAuthLoading(false);
        removeAuth();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("writerToken");
      }
    } catch {
      setisAuthLoading(false);
      toast.error(`Logout Unsuccessful!`);
    }
  };

  const dropdownList = !auth ? (
    <div className="grid gap-2">
      <Link
        to={"/signin"}
        className="py-2 bg-[#DD6E02] text-light w-full block rounded-md text-center"
      >
        Sign in
      </Link>
      <Link
        to={"/signup"}
        className="py-2 bg-dark text-light w-full block rounded-md text-center"
      >
        New user? Start here
      </Link>
    </div>
  ) : (
    <div className={``}>
      <UL ulClasses="grid gap-3 py-2 px-2">
        <li className="flex items-center gap-2 py-2 border-b">
          <HiUser className="text-xl md:text-2xl text-primary-hover" />
          <span>User ID: {user?.user_number}</span>
        </li>
        <li
          className={`flex gap-2 items-center text-sm hover:text-primary-hover ${
            !auth && "hidden"
          }`}
        >
          <Link to={"/"} className="flex gap-2 items-center">
            <span className="font-semibold">Personal AC Manager</span>
            <div className="bg-green-600 text-light p-1 rounded-full flex items-center justify-center">
              <FaWhatsapp className="h-3 w-3" />
            </div>
          </Link>
        </li>
        {customerDropdownData?.map((item, _idx) => (
          <li
            key={_idx}
            className={`flex gap-2 items-center text-sm hover:text-primary-hover ${
              !auth && "hidden"
            }`}
          >
            <Link to={item?.url} className="capitalize">
              {item?.name}
            </Link>
          </li>
        ))}

        <li
          className={`flex gap-2 items-center text-sm hover:text-primary-hover ${
            !auth && "hidden"
          } ${isAuthLoading ? "pointer-events-none opacity-90" : ""}`}
          role="button"
          tabIndex="0"
          onClick={logOut}
        >
          <div className="flex items-center gap-2">
            <span>Sign out</span>
            {isAuthLoading ? <SpinLoader customClasses="w-4 h-4" /> : null}
          </div>
        </li>
      </UL>
    </div>
  );

  return (
    <div
      className={`absolute ${
        show ? "block opacity-1" : "hidden opacity-0"
      } lg:right-[57px] bg-light z-30 w-[250px] text-dark shadow-lg rounded-br-sm rounded-bl-sm transition-all ease-in-out delay-300`}
      ref={ref}
    >
      <div className="py-3 px-2 grid gap-4">{dropdownList}</div>
    </div>
  );
};

const SigninoutDropdown = forwardRef(Dropdown);

export default SigninoutDropdown;
