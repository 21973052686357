import useHomeStore from "../../../appStates/HomeState";
import { HeaderStyleOne } from "../OtherElements/HeaderStyleOne";

export const TestimonialsHeader = () => {
  const state = useHomeStore();
  const { testimonialsHeader } = state;

  return (
    <div>
      <HeaderStyleOne pageSection={testimonialsHeader} />
    </div>
  );
};
