import { NoDataFound } from "../../atomicSections/atoms"; //RoundedChip
import { size } from "lodash";
import SpinLoader from "../SpinLoader";
import { useMemo } from "react";
import { useTable } from "react-table";
import { cn } from "../../utils";

export const AppDynamicTable = ({
  loading = false,
  columns = [],
  data = [],
  tableClasses = "",
  tableHeadClasses = "",
  tableBodyClasses = "",
  rowClasses = "",
}) => {
  columns = useMemo(() => columns, [columns]);
  data = useMemo(() => data, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table
      {...getTableProps()}
      className={cn(
        "w-full border-collapse border-spacing-1 table-auto",
        tableClasses,
      )}
    >
      <thead
        className={cn(
          "text-[12px] md:text-base lg:text-lg text-gray-700 uppercase bg-gray-50",
          tableHeadClasses,
        )}
      >
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, ids) => (
              <th
                key={ids}
                scope="row"
                {...column.getHeaderProps()}
                className="py-2"
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {loading ? (
        <tbody className="" {...getTableBodyProps()}>
          <tr className="">
            <td className="text-center" colSpan={size(columns)}>
              <SpinLoader sectionClass="py-3" />
            </td>
          </tr>
        </tbody>
      ) : !size(data) ? (
        <tbody className="" {...getTableBodyProps()}>
          <tr className="">
            <td className="text-center" colSpan={size(columns)}>
              <NoDataFound className="text-[12px] md:text-base lg:text-lg text-gray-600 min-h-[250px] border-0" />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody
          className={cn(
            "py-2 text-[12px] md:text-base lg:text-lg",
            tableBodyClasses,
          )}
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                {...row.getRowProps()}
                className={cn("border-b p-1", rowClasses)}
              >
                {row.cells.map((cell, c_id) => {
                  return (
                    <td
                      key={c_id}
                      {...cell.getCellProps()}
                      className={`${cell?.column?.className}`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};
